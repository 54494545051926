import React, { useEffect, useState, useContext, useRef } from 'react'
import {
  Box,
  Heading,
  Card,
  Form,
  FormField,
  TextInput,
  RadioButtonGroup,
  ResponsiveContext,
  Select,
  Grid,
  Anchor,
  Button,
  Spinner,
  Paragraph,
} from 'grommet'
import { Add } from 'grommet-icons'

import { useResponsive } from '../../hooks'

import { navigate } from 'gatsby'
import { FormTextInput } from '../Forms'
import { FormLock, View } from 'grommet-icons'

import { employersAPI } from '../../config'
import ProgressBar from '../Components/ProgressBar'
import Sidebar from '../Components/Sidebar'

import { placeOrder } from '../../state/order'
import { useDispatch, useSelector } from 'react-redux'
import Rule from '../Components/Rule'

import Auth from '@aws-amplify/auth'

import API from '@aws-amplify/api'
import { employerAPI } from '../../config'
import axios from 'axios'
import { showError } from '../../state/ui'

// const signUp = async({email, password}) => {
//   try {
//       const { user } = await Auth.signUp({
//           username: email,
//           password,
//           attributes: {
//               email
//           }
//       });
//       console.log("NEW USER")
//       return user;
//   } catch (error) {
//       console.log('error signing up:', error);
//   }
// }

const defaultValue = {
  companyName: '',
  companyNumber: '',
  companyAddress1: '',
  companyAddress2: '',
  finance: false,
  companyPostcode: '',
}

const contactDefaultValue = {
  companyContactEmail: '',
  companyContactFirstname: '',
  companyContactLastname: '',
  companyContactPhone: '',
  companyContactPassword: '',
  empowered: false,
  department: 'HR',
}

const AboutForm = ({ employerId }) => {
  const { isDesktop, isMobile } = useResponsive()
  const [value, setValue] = useState(defaultValue)
  const { name, path } = employersAPI
  const dispatch = useDispatch()
  const [contacts, setContacts] = useState([contactDefaultValue])
  const [readOnly, setReadOnly] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const [reveal, setReveal] = useState(false)
  const [confirmReveal, setConfirmReveal] = useState(false)
  const [confirmError, setConfirmError] = useState(false)

  const signupUser = async (username, password) => {
    try {
      let user = await Auth.signUp({
        username,
        password,
      })

      return user
    } catch (err) {
      setSubmitting(false)
      dispatch(
        showError({
          title: 'Error Registering',
          message: err.message,
          type: null,
        })
      )
    }
  }

  const onSubmit = async () => {
    setSubmitting(true)
    if (value.password !== value.confirmPassword) {
      setConfirmError(true)
      setSubmitting(false)
    } else {
      let { companyContactEmail, confirmPassword } = value

      let response = ''

      if (employerId) {
        response = await signupUser(companyContactEmail, confirmPassword)
      }

      try {
        let apiResponse = await API.post(
          name,
          `${path}${employerId ? `/${employerId}` : ''}`,
          {
            body: {
              employer: value,
              contacts: [
                {
                  companyContactEmail: value.companyContactEmail,
                  companyContactFirstname: value.companyContactFirstname,
                  companyContactLastname: value.companyContactLastname,
                  companyContactPhone: value.companyContactPhone,
                  department: value.companyJobTitle,
                  userSub: response.userSub,
                },
              ],
            },
          }
        )

        navigate(`/employer-confirmation/${companyContactEmail}`)
      } catch (error) {
        setSubmitting(false)
        console.log('error', error)
      }
    }
  }

  return (
    <Box style={{ position: 'relative' }} margin={{ top: 'large' }} flex="grow">
      <Box
        direction="column"
        justify="start"
        flex={false}
        pad={{ horizontal: 'large' }}
        margin={{ bottom: 'large' }}
      >
        <Box fill gap="xlarge">
          <Box>
            <Heading margin={{ top: 'none', bottom: 'small' }} level={3}>
              Company Information
            </Heading>
            <Rule margin={{ top: 'none', bottom: 'small' }} />
            <Form
              value={value}
              onChange={nextValue => setValue(nextValue)}
              onReset={() => setValue({})}
              onSubmit={onSubmit}
            >
              <Box pad={{ vertical: 'medium' }}>
                <Box
                  fill
                  flex={isDesktop ? true : false}
                  direction={isMobile ? 'column' : 'row'}
                  gap="medium"
                >
                  <FormTextInput
                    name="companyName"
                    placeholder="Company Name"
                    disabled={readOnly}
                  />
                  <FormTextInput
                    validate={null}
                    name="companyNumber"
                    placeholder="Company Number"
                    disabled={readOnly}
                  />
                </Box>

                <Box
                  fill
                  flex={isDesktop ? true : false}
                  direction={isMobile ? 'column' : 'row'}
                  gap="medium"
                >
                  <FormTextInput
                    name="companyAddress1"
                    placeholder="Address 1 (Optional)"
                    validate={null}
                    required={false}
                    disabled={readOnly}
                  />
                  <FormTextInput
                    name="companyAddress2"
                    validate={null}
                    required={false}
                    placeholder="Address 2 (Optional)"
                    disabled={readOnly}
                  />
                </Box>

                <Box
                  fill
                  flex={isDesktop ? true : false}
                  direction={isMobile ? 'column' : 'row'}
                  gap="medium"
                >
                  <FormTextInput
                    name="companyPostcode"
                    placeholder="Postcode"
                    disabled={readOnly}
                  />
                  <Box fill></Box>
                </Box>
              </Box>
              <Heading margin={{ top: 'large', bottom: 'small' }} level={3}>
                Primary Contact
              </Heading>
              <Rule margin={{ top: 'none', bottom: 'small' }} />
              {/* <ContactAdd contacts={contacts} setContacts={setContacts} /> */}

              <Box
                fill="horizontal"
                flex={isDesktop ? true : false}
                direction={isMobile ? 'column' : 'row'}
                gap="medium"
              >
                <FormTextInput
                  name={`companyContactFirstname`}
                  placeholder={`First Name`}
                />
                <FormTextInput
                  name={`companyContactLastname`}
                  placeholder={`Last Name`}
                />
              </Box>
              <Box
                fill="horizontal"
                flex={isDesktop ? true : false}
                direction={isMobile ? 'column' : 'row'}
                gap="medium"
              >
                <FormTextInput
                  name={`companyContactPhone`}
                  required={false}
                  placeholder={`Phone Number (Optional)`}
                  // validate={[
                  //   {
                  //     regexp: /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/i,
                  //     message: 'Must be a valid UK phone number',
                  //     status: 'error',
                  //   },
                  // ]}
                />
                <FormTextInput
                  name={`companyJobTitle`}
                  placeholder={`Job Title`}
                  // validate={[
                  //   {
                  //     regexp: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                  //     message: 'Must be a valid email',
                  //     status: 'error',
                  //   },
                  // ]}
                />
              </Box>
              <Box
                fill="horizontal"
                flex={isDesktop ? true : false}
                direction={isMobile ? 'column' : 'row'}
                gap="medium"
              >
                <FormTextInput
                  name={`companyContactEmail`}
                  placeholder={`Email`}
                  validate={[
                    {
                      regexp: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                      message: 'Must be a valid email',
                      status: 'error',
                    },
                  ]}
                />
                <Box fill="horizontal" />
              </Box>

              {employerId && (
                <>
                  <Heading margin={{ top: 'large', bottom: 'small' }} level={3}>
                    Your Password
                  </Heading>
                  <Rule margin={{ top: 'none', bottom: 'small' }} />
                  <Box
                    fill="horizontal"
                    flex={isDesktop ? true : false}
                    direction={isMobile ? 'column' : 'row'}
                    gap="medium"
                  >
                    <Box
                      fill="horizontal"
                      direction="row"
                      align="start"
                      justify="start"
                      style={{ position: 'relative' }}
                    >
                      <FormTextInput
                        autoComplete="off"
                        name="password"
                        placeholder="Password"
                        type={reveal ? 'text' : 'password'}
                        info={
                          'Must contain uppercase, lowercase, digits and one symbol'
                        }
                      />
                      <Button
                        style={{ position: 'absolute', right: 0, top: 3 }}
                        icon={
                          reveal ? (
                            <FormLock size="21px" />
                          ) : (
                            <View size="17px" />
                          )
                        }
                        onClick={() => setReveal(!reveal)}
                      />
                    </Box>

                    <Box
                      fill="horizontal"
                      align="start"
                      justify="start"
                      style={{ position: 'relative' }}
                    >
                      <Box
                        fill="horizontal"
                        direction="row"
                        align="start"
                        justify="start"
                        style={{ position: 'relative' }}
                      >
                        <FormTextInput
                          autoComplete="off"
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          type={confirmReveal ? 'text' : 'password'}
                        />
                        <Button
                          style={{ position: 'absolute', right: 0, top: 3 }}
                          icon={
                            confirmReveal ? (
                              <FormLock size="21px" />
                            ) : (
                              <View size="17px" />
                            )
                          }
                          onClick={() => setConfirmReveal(!confirmReveal)}
                        />
                      </Box>
                      {confirmError && (
                        <Paragraph margin="none" color="red">
                          Passwords do not match
                        </Paragraph>
                      )}
                    </Box>
                  </Box>
                </>
              )}

              <Box
                fill="horizontal"
                flex={isDesktop ? true : false}
                direction={isMobile ? 'column' : 'row'}
                gap="medium"
                margin={{ top: '50px' }}
              >
                <Box flex="shrink" align="center">
                  <Button
                    style={{ width: 250, textAlign: 'center' }}
                    disabled={submitting}
                    justify="center"
                    type="submit"
                    icon={
                      submitting ? (
                        <Spinner
                          style={{ margin: '0 auto' }}
                          alignSelf="center"
                          size="xsmall"
                        />
                      ) : null
                    }
                    alignSelf="start"
                    primary
                    size="large"
                    label={submitting ? null : 'Register'}
                  />
                  <Paragraph>
                    <Anchor
                      margin={{ vertical: 'medium' }}
                      className="smallprint"
                      href="/privacy-policy/"
                      label="Blike Privacy Policy"
                    ></Anchor>
                  </Paragraph>
                </Box>
                <Box flex basis="1/2"></Box>
              </Box>
            </Form>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const ContactRow = ({ contact, onChange, index }) => {
  const { isDesktop, isMobile } = useResponsive()
  const [checked, setChecked] = useState(true)
  const [selectValue, setSelectValue] = useState('HR')

  return (
    <Form
      value={contact}
      onChange={nextValue => onChange(nextValue, index)}
      onReset={() => onChange({})}
    >
      <Box margin={{ top: 'small' }}>
        {/* <Box
          fill
          flex={isDesktop ? true : false}
          direction="row"
          gap="medium"
          width="50%"
        >
          <Select
            options={['HR', 'CFO', 'CEO']}
            value={selectValue}
            onChange={({ option }) => setSelectValue(option)}
          />
          <CheckBox
            checked={checked}
            label="I'm empored to sign for the company"
            onChange={event => setChecked(event.target.checked)}
          />
        </Box> */}
      </Box>
    </Form>
  )
}

const ContactAdd = ({ contacts, setContacts }) => {
  const { isDesktop } = useResponsive()

  const addRow = () => {
    setContacts(current => [...current, contactDefaultValue])
  }

  const onChange = (value, index) => {
    let newArr = [...contacts]
    newArr[index] = { ...contacts[index], ...value }
    setContacts(newArr)
  }

  return (
    <>
      {contacts.map((contact, index) => {
        return (
          <ContactRow
            key={index}
            index={index}
            contact={contact}
            onChange={onChange}
          />
        )
      })}

      {/* <Box
        fill
        flex={isDesktop ? true : false}
        direction="row"
        gap="medium"
        margin={{ top: '50px' }}
      >
        <Box flex basis="1/2" direction="row" align="center">
          <Button
            onClick={addRow}
            alignSelf="start"
            fill={false}
            primary
            size="large"
            icon={<Add color="white" />}
          />
          <Paragraph margin={{ vertical: 'none', left: 'small' }}>
            Add Contact
          </Paragraph>
        </Box>
        <Box flex basis="1/2"></Box>
      </Box> */}
    </>
  )
}

export default AboutForm
