import API from '@aws-amplify/api'
import Auth from '@aws-amplify/auth'
import { navigate } from 'gatsby'
import {
  Anchor,
  Box,
  Button,
  Form,
  CheckBox,
  Heading,
  Paragraph,
  Spinner,
  Text,
} from 'grommet'
import { FormLock, View } from 'grommet-icons'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { employersAPI, emailCheckAPI } from '../../config'
import { useResponsive } from '../../hooks'
import { showError, switchPurchase } from '../../state/ui'
import { clearCode } from '../../state/employee'
import Rule from '../Components/Rule'
import { FormTextInput } from '../Forms'
import BannerImage from '../../assets/images/bikeImages2.png'
import TitleSwoosh from '../../assets/vectors/gradient-swoosh.svg'
import { useEditableRegions } from '../../hooks'
import parser, { domToReact } from 'html-react-parser'
import ClipLoader from 'react-spinners/ClipLoader'
import { AppUser } from '../Auth'

const defaultValue = {
  companyName: '',
  companyNumber: '',
  companyAddress1: '',
  companyAddress2: '',
  finance: false,
  companyPostcode: '',
}

const contactDefaultValue = {
  companyContactEmail: '',
  companyContactFirstname: '',
  companyContactLastname: '',
  companyContactPhone: '',
  companyContactPassword: '',
  empowered: false,
  department: 'HR',
}

const AboutForm = ({ employer }) => {
  const { isDesktop, isMobile } = useResponsive()
  const [value, setValue] = useState(defaultValue)
  const { name, path } = employersAPI
  const dispatch = useDispatch()
  const [contacts, setContacts] = useState([contactDefaultValue])
  const [readOnly, setReadOnly] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [companyName, setCompanyName] = useState(null)
  const [exists, setExists] = useState(null)
  const [reveal, setReveal] = useState(false)
  const [confirmReveal, setConfirmReveal] = useState(false)
  const [confirmError, setConfirmError] = useState(false)
  const [checked, setChecked] = useState(false)
  const { logout } = AppUser
  const { editableRegions } = useEditableRegions('Employer Sign Up')
  let { name: emailCheckName, path: emailCheckPath } = emailCheckAPI

  useEffect(() => {
    dispatch(switchPurchase({ purchaseType: 'cycletowork' }))
    dispatch(clearCode())
  }, [employer])

  useEffect(() => {
    logout()

    API.get(name, `${path}/${employer ? `${employer}` : ''}`).then(response => {
      console.log(response);
      setCompanyName(response.result.name)
      setExists(response.exists)
    })
  }, [])

  const signupUser = (username, password) => {
    return Auth.signUp({
      username,
      password,
    })
  }

  const emailCheck = async email => {
    try {
      let { exists } = await API.get(emailCheckName, emailCheckPath, {
        queryStringParameters: {
          email,
        },
      })

      return exists
    } catch (err) {
      return err
    }
  }

  const onSubmit = async () => {
    setSubmitting(true)

    // if (emailCheck) {
    //   dispatch(
    //     showError({
    //       title: 'Email Exists',
    //       message:
    //         'Looks like you might have already registered. Please login to your account',
    //       type: "login",
    //     })
    //   )
    //   return;
    // }

    if (!checked) {
      dispatch(
        showError({
          title: 'Error',
          message:
            'Please agree to our terms and conditions in order to continue.',
          type: null,
        })
      )
      setSubmitting(false)
      return
    }

    if (value.password !== value.confirmPassword) {
      setConfirmError(true)
      setSubmitting(false)
    } else {
      let { companyContactEmail, confirmPassword } = value

      signupUser(companyContactEmail, confirmPassword)
        .then(response => {
          return API.post(
            name,
            `${path}/url/${employer ? `${employer}` : ''}`,
            {
              body: {
                employer,
                contact: {
                  email: value.companyContactEmail,
                  firstname: value.companyContactFirstname,
                  lastname: value.companyContactLastname,
                  phone: value.companyContactPhone,
                  jobtitle: value.companyJobTitle,
                  user_sub: response.userSub,
                },
              },
            }
          ).then(response => console.log(response))
        })
        .then(response => {
          navigate(
            `/employer-confirmation/${companyContactEmail}/${companyName}/${employer}`
          )
        })
        .catch(error => {
          setSubmitting(false)
          dispatch(
            showError({
              title: 'Error Registering',
              message: error.message,
              type: null,
            })
          )
        })
    }
  }

  return (
    <Box style={{ position: 'relative' }} margin={{ top: 'large' }} flex="grow">
      {companyName ? (
        <>
          <Box
            justify="center"
            fill="horizontal"
            pad={{ horizontal: 'xlarge' }}
            margin={{ bottom: 'large' }}
          >
            <Box
              style={{ position: 'relative' }}
              justify="center"
              align="center"
            >
              <TitleSwoosh
                style={{
                  position: 'absolute',
                  top: '15%',
                  bottom: 0,
                  margin: 'auto 0',
                  zIndex: -1,
                }}
                height="80%"
              />

              <Heading
                textAlign="center"
                style={{ maxWidth: '680px' }}
                alignSelf="center"
                level={2}
                size="xlarge"
                margin={{ vertical: 'xlarge' }}
              >
                {`Blike & ${companyName}`}
              </Heading>
            </Box>

            {parser(editableRegions['Main'], {
              replace: ({ name, children }) => {
                if (name === 'p') {
                  return (
                    <Heading
                      margin={{ top: 'large', bottom: 'meidum' }}
                      level={2}
                      textAlign="center"
                      alignSelf="center"
                    >
                      {domToReact(children)}{' '}
                    </Heading>
                  )
                }
              },
            })}

            {parser(editableRegions['Large Paragraph'], {
              replace: ({ name, children }) => {
                if (name === 'p') {
                  return (
                    <Paragraph textAlign="center" alignSelf="center">
                      {domToReact(children)}{' '}
                    </Paragraph>
                  )
                }
              },
            })}

            <Box
              border={{ side: 'bottom', size: '2px', color: 'brand' }}
              width="200px"
              alignSelf="center"
              margin={{ vertical: 'medium' }}
            ></Box>

            {parser(editableRegions['Points'], {
              replace: ({ name, children }) => {
                if (name === 'p') {
                  return (
                    <Heading
                      level={4}
                      margin="xsmall"
                      textAlign="center"
                      alignSelf="center"
                      color="brand"
                    >
                      {' '}
                      {domToReact(children)}{' '}
                    </Heading>
                  )
                }
              },
            })}
          </Box>
          <Box
            direction="column"
            justify="start"
            flex={false}
            pad={{ horizontal: 'large' }}
            margin={{ bottom: 'large' }}
          >
            <Box fill gap="xlarge">
              {!exists ? (
                <Box>
                  <Form
                    value={value}
                    onChange={nextValue => setValue(nextValue)}
                    onReset={() => setValue({})}
                    onSubmit={onSubmit}
                  >
                    <Heading
                      margin={{ top: 'large', bottom: 'small' }}
                      level={3}
                    >
                      Your Contact Details
                    </Heading>
                    <Rule margin={{ top: 'none', bottom: 'small' }} />
                    {/* <ContactAdd contacts={contacts} setContacts={setContacts} /> */}

                    <Box
                      fill="horizontal"
                      flex={isDesktop ? true : false}
                      direction={isMobile ? 'column' : 'row'}
                      gap="medium"
                    >
                      <FormTextInput
                        name={`companyContactFirstname`}
                        placeholder={`First Name`}
                      />
                      <FormTextInput
                        name={`companyContactLastname`}
                        placeholder={`Last Name`}
                      />
                    </Box>
                    <Box
                      fill="horizontal"
                      flex={isDesktop ? true : false}
                      direction={isMobile ? 'column' : 'row'}
                      gap="medium"
                    >
                      <FormTextInput
                        name={`companyContactPhone`}
                        required={false}
                        placeholder={`Phone Number (Optional)`}
                        // validate={[
                        //   {
                        //     regexp: /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/i,
                        //     message: 'Must be a valid UK phone number',
                        //     status: 'error',
                        //   },
                        // ]}
                      />
                      <FormTextInput
                        name={`companyJobTitle`}
                        placeholder={`Job Title`}
                        // validate={[
                        //   {
                        //     regexp: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                        //     message: 'Must be a valid email',
                        //     status: 'error',
                        //   },
                        // ]}
                      />
                    </Box>
                    <Box
                      fill="horizontal"
                      flex={isDesktop ? true : false}
                      direction={isMobile ? 'column' : 'row'}
                      gap="medium"
                    >
                      <FormTextInput
                        name={`companyContactEmail`}
                        placeholder={`Email`}
                        validate={[
                          {
                            regexp: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                            message: 'Must be a valid email',
                            status: 'error',
                          },
                        ]}
                      />
                      <Box fill="horizontal" />
                    </Box>

                    <Heading
                      margin={{ top: 'large', bottom: 'small' }}
                      level={3}
                    >
                      Choose A Password
                    </Heading>
                    <Rule margin={{ top: 'none', bottom: 'small' }} />
                    <Box
                      fill="horizontal"
                      flex={isDesktop ? true : false}
                      direction={isMobile ? 'column' : 'row'}
                      gap="medium"
                    >
                      <Box
                        fill="horizontal"
                        direction="row"
                        align="start"
                        justify="start"
                        style={{ position: 'relative' }}
                      >
                        <FormTextInput
                          autoComplete="off"
                          name="password"
                          placeholder="Password"
                          type={reveal ? 'text' : 'password'}
                          info={
                            'Must contain uppercase, lowercase, digits and one symbol'
                          }
                        />
                        <Button
                          style={{ position: 'absolute', right: 0, top: 3 }}
                          icon={
                            reveal ? (
                              <FormLock size="21px" />
                            ) : (
                              <View size="17px" />
                            )
                          }
                          onClick={() => setReveal(!reveal)}
                        />
                      </Box>

                      <Box
                        fill="horizontal"
                        align="start"
                        justify="start"
                        style={{ position: 'relative' }}
                      >
                        <Box
                          fill="horizontal"
                          direction="row"
                          align="start"
                          justify="start"
                          style={{ position: 'relative' }}
                        >
                          <FormTextInput
                            autoComplete="off"
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            type={confirmReveal ? 'text' : 'password'}
                          />
                          <Button
                            style={{ position: 'absolute', right: 0, top: 3 }}
                            icon={
                              confirmReveal ? (
                                <FormLock size="21px" />
                              ) : (
                                <View size="17px" />
                              )
                            }
                            onClick={() => setConfirmReveal(!confirmReveal)}
                          />
                        </Box>
                        {confirmError && (
                          <Paragraph margin="none" color="red">
                            Passwords do not match
                          </Paragraph>
                        )}
                      </Box>
                    </Box>

                    <Box
                      direction="row"
                      margin={{ vertical: 'medium' }}
                      alignSelf="start"
                      align="start"
                    >
                      <CheckBox
                        name="marketing"
                        checked={checked}
                        label={
                          <Paragraph>
                            <Text>I agree to Blike's </Text>
                            <a href="/terms-and-conditions">
                              Terms & Conditions
                            </a>
                          </Paragraph>
                        }
                        onChange={event => setChecked(event.target.checked)}
                      />
                    </Box>
                    <Box
                      fill="horizontal"
                      flex={isDesktop ? true : false}
                      direction={isMobile ? 'column' : 'row'}
                      gap="medium"
                      margin={{ top: '50px' }}
                    >
                      <Box flex="shrink" align="center">
                        <Button
                          style={{ width: 250, textAlign: 'center' }}
                          disabled={submitting}
                          justify="center"
                          type="submit"
                          icon={
                            submitting ? (
                              <Spinner
                                style={{ margin: '0 auto' }}
                                alignSelf="center"
                                size="xsmall"
                              />
                            ) : null
                          }
                          alignSelf="start"
                          primary
                          size="large"
                          label={submitting ? null : 'Create Account'}
                        />
                        {/* <Paragraph>
                        <Anchor
                          margin={{ vertical: 'medium' }}
                          className="smallprint"
                          href="/privacy-policy/"
                          label="Blike Privacy Policy"
                        ></Anchor>
                      </Paragraph> */}
                      </Box>
                      <Box flex basis="1/2"></Box>
                    </Box>
                  </Form>
                </Box>
              ) : (
                <Box align="center">
                  <Heading level={3} margin={{ bottom: 'none' }}>
                    It looks like someone has already signed up for your company
                  </Heading>
                  <Paragraph margin={{ bottom: 'none' }}>
                    If you're an employee of this company simply{' '}
                    <a href={`/employee/${employer}`}>click here</a> and enter
                    your email to continue
                  </Paragraph>
                  <Paragraph>
                    If you're still having issues please{' '}
                    <a href="mailto:hello@blike.co.uk">contact us</a> or use our
                    live chat for one of our team to help you.
                  </Paragraph>
                </Box>
              )}
            </Box>
          </Box>
        </>
      ) : (
        <Box align="center" flex fill justify="center" height={{ min: '80vh' }}>
          <Text>Fetching your details...</Text>
          <Box pad="large">
            <ClipLoader
              color={'#03E272'}
              loading={true}
              size={40}
              speedMultiplier={0.5}
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default AboutForm
