import React, { useState } from 'react'
import AsyncSelect from 'react-select/async'
import axios from 'axios'
import _ from 'lodash'
import { Heading, Button, Box, Text, Paragraph } from 'grommet'
import Select from 'react-select'
import { Checkmark, Close } from 'grommet-icons'
import CheckIcon from '../../assets/vectors/creditcheck-01.svg'

const AsyncSearchBar = ({ success, complete, children }) => {
  return (
    <Box background="#ededed" round="10px" pad="medium">
      <Box direction="row" gap="large" justify="end">
        <Box
          width={{ max: '80px' }}
          alignSelf="end"
          justify="start"
          height="100%"
          style={{ width: '15%' }}
        >
          <Box
            flex={false}
            style={{ paddingBottom: '100%', height: 0, position: 'relative' }}
            width="100%"
          >
            <Box
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
              height="100%"
              align="center"
              justify="center"
              background={
                complete ? (success ? 'brand' : 'red') : 'transparent'
              }
              width="100%"
              round="full"
            >
              {complete ? (
                success ? (
                  <Checkmark color="white" width="100%" />
                ) : (
                  <Close color="white" width="100%" />
                )
              ) : (
                <CheckIcon />
              )}
            </Box>
          </Box>
        </Box>
        <Box flex alignSelf="end">
          <Heading margin={{ top: 'none', bottom: 'small' }} level={3}>
            Instant Eligibility Check
          </Heading>
          {!complete || success ? (
            <Paragraph margin={{ bottom: 'medium' }} size="medium">
              We will use your personal information to check your eligibility.
              This may include doing a ‘soft’ credit check that will leave no
              mark on your credit records.
            </Paragraph>
          ) : (
            <Paragraph margin={{ bottom: 'medium' }} size="medium">
              I’m sorry you don’t meet our eligibility criteria at this time.
              Please feel free to contact us via our chat if you have any
              questions
            </Paragraph>
          )}
          {children}
        </Box>
      </Box>
    </Box>
  )
}

export default AsyncSearchBar
