import API from '@aws-amplify/api'
import Auth from '@aws-amplify/auth'
import { navigate } from 'gatsby'
import {
  Anchor,
  Box,
  Button,
  Form,
  Heading,
  Paragraph,
  Spinner,
  Text,
} from 'grommet'
import { FormLock, View } from 'grommet-icons'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { employeesAPI, employersAPI, emailCheckAPI } from '../../config'
import { useResponsive, useWindowSize } from '../../hooks'
import { addCode } from '../../state/employee'
import Rule from '../Components/Rule'
import { FormTextInput } from '../Forms'
import BannerImage from '../../assets/images/bikeImages2.png'
import TitleSwoosh from '../../assets/vectors/gradient-swoosh.svg'
import { useEditableRegions } from '../../hooks'
import parser, { domToReact } from 'html-react-parser'
import ClipLoader from 'react-spinners/ClipLoader'
import { AppUser } from '../Auth'
import { showError, switchPurchase } from '../../state/ui'

const defaultValue = {
  companyName: '',
  companyNumber: '',
  companyAddress1: '',
  companyAddress2: '',
  finance: false,
  companyPostcode: '',
}

const contactDefaultValue = {
  companyContactEmail: '',
  companyContactFirstname: '',
  companyContactLastname: '',
  companyContactPhone: '',
  companyContactPassword: '',
  empowered: false,
  department: 'HR',
}

const AboutForm = ({ employerID }) => {
  const { isDesktop, isMobile } = useResponsive()
  const [value, setValue] = useState({
    ...defaultValue,
    companyCode: employerID,
  })
  const { name, path } = employeesAPI

  const { name: employerName, path: employerPath } = employersAPI
  const dispatch = useDispatch()

  const [submitting, setSubmitting] = useState(false)

  const [confirmError, setConfirmError] = useState(false)
  const [fetchedEmployer, setFetchedEmployer] = useState(null)

  const { editableRegions } = useEditableRegions('Employee Sign Up')
  const { logout } = AppUser

  useEffect(() => {
    dispatch(switchPurchase({ purchaseType: 'cycletowork' }))
    logout()
    API.get(
      employerName,
      `${employerPath}/${employerID ? `${employerID}` : ''}`
    ).then(response => setFetchedEmployer(response.result.name))
  }, [])


  const onSubmit = async () => {

    setSubmitting(true);
    if (value.password !== value.confirmPassword) {
      setConfirmError(true)
      setSubmitting(false)
    } else {
      let { companyContactEmail, companyCode } = value

      return API.post(name, `${path}/prospect`, {
        body: {
          employer: companyCode,
          email: companyContactEmail,
        },
      })
        .then(response => {
          dispatch(addCode({ ...response.body, employerName: fetchedEmployer }))
          navigate(`/`)
        })
        .catch(error => {
          setSubmitting(false)
          console.log('error', error)
        })
    }
  }

  return (
    <Box style={{ position: 'relative' }} margin={{ top: 'large' }} flex="grow">
      {fetchedEmployer ? (
        <Box
          direction="column"
          justify="start"
          flex={false}
          pad={{ horizontal: 'large' }}
          margin={{ bottom: 'large' }}
        >
          <Box style={{ position: 'relative' }} justify="center" align="center">
            <TitleSwoosh
              style={{
                position: 'absolute',
                top: '15%',
                bottom: 0,
                margin: 'auto 0',
                zIndex: -1,
              }}
              height="80%"
            />
            <Heading
              textAlign="center"
              style={{ maxWidth: '680px' }}
              alignSelf="center"
              level={2}
              size="xlarge"
              margin={{ vertical: 'xlarge' }}
            >
              {`Blike & ${fetchedEmployer}`}
            </Heading>
          </Box>

          {parser(editableRegions['Main'], {
            replace: ({ name, children }) => {
              if (name === 'p') {
                return (
                  <Heading
                    margin={{ top: 'large', bottom: 'meidum' }}
                    level={2}
                    textAlign="center"
                    alignSelf="center"
                  >
                    {domToReact(children)}{' '}
                  </Heading>
                )
              }
            },
          })}

          {parser(editableRegions['Large Paragraph'], {
            replace: ({ name, children }) => {
              if (name === 'p') {
                return (
                  <Paragraph textAlign="center" alignSelf="center">
                    {domToReact(children)}{' '}
                  </Paragraph>
                )
              }
            },
          })}

          <Box
            border={{ side: 'bottom', size: '2px', color: 'brand' }}
            width="200px"
            alignSelf="center"
            margin={{ vertical: 'medium' }}
          ></Box>

          {parser(editableRegions['Points'], {
            replace: ({ name, children }) => {
              if (name === 'p') {
                return (
                  <Heading
                    margin={{ top: 'none', bottom: 'none' }}
                    level={4}
                    textAlign="center"
                    alignSelf="center"
                    color="brand"
                  >
                    {domToReact(children)}{' '}
                  </Heading>
                )
              }
            },
          })}

          <Box fill gap="xlarge">
            <Box>
              <Form
                value={value}
                onChange={nextValue => setValue(nextValue)}
                onReset={() => setValue({})}
                onSubmit={onSubmit}
              >
                <Heading margin={{ top: 'large', bottom: 'small' }} level={3}>
                  Enter Your Email
                </Heading>
                <Rule margin={{ top: 'none', bottom: 'small' }} />

                <Box
                  fill="horizontal"
                  flex={isDesktop ? true : false}
                  direction={isMobile ? 'column' : 'row'}
                  gap="medium"
                >
                  <FormTextInput
                    name={`companyContactEmail`}
                    placeholder={`Your Company Email`}
                    validate={[
                      {
                        regexp: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                        message: 'Must be a valid email',
                        status: 'error',
                      },
                    ]}
                  />
                  <Box fill={true}></Box>

                  {/* <FormTextInput
                    disabled
                    name={`companyCode`}
                    placeholder={`Unique Code`}
                    required={false}
                  /> */}
                </Box>

                <Box
                  fill="horizontal"
                  flex={isDesktop ? true : false}
                  direction={isMobile ? 'column' : 'row'}
                  gap="medium"
                  margin={{ top: '50px' }}
                >
                  <Box flex="shrink" align="center">
                    <Button
                      style={{ width: 250, textAlign: 'center' }}
                      disabled={submitting}
                      justify="center"
                      type="submit"
                      icon={
                        submitting ? (
                          <Spinner
                            style={{ margin: '0 auto' }}
                            alignSelf="center"
                            size="xsmall"
                          />
                        ) : null
                      }
                      alignSelf="start"
                      primary
                      size="large"
                      label={submitting ? null : 'Continue'}
                    />
                    <Paragraph>
                      <Anchor
                        margin={{ vertical: 'medium' }}
                        className="smallprint"
                        href="/privacy-policy/"
                        label="Blike Privacy Policy"
                      ></Anchor>
                    </Paragraph>
                  </Box>
                  <Box flex basis="1/2"></Box>
                </Box>
              </Form>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box align="center" flex fill justify="center" height={{ min: '80vh' }}>
          <Text>Fetching your company's details...</Text>
          <Box pad="large">
            <ClipLoader
              color={'#03E272'}
              loading={true}
              size={40}
              speedMultiplier={0.5}
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}

const ContactRow = ({ contact, onChange, index }) => {
  const { isDesktop, isMobile } = useResponsive()
  const [checked, setChecked] = useState(true)
  const [selectValue, setSelectValue] = useState('HR')

  return (
    <Form
      value={contact}
      onChange={nextValue => onChange(nextValue, index)}
      onReset={() => onChange({})}
    >
      <Box margin={{ top: 'small' }}>
        {/* <Box
          fill
          flex={isDesktop ? true : false}
          direction="row"
          gap="medium"
          width="50%"
        >
          <Select
            options={['HR', 'CFO', 'CEO']}
            value={selectValue}
            onChange={({ option }) => setSelectValue(option)}
          />
          <CheckBox
            checked={checked}
            label="I'm empored to sign for the company"
            onChange={event => setChecked(event.target.checked)}
          />
        </Box> */}
      </Box>
    </Form>
  )
}

const ContactAdd = ({ contacts, setContacts }) => {
  const { isDesktop } = useResponsive()

  const addRow = () => {
    setContacts(current => [...current, contactDefaultValue])
  }

  const onChange = (value, index) => {
    let newArr = [...contacts]
    newArr[index] = { ...contacts[index], ...value }
    setContacts(newArr)
  }

  return (
    <>
      {contacts.map((contact, index) => {
        return (
          <ContactRow
            key={index}
            index={index}
            contact={contact}
            onChange={onChange}
          />
        )
      })}

      {/* <Box
        fill
        flex={isDesktop ? true : false}
        direction="row"
        gap="medium"
        margin={{ top: '50px' }}
      >
        <Box flex basis="1/2" direction="row" align="center">
          <Button
            onClick={addRow}
            alignSelf="start"
            fill={false}
            primary
            size="large"
            icon={<Add color="white" />}
          />
          <Paragraph margin={{ vertical: 'none', left: 'small' }}>
            Add Contact
          </Paragraph>
        </Box>
        <Box flex basis="1/2"></Box>
      </Box> */}
    </>
  )
}

export default AboutForm
