import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { AppContent } from '../Layout'
import { navigate } from 'gatsby'
import Auth from '@aws-amplify/auth'
import API from '@aws-amplify/api'
import { orderAPI, employersAPI, usersAPI, subscriptionAPI } from '../../config'
import { dispatch, useSelector } from 'react-redux'
import { AppUser } from '../Auth'
import {
  Box,
  Heading,
  Grid,
  Anchor,
  FormField,
  Text,
  Image,
  TextInput,
  Layer,
  Form,
  Button,
  Paragraph,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHeader,
} from 'grommet'
import Rule from '../Components/Rule'
import Seo from '../../components/Seo'


import { AccessoriesHeader } from '../Components/Sidebar/Accessories'
import {
  SelectedPlan,
  SelectedPlanHeader,
  SelectedPlanSubscription,
} from '../Components/Sidebar/SelectedPlan'
import {
  SelectedBike,
  SelectedHeader,
} from '../Components/Sidebar/SelectedBike'
import parser from 'html-react-parser'
import { useEditableRegions, useResponsive } from '../../hooks'
import ClipLoader from 'react-spinners/ClipLoader'
import GoCardless from '../Components/PaymentFlow'

let { name, path } = orderAPI
let { name: usersApiName } = usersAPI

const Orders = () => {
  const [orders, setOrders] = useState(false)
  const [employerOrders, setEmployerOrders] = useState()
  const { isMobile } = useResponsive()
  const [username, setUsername] = useState(null)
  const { purchaseType } = useSelector(state => state.ui)

  const { logout } = AppUser
  function logOut(e) {
    e.preventDefault()

    Auth.signOut()
      .then(logout(() => navigate('/signin')))
      .catch(err => console.log('error: ', err))
  }

  const fetchOrders = async () => {
    API.get(name, path)
      .then(({ orders, subscriptions, employerOrders }) => {
        let mappedOrders = orders.map(item => ({ ...item, orderType: 'order' }))
        let mappedSubs = subscriptions.map(item => {
          return {
            ...item,
            orderType: 'subscription',
          }
        })

        setOrders([...mappedOrders, ...mappedSubs])
        setEmployerOrders(employerOrders)
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    fetchOrders()
    Auth.currentAuthenticatedUser().then(response =>
      setUsername(response.attributes.email)
    )
  }, [])

  // useEffect(() => {
  //   console.log('orders', orders)
  // }, [orders])

  return (
    // <AppContent>

    // </AppContent>
    <Box pad={{ horizontal: 'large' }}>
      <Box
        background="#e3e3e3"
        fill="horizontal"
        pad={{ vertical: 'small', horizontal: 'medium' }}
        round="20px"
        align="center"
        justify={isMobile ? 'center' : 'between'}
        margin={{ vertical: 'medium' }}
        direction={isMobile ? 'column' : 'row'}
      >
        <Heading level={3} margin="none">
          Your account
        </Heading>
        <Box align={isMobile ? 'center' : 'end'}>
          {username && (
            <Heading margin="none" level={4}>
              {orders[0]?.fields['First Name']
                ? `${orders[0].fields['First Name']} ${orders[0].fields['Surname']}`
                : username}
            </Heading>
          )}
          <Button
            onClick={e => logOut(e)}
            style={{ margin: 0, cursor: 'pointer' }}
            className="nav-link text-primary"
          >
            Sign Out
          </Button>
        </Box>
      </Box>

      {!orders ? (
        <Box align="center">
          <Paragraph>Fetching orders...</Paragraph>
        </Box>
      ) : orders.length > 0 ? (
        <OrderTable orders={orders} fetchOrders={fetchOrders}/>
      ) : (
        <Box align="center">
          <Paragraph>You have no current orders</Paragraph>
        </Box>
      )}
      {/* <EmployeeTable employeeOrders={employerOrders} /> */}
    </Box>
  )
}

const AccessoriesSummary = ({ accessories }) => {
  const total = accessories.reduce((a, { cost }) => a + cost, 0)

  return (
    <Box>
      {accessories.map((accessory, index) => {
        return (
          <Box key={index}>
            <Box justify="center">
              <Heading margin="none" level={5} size="xsmall" truncate>
                {accessory?.name}
              </Heading>
            </Box>
            {/* <Box justify="center" align="end">
              <Text className="bold" size="xsmall">
                {`£${accessory?.cost}`}
              </Text>
            </Box> */}
          </Box>
        )
      })}
      {/* <Box direction="row" justify="between">
        <Heading
          margin={{ vertical: 'none', top: 'medium' }}
          level={5}
          color="black"
          className="uppercase"
        >
          Accessories Total
        </Heading>

        <Heading
          margin={{ vertical: 'none', top: 'medium' }}
          level={4}
          color="black"
          className="uppercase"
        >
          {`£${total}`}
        </Heading>
      </Box> */}
    </Box>
  )
}

const OrderTable = ({ orders, fetchOrders }) => {
  const { editableRegions } = useEditableRegions('Sidebar')
  const { isMobile } = useResponsive()
  const { path, name } = subscriptionAPI



  return (
    <>
      {orders ? (
        orders.map(({ bike, fields, id, accessories, orderType }, index) => {


          const confirmSubscription = async isSuccess => {
            // let cognitoSub = await createAccount()
            API.post(name, `${path}/confirmation`, {
              body: {
                subscriptionId: id,
                // cognitoSub,
                paymentStatus: isSuccess ? 'success' : 'fail',
              },
            })
              .catch(error => {
                alert('There was an issue with your direct debit payment')
                console.log(error)
              })
          }
        
          const onSucess = async response => {
            confirmSubscription(true)
            fetchOrders();
          }
        
          const onError = response => {
            confirmSubscription(false)
          }


          console.log('fields', fields)
          return (
            <Box
              key={index}
              fill="horizontal"
              direction={!isMobile ? 'row' : 'column'}
              gap="large"
              pad="small"
            >
              <Box basis="1/3" flex="grow">
                <Box direction="row" gap="small" justify="between">
                  <Heading
                    margin="none"
                    level={4}
                    color="brand"
                    className="uppercase"
                  >
                    {`Order`}
                  </Heading>
                  <Heading
                    margin="none"
                    level={5}
                    color="black"
                    className="uppercase"
                  >
                    {`${fields['Display Quote Number']}`}
                  </Heading>
                </Box>
                <Rule margin={{ top: 'xsmall', bottom: 'small' }} />

                <Box justify="between" align="start" direction="row">
                  <Heading color="brand" margin="none" level={4} margin="none">
                    STATUS
                  </Heading>
                  <Heading
                    margin="none"
                    level={5}
                    color="black"
                    className="uppercase"
                  >
                    {fields['Status Message']}
                  </Heading>
                </Box>
                <Rule margin={{ top: 'xsmall', bottom: 'small' }} />
                {fields['Credit Band'] === '1' && (
                  <Box justify="between" align="start" direction="row">
                    <Heading
                      color="brand"
                      margin="none"
                      level={4}
                      margin="none"
                    >
                      ELIGIBILITY CHECK
                    </Heading>
                    <Heading
                      margin="none"
                      level={5}
                      color="red"
                      className="uppercase"
                    >
                      Failed
                    </Heading>
                  </Box>
                )}
                {fields['Status Message'] === 'Payment Required' && (
                  <Box justify="between" align="start" direction="row">
                    <GoCardless
                      onSuccess={onSucess}
                      onError={onError}
                      agree={true}
                      value={
                        parseFloat(fields["Monthly Cost"])
                      }
                    />
                  </Box>
                )}
              </Box>
              <Box basis="1/3" flex="grow">
                <SelectedHeader header={'Bike'} readOnly={true} />
                <SelectedBike
                  step={1}
                  displayName={bike['Display Name']}
                  brand={bike['Brands']}
                  model={bike['Model']}
                  image={null}
                  size={fields['Size']}
                  color={fields['Colour']}
                  includedAccessories={bike['Included Accessories']}
                />
                <AccessoriesHeader step={1} readOnly={true} />
                <AccessoriesSummary accessories={accessories} />

                <SelectedPlanHeader />
                {orderType === 'order' ? (
                  <SelectedPlan
                    step={null}
                    length={fields['Plan Months']}
                    net={fields['Net'].toFixed(2)}
                    stepName={null}
                    terms={parser(editableRegions['Footer Terms'])}
                  />
                ) : (
                  <SelectedPlanSubscription
                    // step={null}
                    price={fields['Monthly Cost']}
                    // stepName={null}
                  />
                )}
              </Box>
              <Box basis="1/3" flex="grow"></Box>
            </Box>
          )
        })
      ) : (
        <Box align="center" flex fill justify="center" height={{ min: '80vh' }}>
          <Text>Fetching your orders...</Text>
          <Box pad="large">
            <ClipLoader
              color={'#03E272'}
              loading={true}
              size={40}
              speedMultiplier={0.5}
            />
          </Box>
        </Box>
      )}
    </>
  )
}

const EmployeeTable = ({ employeeOrders }) => {
  return (
    <>
      <h1>Your Employees Orders</h1>
      <p>Here's all your current employees orders</p>
      <Table alignSelf="stretch">
        <TableHeader>
          <TableCell align="left">
            <p>Id</p>
          </TableCell>
          <TableCell align="left">
            <p>Employee</p>
          </TableCell>
          <TableCell align="left">
            <p>Email</p>
          </TableCell>
          <TableCell align="left">
            <p>Brand</p>
          </TableCell>
          <TableCell align="left">
            <p>Model</p>
          </TableCell>
          <TableCell align="left">
            <p>Cost</p>
          </TableCell>
          <TableCell align="left">
            <p>Plan Length</p>
          </TableCell>
          <TableCell align="left">
            <p>Status</p>
          </TableCell>
        </TableHeader>
        <TableBody>
          {employeeOrders &&
            employeeOrders.map(
              ({ user, order: { bike, fields, id, accessories } }, index) => {
                return (
                  <TableRow key={index} style={{ backgroundColor: 'white' }}>
                    <TableCell align="left">{id}</TableCell>
                    <TableCell align="left">{user['Name']}</TableCell>
                    <TableCell align="left">{user['Email']}</TableCell>
                    <TableCell align="left">{bike['Brands']}</TableCell>
                    <TableCell align="left">{bike['Model']}</TableCell>
                    <TableCell align="left">{bike['RRP']}</TableCell>
                    <TableCell align="left">{fields['Plan Months']}</TableCell>
                    <TableCell align="left">{fields['Status']}</TableCell>
                  </TableRow>
                )
              }
            )}
        </TableBody>
      </Table>
    </>
  )
}

export default Orders
