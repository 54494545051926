import { Box, Grid, Paragraph, Text } from 'grommet'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ClipLoader from 'react-spinners/ClipLoader'
import { useResponsive } from '../../hooks'
//REDUX
import { fetchBikes } from '../../state/bikes'

import { navigate } from 'gatsby'
import parser from 'html-react-parser'
import { fbEvent, gaEvent } from '../../helpers'
import { useEditableRegions } from '../../hooks'
import BikeItem from '../Components/BikeItem'


const Bike = ({ status, message, onValidated }) => {
  const { isDesktop, isMobile } = useResponsive()
  const [loading, setLoading] = useState(true)

  const { editableRegions } = useEditableRegions('Bike grid', 'Footer note')

  const bikeAllIds = useSelector(state => state.bikes.models.allIds)
  const bikebyId = useSelector(state => state.bikes.models.byId)
  const { purchaseType } = useSelector(state => state.ui)

  const dispatch = useDispatch()

  // const selectBikeModel = (e, option) => {
  //   e.preventDefault()
  //   dispatch(selectBike(option))
  // }

  useEffect(() => {
    gaEvent('grid-view')
    fbEvent('ViewContent')
    dispatch(fetchBikes(true))
  }, [])

  useEffect(() => {
    if (bikeAllIds.length > 0) {
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [bikeAllIds]) // Only depend on bikeAllIds

  return loading ? (
    <>
      <Box align="center" flex fill justify="center" height={{ min: '80vh' }}>
        <Text>Fetching bikes...</Text>
        <Box pad="large">
          <ClipLoader
            color={'#03E272'}
            loading={true}
            size={40}
            speedMultiplier={0.5}
          />
        </Box>
      </Box>
    </>
  ) : (
    <>
      <Box
        // ref={ref}
        pad={{ horizontal: 'medium' }}
        direction="column"
        justify="start"
        flex
        fill
        margin={{ top: 'medium', bottom: 'large' }}
      >
          <Paragraph
          alignSelf="center"
          size="large"
          textAlign="center"
          margin={{ top: 'none', bottom: 'large' }}
        >
          {
            "Recycle's are pre-used bikes available at a discount"
          }
        </Paragraph>

        {bikeAllIds ? (
          <Grid
            columns={{
              count: isDesktop ? 3 : 1,
              size: '33%',
            }}
          >
            {bikeAllIds.map((bikeId, key) => {
              return (
                <a
                  href="#"
                  key={key}
                  className="bike-item"
                  onClick={e => navigate(`/recycle/${bikeId}`)}
                >
                  <Box margin={{ vertical: isMobile ? 'medium' : 'none' }}>
                    <BikeItem
                      recycle
                      purchaseType={purchaseType}
                      bike={bikebyId[bikeId]}
                      top={key + 1 <= 3 ? true : false}
                      right={(key + 1) % 3 === 0 ? true : false}
                    />
                  </Box>
                </a>
              )
            })}
          </Grid>
        ) : (
          <Box
            align="center"
            flex
            fill
            justify="center"
            height={{ min: '80vh' }}
          >
            <Text>Fetching bikes...</Text>
            <Box pad="large">
              <ClipLoader
                color={'#03E272'}
                loading={true}
                size={40}
                speedMultiplier={0.5}
              />
            </Box>
          </Box>
        )}
      </Box>
      <Box className="editable smallprint" pad={{ horizontal: 'large' }}>
        {purchaseType === 'subscription'
          ? parser(editableRegions['Footer Terms Subscription'])
          : parser(editableRegions['Footer Terms'])}
      </Box>
    </>
  )
}

export default Bike
