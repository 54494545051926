import { AppUser } from '../components/Auth'
import { gaEvent } from '../helpers'
import Auth from '@aws-amplify/auth'
import { navigate } from '@reach/router'
import React, { useState } from 'react'

export const useAuthentication = location => {
  const [state, setState] = useState({
    username: ``,
    email: ``,
    password: ``,
    error: ``,
    loading: false,
    confirmPassword: ''
  })


  const signUp = async () => {
    const { username, password, email, phone_number } = state
    setState({ loading: true })
    try {
      await Auth.signUp({
        username,
        password,
        attributes: { email, phone_number },
      })
      setState({ loading: false })
      navigate(`/verify/${username}`)
    } catch (err) {
      setState({ error: err, loading: false })
      console.log('error signing up...', err)
    }
  }

  const resendCode = async () => {
    const { email } = state
    setState({ loading: true })
    try {
      await Auth.resendSignUp(email)
      setState({ stage: 1, loading: false })
    } catch (err) {
      setState({ error: err, loading: false })
      console.log('error resending code...', err)
    }
  }

  const verify = async () => {
    const { email, auth_code } = state
    setState({ loading: true })
    try {
      await Auth.verifyCurrentUserAttributeSubmit(email, auth_code)
      setState({ loading: false })
      navigate('/signin')
    } catch (err) {
      setState({ error: err, loading: false })
      console.log('error signing up...', err)
    }
  }

  const confirmSignUp = async () => {
    setState({ loading: true })
    const { email, auth_code } = state
    try {
      setState({ loading: true })
      await Auth.confirmSignUp(email, auth_code)
      setState({ loading: false })
      navigate('/signin')
    } catch (err) {
      setState({ error: err, loading: false })
      console.log('error confirming signing up...', err)
    }
  }

  const login = async e => {
    const { setUser } = AppUser
    e.preventDefault()
    const { username, password } = state
    try {
      setState(current => ({ ...current, loading: true }))
      await Auth.signIn(username, password)
      const user = await Auth.currentAuthenticatedUser()
      const userInfo = {
        ...user.attributes,
        username: user.username,
      }
      gaEvent('intercom-update', user)
      setUser(userInfo)
      setState(current => ({ ...current, loading: false }))
      if (location.search) {
        navigate(`/${location.search.replace('?redirect=', '')}`)
      } else {
        navigate('/profile')
      }
    } catch (err) {
      console.log(err);
      setState(current => ({ ...current, error: err, loading: false }))
      if(err.name === "UserNotConfirmedException"){
        navigate(`/verify/${username}${location.search}`)
      }
    }
  }

  return {
    state,
    setState,
    login,
    signUp,
    resendCode,
    verify,
    confirmSignUp
  }
}
