import { Box, Grid, Heading, Image, Paragraph, Text } from 'grommet'
import React, { useEffect, useState } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import { useDispatch, useSelector } from 'react-redux'
import { useResponsive } from '../../hooks'
//REDUX
import { fetchBikes, selectBike } from '../../state/bikes'

import { faTruck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEditableRegions } from '../../hooks'
import parser from 'html-react-parser'
import { navigate } from 'gatsby'
import { gaEvent, fbEvent } from '../../helpers'
import BikeItem from '../Components/BikeItem';

const getImageURL = (brand, model, image) => {
  let brandCleaned = brand?.toLowerCase().replace(/ /g, '') || ''
  let modelCleaned =
    model
      ?.toLowerCase()
      .replace(/ /g, '')
      .replace('+', '') || ''

  return `https://blike.imgix.net/grid/${brandCleaned}/${modelCleaned}/${'default.jpg'}?w=600`
}

const Bike = ({ status, message, onValidated }) => {
  const [loading, setLoading] = useState(true)
  const { isDesktop, isMobile } = useResponsive()

  const { editableRegions } = useEditableRegions('Bike grid', 'Footer note')

  const bikeAllIds = useSelector(state => state.bikes.models.allIds)
  const bikebyId = useSelector(state => state.bikes.models.byId)
  const { purchaseType } = useSelector(state => state.ui)

  const dispatch = useDispatch()

  useEffect(() => {
    gaEvent('grid-view')
    fbEvent('ViewContent')
    dispatch(fetchBikes())
  }, [])

  useEffect(() => {
    if (bikeAllIds.length > 0) {
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [bikeAllIds]) // Only depend on bikeAllIds

  return loading ? (
    <>
      <Box align="center" flex fill justify="center" height={{ min: '80vh' }}>
        <Text>Fetching bikes...</Text>
        <Box pad="large">
          <ClipLoader
            color={'#03E272'}
            loading={true}
            size={40}
            speedMultiplier={0.5}
          />
        </Box>
      </Box>
    </>
  ) : (
    <>
      <Box
        // ref={ref}
        pad={{ horizontal: 'medium' }}
        direction="column"
        justify="start"
        flex
        fill
        margin={{ top: 'medium', bottom: 'large' }}
      >
        <Paragraph
          alignSelf="center"
          size="large"
          textAlign="center"
          margin={{ top: 'none', bottom: 'large' }}
        >
          {
            "All bikes are supplied new, for pre-used bikes checkout the ReCycles section"
          }
        </Paragraph>

        {bikeAllIds ? (
          <Grid
            columns={{
              count: isDesktop ? 3 : 1,
              size: '33%',
            }}
          >
            {bikeAllIds.map((bikeId, key) => {
              return (
                <a
                  href="#"
                  key={key}
                  className="bike-item"
                  onClick={e => navigate(`/bike/${bikeId}`)}
                >
                  <Box margin={{ vertical: isMobile ? 'medium' : 'none' }}>
                    <BikeItem
                      purchaseType={purchaseType}
                      bike={bikebyId[bikeId]}
                      top={key + 1 <= 3 ? true : false}
                      right={(key + 1) % 3 === 0 ? true : false}
                    />
                  </Box>
                </a>
              )
            })}
          </Grid>
        ) : (
          <Box
            align="center"
            flex
            fill
            justify="center"
            height={{ min: '80vh' }}
          >
            <Text>Fetching bikes...</Text>
            <Box pad="large">
              <ClipLoader
                color={'#03E272'}
                loading={true}
                size={40}
                speedMultiplier={0.5}
              />
            </Box>
          </Box>
        )}
      </Box>
      <Box className="editable smallprint" pad={{ horizontal: 'large' }}>
        {purchaseType === 'subscription'
          ? parser(editableRegions['Footer Terms Subscription'])
          : parser(editableRegions['Footer Terms'])}
      </Box>
    </>
  )
}

export default Bike
