import { navigate } from 'gatsby'
import { Box, Button, Heading, Paragraph, Text, FormField, TextInput } from 'grommet'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ClipLoader from 'react-spinners/ClipLoader'
import Sticky from 'react-sticky-el'
import { useResponsive } from '../../../hooks'
import { fetchAccessories } from '../../../state/accessories'
import {
  selectedAccessories,
  addDefaultAccessories,
} from '../../../state/selection'
import Rule from '../../Components/Rule'
import { Accessory } from './Accessory'
import { applyDiscount } from '../../../state/discount'

const DefaultAccessoryBox = () => {
  const { isMobile } = useResponsive()
  const accessoriesById = useSelector(state => state.accessories.byId)
  const accessories = useSelector(state => state.accessories.allIds)
  const dispatch = useDispatch()
  const bikes = useSelector(state => state.bikes.models.byId)
  const selectedBike = useSelector(state => state.selection.selectedBike.model)
  const defaultId = useRef(null)
  const [discountCode, setDiscountCode] = useState('')

  const accessoriesPicked = useSelector(
    state => state.selection.selectedAccessories
  )

  useEffect(() => {
    accessories?.map((accessory, index) => {
      if (accessoriesById[accessory]['Default Lock']) {
        defaultId.current = accessory
        accessories.length &&
          dispatch(addDefaultAccessories(accessoriesById[accessory]))
      }
    })
  }, [accessories])

  const selectItem = id => {
    dispatch(selectedAccessories(accessoriesById[id], true))
  }

  if (!defaultId.current) return null

  const applyDiscountCode = () => {
    dispatch(applyDiscount(discountCode))
  }

  return (
    <Box
      pad={{
        top: isMobile ? 'large' : 'small',
        right: 'small',
        bottom: isMobile ? null : 'small',
      }}
      margin={{ bottom: isMobile ? 'xlarge' : 'small' }}
      round="10px"
      background="#F2F2F2"
    >
      <Accessory
        defaultLock
        item={accessoriesById[defaultId.current]}
        onSelect={() => selectItem(defaultId.current)}
        bike={bikes[selectedBike]}
        selected={accessoriesPicked.some(e => e === defaultId.current)}
      />

      <Box direction={isMobile ? 'column' : 'row'} gap="small" pad={{ horizontal: "medium", vertical: isMobile ? "medium" : null }} margin={{ top: "-40px" }}>
        <TextInput
          style={{ backgroundColor: "white", marginTop: isMobile ? "10px" : null }}
          onChange={(e) => setDiscountCode(e.target.value.toUpperCase())}
          value={discountCode}
          placeholder="type here"
        />
        {/* <Button flex alignSelf='stretch' primary small width={"300px"} label="Add discount" onClick={applyDiscountCode} /> */}

        <Button
          onClick={applyDiscountCode}
          alignSelf="center"
          style={{
            maxWidth: isMobile ? null : 200 ,
          }}
          size="medium"
          primary
          fill="horizontal"
          // margin={{ vertical: 'xsmall' }}
          label={"Add discount"}
        />

      </Box>

    </Box>
  )
}

export const AccessoryChooser = () => {
  const { isMobile } = useResponsive()
  const categories = useSelector(state => state.accessories.categories)
  const accessoriesById = useSelector(state => state.accessories.byId)
  const accessories = useSelector(state => state.accessories.allIds)
  const { purchaseType } = useSelector(state => state.ui)

  const accessoriesBySourceIds = useSelector(
    state => state.accessories.sourceIds
  )

  const bikes = useSelector(state => state.bikes.models.byId)
  const selectedBike = useSelector(state => state.selection.selectedBike.model)

  const accessoriesPicked = useSelector(
    state => state.selection.selectedAccessories
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchAccessories(bikes[selectedBike]?.recycle))
  }, [])

  const selectItem = id => {
    dispatch(selectedAccessories(accessoriesById[id]))
  }

  const getPlan = () => {
    if (purchaseType === 'subscription') {
      navigate(`/checkout`)
    } else {
      navigate(`/planpicker`)
    }
  }

  const renderCategoryAccessory = (category, index) => {


    let catAccessories = []
    category['Record ID']?.split(', ').map(id => {
      let accessoryDetails = accessoriesBySourceIds[id]
      accessoryDetails && catAccessories.push(accessoryDetails)
    })

    return catAccessories.length ? (
      <React.Fragment key={index}>
        <Box margin={{ bottom: 'medium' }} flex="grow">
          <Heading level={3} size="medium" margin={{ bottom: 'none' }}>
            {category['Name']}
          </Heading>
          <Paragraph
            className="light"
            margin="none"
            size={isMobile ? 'small' : 'medium'}
          >
            {category['Name'] === 'Locks'
              ? 'You must have a Sold Secure Gold rated lock and always secure your bike with it. We include our standard lock in each plan. You can remove it if you already own an appropriate lock or if you want a different lock.'
              : category['Description']}
          </Paragraph>
        </Box>

        <>
          {category['Name'] === 'Locks' && <DefaultAccessoryBox key={index} />}
        </>

        {catAccessories.map((accessory, index) => {
          if (accessory['Default Lock']) return null
          return (
            <Accessory
              key={index}
              item={accessory}
              onSelect={() => selectItem(accessory['ID'])}
              bike={bikes[selectedBike]}
              selected={accessoriesPicked.some(e => e === accessory['ID'])}
            />
          )
        })}
      </React.Fragment>
    ) : null
  }

  return !accessories?.length ? (
    <Box align="center" flex fill justify="center" height={{ min: '80vh' }}>
      <Text>Fetching accessories...</Text>
      <Box pad="large">
        <ClipLoader
          color={'#03E272'}
          loading={true}
          size={40}
          speedMultiplier={0.5}
        />
      </Box>
    </Box>
  ) : (
    <Box flex="grow">
      <Box pad={{ left: isMobile ? 'none' : 'large' }}>
        <Heading margin={{ top: 'none', bottom: 'small' }} level={3}>
          Add Accessories
        </Heading>
        <Rule margin={{ top: '-3px', bottom: 'small' }} />
      </Box>

      <Box pad={{ left: isMobile ? 'none' : 'large' }} flex>
        {categories.map((category, index) => {
          return (
            category['Record ID'] && renderCategoryAccessory(category, index)
          )
        })}
      </Box>

      <Sticky
        mode="bottom"
        positionRecheckInterval={1}
        boundaryElement="#mainpage"
      >
        <Box fill="horizontal">
          <Box
            style={{
              height: 50,
              background: `rgb(255,255,255)`,
              background: `linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)`,
            }}
          ></Box>
          <Box fill align="end">
            <Rule margin="none" />
            <Box
              direction="row"
              fill
              justify="center"
              background="white"
              pad={{ vertical: 'medium' }}
            >
              <Button
                onClick={getPlan}
                alignSelf="end"
                color="brand"
                style={{ width: '50%', maxWidth: 350 }}
                primary
                round
                fill={isMobile}
                size="large"
                label={
                  purchaseType === 'subscription'
                    ? 'Eligibility Check'
                    : 'Select Plan'
                }
              />
            </Box>
          </Box>
        </Box>
      </Sticky>
    </Box>
  )
}
