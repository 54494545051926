import { Box } from 'grommet'
import React, { useEffect } from 'react'
import { FormDown } from 'grommet-icons'
import styled, { keyframes, css } from 'styled-components'

const Rotate = styled.div`
  transition: all 0.25s;
  transform: ${props => (props.active ? 'rotate(180deg)' : 'rotate(0deg)')};
`

export const AccordionArrow = ({ active }) => {

  return (
    <Rotate active={active}>
      <Box
        height="20px"
        width="20px"
        round="full"
        align="center"
        justify="center"
        border={{ side: 'all', size: 'small', color: 'brand' }}
      >
        <FormDown size="20px" color="brand" />
      </Box>
    </Rotate>
  )
}
