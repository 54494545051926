import React from 'react'
import { Redirect } from '@reach/router'
import { AppUser } from '../Auth'
import { Layout } from '../Layout'

class PublicRoute extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {}

  render() {
    const { isLoggedIn } = AppUser

    // if (isLoggedIn()) {
    //   return <Redirect to="/orders" noThrow />
    // }
    const {
      component: Component,
      location,
      swoosh = false,
      title, 
      noMenu = false,
      ...rest
    } = this.props
    return (
      <Layout isUserNav={isLoggedIn()} swoosh={swoosh} title={title} noMenu={noMenu}>
        <Component location={location} {...rest} />
      </Layout>
    )
  }
}

export default PublicRoute
