import React, { useEffect, useState, useContext, useRef } from 'react'
import {
  Box,
  Heading,
  Grid,
  Anchor,
  FormField,
  Text,
  Image,
  TextInput,
  Layer,
  Button,
  Paragraph,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHeader,
} from 'grommet'

const ProgressBar = () => {
    return (
      <Box fill pad={{ bottom: 'xlarge', horizontal: 'xlarge' }}>
        <ul className="steps">
          <li>
            <div>
              <span>Choose your bike</span>
            </div>
          </li>
          <li className="active">
            <div>
              <span>Accessories</span>
            </div>
          </li>
          <li>
            <div>
              <span>Your Plan</span>
            </div>
          </li>
          <li>
            <div>
              <span>Your Info</span>
            </div>
          </li>
          <li>
            <div>
              <span style={{ left: -26, top: 2, position: 'absolute' }}>
                Submit
              </span>
            </div>
          </li>
        </ul>
        {/* <ul class="step-description">
          <li>Choose your bike</li>
          <li>Accessories and Servicing</li>
          <li class="active">Your Plan</li>
          <li>Your & Employer Info</li>
          <li>Submit</li>
        </ul> */}
      </Box>
    )
  }

  export default ProgressBar;