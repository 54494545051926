import API from '@aws-amplify/api'
import {
  Box,
  Button,
  CheckBox,
  Form,
  Heading,
  Paragraph,
  Spinner,
  Text,
} from 'grommet'
import parser, { domToReact } from 'html-react-parser'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import TitleSwoosh from '../../assets/vectors/gradient-swoosh.svg'
import { employeesAPI } from '../../config'
import { useEditableRegions, useResponsive } from '../../hooks'
import { showError } from '../../state/ui'
import Rule from '../Components/Rule'
import { FormTextInput } from '../Forms'

const defaultValue = {
  company: '',
  contactEmail: '',
  contactFirstName: '',
  contactLastLame: '',
  email: '',
  firstname: '',
  lastname: '',
  marketing: false,
  phone: '',
  postcode: '',
  // subscribe: false,
}

const AboutForm = ({ employer }) => {
  const { isDesktop, isMobile } = useResponsive()
  const [value, setValue] = useState(defaultValue)
  const { name, path } = employeesAPI
  const dispatch = useDispatch()
  const [submitting, setSubmitting] = useState(false)
  const [checked, setChecked] = useState(false)
  const [success, setSuccess] = useState(false)

  const { editableRegions } = useEditableRegions('Honbike')

  const onSubmit = async () => {
    setSubmitting(true)

    const {
      company,
      contactEmail,
      contactFirstName,
      contactLastLame,
      email,
      firstname,
      lastname,
      phone,
    } = value || {}

    // if (!checked) {
    //   dispatch(
    //     showError({
    //       title: 'Error',
    //       message:
    //         'Please agree to our terms and conditions in order to continue.',
    //       type: null,
    //     })
    //   )
    //   setSubmitting(false)
    //   return
    // }

    API.post(name, `${path}/invite`, {
      body: {
        companyProperties: {
          name: company,
          lead_source: "Honbike campaign"
        },
        primaryContactProperties: {
          firstname: contactFirstName,
          lastname: contactLastLame,
          email: contactEmail
        },
        employeeProperties: {
          firstname,
          lastname,
          email,
          phone,
        },
      },
    })
      .then(response => {
        if (response.success) {
          setSuccess(true)
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(
          showError({
            title: 'Error',
            message: 'There was a problem submitting the form.',
            type: null,
          })
        )
        setSubmitting(false)
        return
      })
  }

  return (
    <Box style={{ position: 'relative' }} margin={{ top: 'large' }} flex="grow">
      <>
        <Box
          justify="center"
          fill="horizontal"
          pad={{ horizontal: 'xlarge' }}
          margin={{ bottom: 'large' }}
        >
          <Box style={{ position: 'relative' }} justify="center" align="center">
            <TitleSwoosh
              style={{
                position: 'absolute',
                top: '15%',
                bottom: 0,
                margin: 'auto 0',
                zIndex: -1,
              }}
              height="80%"
            />

            <Heading
              textAlign="center"
              style={{ maxWidth: '680px' }}
              alignSelf="center"
              level={2}
              size="xlarge"
              margin={{ vertical: 'xlarge' }}
            >
              {`Blike & Honbike`}
            </Heading>
          </Box>

          {success ? null : (
            <>
              {editableRegions['Main'] && parser(editableRegions['Main'], {
                replace: ({ name, children }) => {
                  if (name === 'p') {
                    return (
                      <Heading
                        margin={{ top: 'large', bottom: 'meidum' }}
                        level={2}
                        textAlign="center"
                        alignSelf="center"
                      >
                        {domToReact(children)}{' '}
                      </Heading>
                    )
                  }
                },
              })}

              {editableRegions['Large Paragraph'] && parser(editableRegions['Large Paragraph'], {
                replace: ({ name, children }) => {
                  if (name === 'p') {
                    return (
                      <Paragraph textAlign="center" alignSelf="center">
                        {domToReact(children)}{' '}
                      </Paragraph>
                    )
                  }
                },
              })}

              <Box
                border={{ side: 'bottom', size: '2px', color: 'brand' }}
                width="200px"
                alignSelf="center"
                margin={{ vertical: 'medium' }}
              ></Box>

              {editableRegions['Points'] && parser(editableRegions['Points'], {
                replace: ({ name, children }) => {
                  if (name === 'p') {
                    return (
                      <Heading
                        level={4}
                        margin="xsmall"
                        textAlign="center"
                        alignSelf="center"
                        color="brand"
                      >
                        {' '}
                        {domToReact(children)}{' '}
                      </Heading>
                    )
                  }
                },
              })} 
            </>
          )}
        </Box>

        {success ? (
          <Box pad="large">
            <Heading
              level={2}
              margin="xsmall"
              textAlign="center"
              alignSelf="center"
              color="black"
            >
              {`Thank you. We'll be in touch shortly.`}
            </Heading>
          </Box>
        ) : (
          <>
            <Box
              direction="column"
              justify="start"
              flex={false}
              pad={{ horizontal: 'large' }}
              margin={{ bottom: 'large' }}
            >
              <Box fill gap="xlarge">
                <Box>
                  <Form
                    value={value}
                    onChange={nextValue => setValue(nextValue)}
                    onReset={() => setValue({})}
                    onSubmit={onSubmit}
                  >
                    <Heading
                      margin={{ top: 'large', bottom: 'small' }}
                      level={3}
                    >
                      Your Contact Details
                    </Heading>
                    <Rule margin={{ top: 'none', bottom: 'small' }} />
                    {/* <ContactAdd contacts={contacts} setContacts={setContacts} /> */}

                    <Box
                      fill="horizontal"
                      flex={isDesktop ? true : false}
                      direction={isMobile ? 'column' : 'row'}
                      gap="medium"
                    >
                      <FormTextInput
                        name={`firstname`}
                        placeholder={`First Name`}
                      />
                      <FormTextInput
                        name={`lastname`}
                        placeholder={`Last Name`}
                      />
                    </Box>
                    <Box
                      fill="horizontal"
                      flex={isDesktop ? true : false}
                      direction={isMobile ? 'column' : 'row'}
                      gap="medium"
                    >
                      <FormTextInput
                        name={`phone`}
                        required={false}
                        placeholder={`Phone Number (Optional)`}
                        // validate={[
                        //   {
                        //     regexp: /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/i,
                        //     message: 'Must be a valid UK phone number',
                        //     status: 'error',
                        //   },
                        // ]}
                      />
                      <FormTextInput
                        name={`email`}
                        placeholder={`Email`}
                        validate={[
                          {
                            regexp: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                            message: 'Must be a valid email',
                            status: 'error',
                          },
                        ]}
                      />
                    </Box>

                    <Heading
                      margin={{ top: 'large', bottom: 'small' }}
                      level={3}
                    >
                      Your Employers Details
                    </Heading>
                    <Rule margin={{ top: 'none', bottom: 'small' }} />
                    {/* <ContactAdd contacts={contacts} setContacts={setContacts} /> */}

                    <Box
                      fill="horizontal"
                      flex={isDesktop ? true : false}
                      direction={isMobile ? 'column' : 'row'}
                      gap="medium"
                    >
                      <FormTextInput
                        name={`company`}
                        placeholder={`Company Name`}
                      />
                      <FormTextInput
                        name={`contactFirstName`}
                        placeholder={`Contact First Name`}
                      />
                    </Box>
                    <Box
                      fill="horizontal"
                      flex={isDesktop ? true : false}
                      direction={isMobile ? 'column' : 'row'}
                      gap="medium"
                    >
                      <FormTextInput
                        name={`contactLastLame`}
                        required={false}
                        placeholder={`Contact Last Name`}
                        // validate={[
                        //   {
                        //     regexp: /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/i,
                        //     message: 'Must be a valid UK phone number',
                        //     status: 'error',
                        //   },
                        // ]}
                      />
                      <FormTextInput
                        name={`contactEmail`}
                        placeholder={`Contact Email`}
                        validate={[
                          {
                            regexp: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                            message: 'Must be a valid email',
                            status: 'error',
                          },
                        ]}
                      />
                    </Box>

                   
                    <Box
                      fill="horizontal"
                      flex={isDesktop ? true : false}
                      direction={isMobile ? 'column' : 'row'}
                      gap="medium"
                      margin={{ top: '50px' }}
                    >
                      <Box flex="shrink" align="center">
                        <Button
                          style={{ width: 250, textAlign: 'center' }}
                          disabled={submitting}
                          justify="center"
                          type="submit"
                          icon={
                            submitting ? (
                              <Spinner
                                style={{ margin: '0 auto' }}
                                alignSelf="center"
                                size="xsmall"
                              />
                            ) : null
                          }
                          alignSelf="start"
                          primary
                          size="large"
                          label={submitting ? null : 'Submit Details'}
                        />
                      </Box>
                      <Box flex basis="1/2"></Box>
                    </Box>
                  </Form>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </>
    </Box>
  )
}

export default AboutForm
