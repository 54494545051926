import API from '@aws-amplify/api'
import Auth from '@aws-amplify/auth'
import { navigate } from 'gatsby'
import {
  Anchor,
  Box,
  Button,
  Form,
  Grid,
  Heading,
  Paragraph,
  Spinner,
  Text,
} from 'grommet'
import { FormLock, View } from 'grommet-icons'
import LogRocket from 'logrocket'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Sticky from 'react-sticky-el'
import { usersAPI, emailCheckAPI } from '../../config'
import { fbEvent, gaEvent } from '../../helpers'
import { useResponsive } from '../../hooks'
import { placeOrder } from '../../state/order'
import { showError } from '../../state/ui'
import Rule from '../Components/Rule'
import Sidebar from '../Components/Sidebar'
import MobileTopBar from '../Components/Sidebar/MobileTopBar'
import { FormTextInput } from '../Forms'
import ClipLoader from 'react-spinners/ClipLoader'

const defaultValue = {
  firstname: '',
  lastname: '',
  emailPersonal: '',
  emailWork: '',
  emailPersonalValidate: '',
  emailWorkValidate: '',
  mobileNumber: '',
  homePostcode: '',
  homeAddress1: '',
  homeAddress2: '',
  companyName: '',
  companyContactFirstname: '',
  companyContactLastname: '',
  companyContactTitle: '',
  companyContactEmail: '',
  companyContactPhone: '',
  companyCode: '',
  password: '',
  confirmPassword: '',
}

const AboutForm = ({}) => {
  const { isDesktop, isMobile } = useResponsive()

  return (
    <Box
      direction="column"
      justify="start"
      flex={false}
      fill
      margin={{ bottom: 'large' }}
      pad={{ horizontal: 'large' }}
    >
      <Grid columns={isMobile ? ['auto'] : ['350px', 'flex']}>
        {isDesktop && (
          <Box id="boundary">
            <Sticky
              mode="top"
              positionRecheckInterval={1}
              boundaryElement="#boundary"
            >
              <Box fill>
                <Sidebar step="plan" />
              </Box>
            </Sticky>
          </Box>
        )}
        {isMobile && (
          <Box flex>
            <MobileTopBar />
          </Box>
        )}
        <Box flex>
          <RegisterForm />
        </Box>
      </Grid>
    </Box>
  )
}

export const useAuth = () => {
  let { name: usersApiName } = usersAPI
  const [loading, setLoading] = useState(true)

  const [isLoggedIn, setIsLoggedIn] = useState()
  const [user, setUser] = useState(null)

  useEffect(() => {
    getAuthedUser()
  }, [])

  const getAuthedUser = async () => {
    try {
      setLoading(true)
      await Auth.currentAuthenticatedUser()
      setIsLoggedIn(true)

      let response = await API.get(usersApiName, '/user')
      setUser(response.user)
      setLoading(false)
    } catch {
      setLoading(false)
      setIsLoggedIn(false)
    }
  }

  return { isLoggedIn, user, loading }
}

const Number = ({ number, complete = true }) => {
  return (
    <Box
      border={{ color: complete ? 'brand-third' : 'brand', size: '2px' }}
      round="full"
      width={{ min: '50px', max: '50px' }}
      height="50px"
      align="center"
      shrink={false}
      justify="center"
      background={complete ? 'brand-third' : null}
    >
      <Paragraph
        style={{ fontSize: 25, textAlign: 'center' }}
        color={complete ? 'brand-secondary' : 'brand'}
      >
        {number}
      </Paragraph>
    </Box>
  )
}

const FormSection = ({
  children,
  heading = 'Your details',
  number = 1,
  rightItem = null,
}) => {
  const { isDesktop, isMobile } = useResponsive()

  return (
    <Box direction="row" gap="40px">
      {isDesktop && <Number number={number} />}
      <Box pad={{ bottom: 'medium' }} flex="grow">
        <Box direction="row" justify="between" align="center">
          <Heading margin={{ top: 'none', bottom: 'small' }} level={3}>
            {heading}
          </Heading>
          {rightItem}
        </Box>

        <Rule margin={{ top: 'none', bottom: 'small' }} />
        {children}
      </Box>
    </Box>
  )
}

const PersonalDetails = ({ user, emailCheck }) => {
  const { isDesktop, isMobile } = useResponsive()
  const dispatch = useDispatch()

  return (
    <>
      <Box
        direction={isMobile ? 'column' : 'row'}
        gap={isDesktop ? 'medium' : 'none'}
      >
        <FormTextInput
          name="firstname"
          placeholder="First Name"
          disabled={user}
        />
        <FormTextInput
          name="lastname"
          placeholder="Last Name"
          disabled={user}
        />
      </Box>
      <Box
        direction={isMobile ? 'column' : 'row'}
        gap={isDesktop ? 'medium' : 'none'}
      >
        <FormTextInput
          onBlur={e => !user && emailCheck(e.target.value)}
          name="emailPersonal"
          placeholder="Email"
          disabled={user}
          validate={[
            {
              regexp: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
              message: 'Must be a valid email',
              status: 'error',
            },
          ]}
        />
        <FormTextInput
          name="emailPersonalValidate"
          disabled={user}
          placeholder="Confirm Email"
          validate={(val, form) => {
            if (val !== form.emailPersonal) {
              return {
                message: "Your emails don't match",
                status: 'error',
              }
            }
            return undefined
          }}
        />
      </Box>

      <Box
        direction={isMobile ? 'column' : 'row'}
        gap={isDesktop ? 'medium' : 'none'}
      >
        <FormTextInput
          name="mobileNumber"
          placeholder="Mobile Number"
          validate={null}
          required={false}
          disabled={user}
        />
        <FormTextInput
          name="homePostcode"
          placeholder="Postcode"
          required={false}
          disabled={user}
          validate={[
            {
              regexp: /^(GIR[ ]?0AA|((AB|AL|B|BA|BB|BD|BH|BL|BN|BR|BS|BT|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE)(\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}))|BFPO[ ]?\d{1,4})$/,
              message: 'Must be a valid UK postcode',
              status: 'error',
            },
          ]}
        />
      </Box>
    </>
  )
}

const EmploymentDetails = ({ user }) => {
  const { isDesktop, isMobile } = useResponsive()

  const { employer } = useSelector(state => state.employee)
  const [employerCode, setEmployerCode] = useState(employer)

  useEffect(() => {
    user?.employer && setEmployerCode(true)
  }, [user])

  return employerCode || employer ? (
    <>
      <Box
        direction={isMobile ? 'column' : 'row'}
        gap={isDesktop ? 'medium' : 'none'}
      >
        <FormTextInput
          name="companyCode"
          placeholder="Your Unique Code"
          validate={null}
          value={user ? user.employer : employer ? employer : ''}
          disabled={user?.employer || employer}
        />

        {!user && (
          <Box fill="horizontal" align="end" justify="center">
            <a
              href="#"
              onClick={() => {
                setEmployerCode(false)
              }}
            >
              Don't have an employee code?
            </a>
          </Box>
        )}
      </Box>
    </>
  ) : (
    <>
      <Box
        direction={isMobile ? 'column' : 'row'}
        gap={isDesktop ? 'medium' : 'none'}
      >
        <FormTextInput
          name="companyName"
          placeholder="Company Name"
          validate={null}
        />
        <Box fill="horizontal" align="end" justify="center">
          <Paragraph margin={{bottom: "medium", top: "none"}} size={isMobile ? "small" : "medium"}>
            <a href="#" onClick={() => setEmployerCode(true)}>
              Have an employee code?
            </a>
          </Paragraph>
        </Box>
      </Box>

      <Box
        direction={isMobile ? 'column' : 'row'}
        gap={isDesktop ? 'medium' : 'none'}
      >
        <FormTextInput
          name="companyContactFirstname"
          placeholder="Contact First Name"
        />
        <FormTextInput
          name="companyContactLastname"
          placeholder="Contact Last Name"
        />
      </Box>
      <Box
        direction={isMobile ? 'column' : 'row'}
        gap={isDesktop ? 'medium' : 'none'}
      >
        <FormTextInput
          name="companyContactTitle"
          placeholder="Contact Job Title"
        />
        <FormTextInput
          name="companyContactEmail"
          placeholder="Contact Email"
          validate={[
            {
              regexp: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
              message: 'Must be a valid email',
              status: 'error',
            },
          ]}
        />
      </Box>
      <Box
        direction={isMobile ? 'column' : 'row'}
        gap={isDesktop ? 'medium' : 'none'}
      >
        <FormTextInput
          name="companyContactPhone"
          placeholder="Phone Number (Optional)"
          required={false}
          validate={null}

          // validate={value =>
          //   value !== ''
          //     ? {
          //         regexp: null,
          //         message: 'Must be a valid UK phone number',
          //         status: 'error',
          //       }
          //     : true
          // }
        />
        <Box fill={"horizontal"}></Box>
      </Box>
    </>
  )
}

const CreatePassword = ({ stage, setStage }) => {
  const { isDesktop, isMobile } = useResponsive()
  const [reveal, setReveal] = useState(false)
  const [confirmReveal, setConfirmReveal] = useState(false)

  return (
    <>
      <Box
        direction={isMobile ? 'column' : 'row'}
        gap={isDesktop ? 'medium' : 'none'}
      >
        <Box
          fill="horizontal"
          direction="row"
          align="start"
          justify="start"
          style={{ position: 'relative' }}
        >
          <FormTextInput
            autoComplete="off"
            name="password"
            placeholder="Password"
            type={reveal ? 'text' : 'password'}
            info={'Must contain uppercase, lowercase, digits and one symbol'}
          />
          <Button
            style={{ position: 'absolute', right: 0, top: 13 }}
            icon={reveal ? <FormLock size="21px" /> : <View size="17px" />}
            onClick={() => setReveal(!reveal)}
          />
        </Box>
        <Box
          fill="horizontal"
          direction="row"
          align="start"
          justify="start"
          style={{ position: 'relative' }}
        >
          <FormTextInput
            autoComplete="off"
            name="passwordConfirm"
            placeholder="Confirm Password"
            type={confirmReveal ? 'text' : 'password'}
            validate={(val, form) => {
              if (val !== form.password) {
                return {
                  message: "Your passwords don't match",
                  status: 'error',
                }
              }
              return undefined
            }}
          />
          <Button
            style={{ position: 'absolute', right: 0, top: 13 }}
            icon={
              confirmReveal ? <FormLock size="21px" /> : <View size="17px" />
            }
            onClick={() => setConfirmReveal(!confirmReveal)}
          />
        </Box>
      </Box>
    </>
  )
}

const RegisterForm = () => {
  const { email, employer, employerName } = useSelector(state => state.employee)
  const { isLoggedIn, user, loading } = useAuth()
  const [value, setValue] = useState({
    ...defaultValue,
  })

  let { name, path } = emailCheckAPI

  const emailCheck = email => {
    API.get(name, path, {
      queryStringParameters: {
        email,
      },
    })
      .then(response => {
        console.log(response)
        if (response.exists) {
          dispatch(
            showError({
              title: 'Email In Use',
              message:
                'An account with this email already exists. Please login to continue.',
              type: 'login',
            })
          )
        }
        // // setUser(response.user)
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {

    gaEvent('select-plan');
    fbEvent('InitiateCheckout');

    if (email && !user && !loading) {
      emailCheck(email)
    }

    setValue({
      firstname: user ? user?.Name.split(' ')[0] : '',
      lastname: user ? user?.Name.split(' ')[1] : '',
      emailPersonal: email || user?.Email || '',
      emailPersonalValidate: user?.Email || '',
      companyCode: employer,
      companyName: employerName,
      homePostcode: user?.Postcode,
      mobileNumber: user?.Phone,
    })
  }, [user, loading, email])

  const dispatch = useDispatch()
  const { isDesktop, isMobile } = useResponsive()
  const [formStage, setFormStage] = useState()
  const [submitting, setSubmitting] = useState(false)

  const onSubmit = async ({ value }) => {
    setSubmitting(true)
    let { emailPersonal, password, firstname, lastname } = value
    try {
      let user = {}

      if (!isLoggedIn) {
        user = await Auth.signUp({
          username: emailPersonal,
          password,
        })
      } else {
        let cognitoUser = await Auth.currentAuthenticatedUser()
        user.userSub = cognitoUser.username
      }

      if (process.env.NODE_ENV === 'production') {
        LogRocket.identify(user.userSub, {
          name: `${firstname} ${lastname}`,
          email: emailPersonal,
        })
      }

      dispatch(placeOrder({ user: user.userSub, value }))
      setSubmitting(false)

      // isLoggedIn
      //   ? navigate(`/confirmation/`)
      //   : user.userSub && navigate(`/confirmation/${user.userSub}`)

      user.userSub && navigate(`/confirmation/${user.userSub || ""}`)

    } catch (err) {
      dispatch(
        showError({
          title: 'Error Registering',
          message: err.message,
          type:
            err.message === 'An account with the given email already exists.'
              ? 'login'
              : null,
        })
      )
      setSubmitting(false)
    }
  }

  if (loading)
    return (
      <Box align="center" flex fill justify="center" height={{ min: '80vh' }}>
        <Text>Fetching your details...</Text>
        <ClipLoader
          color={'#03E272'}
          loading={true}
          size={40}
          speedMultiplier={0.5}
        />
      </Box>
    )

  return (
    <Box flex="grow" pad={{ left: isMobile ? 'none' : 'large' }}>
      <Form
        value={value}
        onChange={nextValue => setValue(nextValue)}
        onReset={() => setValue({})}
        onSubmit={onSubmit}
      >
        <FormSection
          number="1"
          heading="Personal details"
          rightItem={
            !isLoggedIn ? (
              <a href="#" onClick={() => navigate('/signin?redirect=register')}>
                Already have an account?
              </a>
            ) : null
          }
        >
          <PersonalDetails
            stage={formStage}
            setStage={setFormStage}
            user={user}
            emailCheck={emailCheck}
          />
        </FormSection>
        <FormSection number="2" heading="Your Employer">
          <EmploymentDetails
            stage={formStage}
            setStage={setFormStage}
            user={user}
          />
        </FormSection>
        {!isLoggedIn && (
          <FormSection number="3" heading="Create Account">
            <CreatePassword
              stage={formStage}
              setStage={setFormStage}
              user={user}
            />
          </FormSection>
        )}
        <Box pad={{ vertical: 'medium' }} flex="shrink">
          <Box
            direction={isMobile ? 'column' : 'row'}
            gap={isDesktop ? 'medium' : 'none'}
          >
            <Box flex basis="1/2"></Box>
            <Box flex basis="1/2" margin={{ top: 'small' }}>
              <Button
                fill="horizontal"
                type="submit"
                primary
                size="large"
                disabled={submitting}
                icon={
                  submitting ? (
                    <Spinner
                      style={{ margin: '0 auto' }}
                      alignSelf="center"
                      size="xsmall"
                    />
                  ) : null
                }
                label={submitting ? null : 'Confirm & Submit'}
              />
              <Anchor
                size="small"
                margin={{
                  left: 'xsmall',
                  vertical: 'small',
                }}
                alignSelf="center"
                className="smallprint"
                href="/privacy-policy/"
                label="Blike Privacy Policy"
              ></Anchor>
            </Box>
          </Box>
        </Box>
      </Form>
    </Box>
  )
}

export default AboutForm
