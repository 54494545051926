import Auth from '@aws-amplify/auth'
import { navigate } from 'gatsby'
import {
  Box, Button,
  Form, Grid, Heading, Paragraph
} from 'grommet'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Sidebar from '../../components/Components/Sidebar'
import { useResponsive } from '../../hooks'
import { clearOrders } from '../../state/order'
import MobileTopBar from '../Components/Sidebar/MobileTopBar'
import { FormTextInput } from '../Forms'
import { useAuth } from '../Pages/Form'
import { gaEvent, fbEvent } from '../../helpers'

const Rule = ({ margin = { vertical: 'medium', horizontal: 'none' } }) => {
  return (
    <Box
      margin={margin}
      fill="horizontal"
      border={{
        color: 'border',
        size: '1px',
        style: 'solid',
        side: 'top',
      }}
    />
  )
}

const defaultValue = {
  confirmation: '',
}

const Summary = ({ userId }) => {
  const [selected, setSelected] = useState([1])
  const [success, setSuccess] = useState('')
  const [value, setValue] = useState(defaultValue)
  const { isDesktop, isMobile } = useResponsive()
  const { isLoggedIn, user } = useAuth()

  // const { id, email } = useSelector(state => state.order);
  // const [orderNo, setOrderNo]=useState();

  const dispatch = useDispatch()

  useEffect(() => {
    gaEvent('submit-application');
    fbEvent('SubmitApplication');
    dispatch(clearOrders())
  }, [])

  const resendCode = async e => {
    e.preventDefault()
    try {
      await Auth.resendSignUp(userId)
      alert('code resent successfully')
    } catch (err) {
      alert('error resending code: ', err)
    }
  }

  const onSubmit = async ({ value }) => {
    try {
      let result = await Auth.confirmSignUp(userId, value.confirmation)
      if (result === 'SUCCESS') {
        setSuccess(true);
        gaEvent('sign_up');
        fbEvent('CompleteRegistration');
      }
    } catch (error) {
      alert('error confirming sign up', error)
    }
  }

  return (
    <>
      <Box
        // ref={ref}
        direction="column"
        justify="start"
        flex={false}
        pad={{ horizontal: 'large' }}
        margin={{ bottom: 'large' }}
      >
        <Grid columns={isMobile ? ['auto'] : ['350px', 'flex']}>
          {isMobile ? (
            <Box flex>
              <MobileTopBar />
            </Box>
          ) : (
            <Sidebar disabled />
          )}

          <Box pad={{ left: isMobile ? 'none' : 'large' }}>
            {success || isLoggedIn ? (
              <Box fill align="center">
                <Heading>{'Thank You'}</Heading>
                <Paragraph margin="none">
                  Your your order has been submitted.
                </Paragraph>

                <Paragraph margin="none" textAlign="center">
                  You will hear from us as soon as you order has been approved
                  by your employer.
                </Paragraph>

                {isLoggedIn ? (
                  <Paragraph margin="none" textAlign="center">
                    You can view your order by going to your account{' '}
                    <a href="#" onClick={() => navigate('/profile')}>
                      your account
                    </a>
                  </Paragraph>
                ) : (
                  <Paragraph margin="none" textAlign="center">
                    You can sign into your account and view your order{' '}
                    <a href="#" onClick={() => navigate('/signin')}>
                      click here
                    </a>
                  </Paragraph>
                )}
              </Box>
            ) : (
              <>
                <Heading margin={{ top: 'none', bottom: 'small' }} level={3}>
                  Verify Account & Submit Order
                </Heading>
                <Rule margin={{ top: 'none', bottom: 'small' }} />
                <Paragraph>
                  {`We have just sent you a verification code to your email address`}
                </Paragraph>
                <Form
                  value={value}
                  onChange={nextValue => setValue(nextValue)}
                  onReset={() => setValue({})}
                  onSubmit={onSubmit}
                >
                  <Box
                    direction="column"
                    gap="small"
                    width={{ max: isMobile ? 'none' : '50%', min: '300px' }}
                  >
                    <FormTextInput
                      width="50%"
                      validate={null}
                      name="confirmation"
                      placeholder="Verification Code"
                    />

                    <Button
                      type="submit"
                      style={{ maxWidth: 250 }}
                      margin={{ bottom: 'medium' }}
                      primary
                      size="large"
                      label="Confirm & Submit"
                    />
                    <a href="#" onClick={resendCode}>
                      Resend Code
                    </a>
                  </Box>
                </Form>
              </>
            )}
          </Box>
        </Grid>
      </Box>
    </>
  )
}

export default Summary
