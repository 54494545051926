import { Box, Button, Grid, Heading, Image, Paragraph } from 'grommet'
import { Checkmark } from 'grommet-icons'
import React from 'react'
import { useResponsive } from '../../../hooks'
import Rule from '../../Components/Rule'
import { useSelector } from 'react-redux'

export const Accessory = ({
  item,
  onSelect,
  selected,
  bike,
  defaultLock = false,
}) => {
  const { isMobile, isDesktop } = useResponsive()
  const { purchaseType } = useSelector(state => state.ui)

  const getImageURL = () => {
    const brand = bike?.Brands?.toLowerCase().replace(/ /g, '') || null
    const model = bike?.Model?.toLowerCase().replace(/ /g, '') || null

    return item['File location & Name'] && brand && model
      ? `https://blike.imgix.net/${encodeURIComponent(
          item['File location & Name']
        )}?w=250&q=64&auto=format`
      : null
  }

  return (
    <Box flex={false}>
      <Grid
        gap={isMobile ? 'none' : 'medium'}
        columns={isMobile ? ['80px', 'auto'] : ['110px', 'flex', '125px']}
        rows={isMobile ? ['auto', '100px'] : ['auto']}
        areas={
          isMobile
            ? [
                { name: 'image', start: [0, 0], end: [0, 0] },
                { name: 'description', start: [1, 0], end: [1, 0] },
                { name: 'checkmark', start: [0, 1], end: [0, 1] },
                { name: 'price', start: [1, 1], end: [1, 1] },
              ]
            : [
                { name: 'image', start: [0, 0], end: [0, 0] },
                { name: 'description', start: [1, 0], end: [1, 0] },
                { name: 'price', start: [2, 0], end: [2, 0] },
              ]
        }
      >
        <Box justify="center" style={{ position: 'relative' }} gridArea="image">
          {selected && isDesktop && (
            <Box
              style={{ position: 'absolute', left: -40 }}
              background="brand"
              round
              pad="xsmall"
            >
              <Checkmark color="white" align="center" size="20px" />
            </Box>
          )}
          <Box
            justify="center"
            align="center"
            fill
            style={{ maxWidth: 'none', maxHeight: isMobile ? '80px' : '110px' }}
          >
            <Image fill fit="contain" src={getImageURL()} />
          </Box>
        </Box>
        <Box justify="center" gridArea="description">
          <Heading
            truncate
            level="4"
            margin={{ top: 'none', bottom: 'xsmall' }}
            className="bold"
          >
            {item['Display Name']}
          </Heading>
          <Paragraph
            className="light"
            size={isMobile ? 'xsmall' : 'small'}
            margin="none"
            fill
          >
            {item.Description}
          </Paragraph>
        </Box>

        {isMobile && (
          <Box gridArea="checkmark" justify="center">
            {selected && (
              <Box alignSelf="center" background="brand" round pad="xsmall">
                <Checkmark color="white" align="center" size="20px" />
              </Box>
            )}
          </Box>
        )}
        <Box
          gridArea="price"
          justify="end"
          align="center"
          flex
          gap={isMobile ? 'medium' : 'none'}
          direction={isMobile ? 'row' : 'column'}
        >
          <Box
            fill="horizontal"
            direction={isMobile ? 'row' : 'column'}
            align="center"
            justify="start"
          >
            <Heading
              level={4}
              margin={{ vertical: 'none' }}
              responsive={false}
            >{`${!selected ? `+` : `-`}£${
              purchaseType === 'subscription'
                ? item['Sub Monthly'].toFixed(2)
                : item.RRP.toFixed(2)
            }`}</Heading>
            <Paragraph
              margin={{ vertical: 'none', left: 'xsmall' }}
              size="xsmall"
            >
              {purchaseType === 'subscription' ? 'per month' : ''}
            </Paragraph>
          </Box>

          <Button
            onClick={onSelect}
            alignSelf="center"
            style={{
              maxWidth: 100,
              color: selected ? 'white' : '#0f4c3d',
            }}
            size="small"
            secondary
            active
            fill="horizontal"
            color={!selected ? 'brand-third' : 'black'}
            margin={{ vertical: 'xsmall' }}
            label={!selected ? 'Add' : 'Remove'}
          />
        </Box>
      </Grid>
      <Box margin={{ horizontal: defaultLock ? 'medium' : 'none' }}>
        <Rule
          margin={{ top: isMobile ? 'none' : 'small' }}
          pad={{ bottom: 'large' }}
        />
      </Box>
    </Box>
  )
}
