import React, { useEffect, useState, useContext, useRef } from 'react'
import {
  Box,
  Heading,
  Grid,
  Text,
  Image,
  Paragraph,
  Button,
  Form,
  Spinner,
} from 'grommet'
import { useResponsive } from '../../hooks'
import { Checkmark } from 'grommet-icons'
import { FormTextInput } from '../Forms'
import blackbike from '../../assets/images/bikes/cowboy-3-black.png'
import quadlock from '../../assets/images/quadlock.png'
import MainSwoosh from '../../assets/vectors/swooshmain.svg'
import Sidebar from '../Components/Sidebar'
import ProgressBar from '../Components/ProgressBar'
import Auth from '@aws-amplify/auth'
import { navigate, Link } from 'gatsby'
import { useLocation } from '@reach/router'
import TitleSwoosh from '../../assets/vectors/gradient-swoosh.svg'
import { useEditableRegions } from '../../hooks'
import parser, { domToReact } from 'html-react-parser'
import ClipLoader from 'react-spinners/ClipLoader'

const Rule = ({ margin = { vertical: 'medium', horizontal: 'none' } }) => {
  return (
    <Box
      margin={margin}
      fill="horizontal"
      border={{
        color: 'border',
        size: '1px',
        style: 'solid',
        side: 'top',
      }}
    />
  )
}

const defaultValue = {
  confirmation: '',
}

const Summary = ({ sub: userId }) => {
  const [selected, setSelected] = useState([1])
  const [success, setSuccess] = useState(false)
  const [value, setValue] = useState(defaultValue)
  const [submitting, setSubmitting] = useState(false)

  const location = useLocation()

  const { editableRegions } = useEditableRegions(
    'Employer Sign Up Confirmation Form'
  )

  const resendCode = async e => {
    e.preventDefault()
    try {
      await Auth.resendSignUp(userId)
      alert('code resent successfully')
    } catch (err) {
      alert('error resending code: ', err)
    }
  }

  const onSubmit = async ({ value }) => {
    setSubmitting(true)
    try {
      let result = await Auth.confirmSignUp(
        userId,
        value.confirmation.toString()
      )
      if (result === 'SUCCESS') {
        setSuccess(true)
        setSubmitting(false)
      }
    } catch (error) {
      alert('error confirming sign up', error.message)
      setSubmitting(false)
    }
  }

  console.log('userId', userId)

  return (
    <>
      <Box
        // ref={ref}
        direction="column"
        justify="start"
        flex={false}
        margin={{ vertical: 'large' }}
      >
        <>
          <Box fill justify="center" align="center">
            <Box
              style={{ position: 'relative' }}
              justify="center"
              align="center"
              margin={{ bottom: 'large' }}
            >
              <TitleSwoosh
                style={{
                  position: 'absolute',
                  top: '15%',
                  bottom: 0,
                  margin: 'auto 0',
                  zIndex: -1,
                }}
                height="80%"
              />
              <Heading
                textAlign="center"
                style={{ maxWidth: '680px' }}
                alignSelf="center"
                level={2}
                size="xlarge"
                margin={{ vertical: 'xlarge' }}
              >
                {`Thank you!`}
              </Heading>
            </Box>

            {parser(editableRegions['Intro'], {
              replace: ({ name, children }) => {
                if (name === 'p') {
                  console.log(children)
                  return (
                    <Heading
                      margin="none"
                      level={3}
                      textAlign="center"
                      alignSelf="center"
                    >
                      {domToReact(children)}
                    </Heading>
                  )
                }
              },
            })}

            <Box
              border={{ side: 'bottom', size: '2px', color: 'brand' }}
              width="200px"
              alignSelf="center"
              margin={{ vertical: 'large' }}
            ></Box>

            {parser(editableRegions['Title'], {
              replace: ({ name, children }) => {
                if (name === 'p') {
                  console.log(children)
                  return (
                    <Heading
                      margin={{ bottom: 'medium', top: 'none' }}
                      level={4}
                      textAlign="center"
                      alignSelf="center"
                    >
                      {domToReact(children)}
                    </Heading>
                  )
                }
              },
            })}

            <Box margin={{ vertical: 'large' }}>
              {parser(editableRegions['Paragraph'], {
                replace: ({ name, children }) => {
                  if (name === 'p') {
                    return (
                      <Paragraph textAlign="center" alignSelf="center">
                        {domToReact(children)}{' '}
                      </Paragraph>
                    )
                  }
                },
              })}
            </Box>
          </Box>
        </>
      </Box>
    </>
  )
}

export default Summary
