import React, { useEffect, useState, useContext, useRef } from 'react'
import {
  Box,
  Heading,
  Grid,
  Text,
  Image,
  Paragraph,
  Button,
  Form,
  Spinner,
} from 'grommet'
import { useResponsive } from '../../hooks'
import { Checkmark } from 'grommet-icons'
import { FormTextInput } from '../Forms'
import blackbike from '../../assets/images/bikes/cowboy-3-black.png'
import quadlock from '../../assets/images/quadlock.png'
import MainSwoosh from '../../assets/vectors/swooshmain.svg'
import Sidebar from '../../components/Components/Sidebar'
import ProgressBar from '../../components/Components/ProgressBar'
import Auth from '@aws-amplify/auth'
import { navigate, Link } from 'gatsby'
import { useLocation } from '@reach/router'
import TitleSwoosh from '../../assets/vectors/gradient-swoosh.svg'
import { useEditableRegions } from '../../hooks'
import parser, { domToReact } from 'html-react-parser'
import ClipLoader from 'react-spinners/ClipLoader'

const Rule = ({ margin = { vertical: 'medium', horizontal: 'none' } }) => {
  return (
    <Box
      margin={margin}
      fill="horizontal"
      border={{
        color: 'border',
        size: '1px',
        style: 'solid',
        side: 'top',
      }}
    />
  )
}

const defaultValue = {
  confirmation: '',
}

const Summary = ({ sub: userId, name, id }) => {
  const [selected, setSelected] = useState([1])
  const [success, setSuccess] = useState(false)
  const [value, setValue] = useState(defaultValue)
  const [submitting, setSubmitting] = useState(false)

  const location = useLocation()

  const { editableRegions } = useEditableRegions(
    'Employer Sign Up Confirmation'
  )

  const resendCode = async e => {
    e.preventDefault()
    try {
      await Auth.resendSignUp(userId)
      alert('code resent successfully')
    } catch (err) {
      alert('error resending code: ', err)
    }
  }

  const onSubmit = async ({ value }) => {
    setSubmitting(true)
    try {
      let result = await Auth.confirmSignUp(
        userId,
        value.confirmation.toString()
      )
      if (result === 'SUCCESS') {
        setSuccess(true)
        setSubmitting(false)
      }
    } catch (error) {
      alert('error confirming sign up', error.message)
      setSubmitting(false)
    }
  }

  return (
    <>
      <Box
        // ref={ref}
        direction="column"
        justify="start"
        flex={false}
        margin={{ vertical: 'large' }}
      >
        {name ? (
          <Box pad={{ horizontal: 'large' }}>
            {success ? (
              <Box fill justify="center" align="center">
                <Box
                  style={{ position: 'relative' }}
                  justify="center"
                  align="center"
                  margin={{ bottom: 'large' }}
                >
                  <TitleSwoosh
                    style={{
                      position: 'absolute',
                      top: '15%',
                      bottom: 0,
                      margin: 'auto 0',
                      zIndex: -1,
                    }}
                    height="80%"
                  />
                  <Heading
                    textAlign="center"
                    style={{ maxWidth: '680px' }}
                    alignSelf="center"
                    level={2}
                    size="xlarge"
                    margin={{ vertical: 'xlarge' }}
                  >
                    {`Great to have you onboard!`}
                  </Heading>
                </Box>

                {parser(editableRegions['Intro'], {
                  replace: ({ name, children }) => {
                    if (name === 'p') {
                      console.log(children)
                      return (
                        <Heading
                          margin="none"
                          level={3}
                          textAlign="center"
                          alignSelf="center"
                        >
                          {domToReact(children)}
                        </Heading>
                      )
                    }
                  },
                })}

                <Box
                  border={{ side: 'bottom', size: '2px', color: 'brand' }}
                  width="200px"
                  alignSelf="center"
                  margin={{ vertical: 'large' }}
                ></Box>

                {parser(editableRegions['Title'], {
                  replace: ({ name, children }) => {
                    if (name === 'p') {
                      console.log(children)
                      return (
                        <Heading
                          margin={{ bottom: 'medium', top: 'none' }}
                          level={4}
                          textAlign="center"
                          alignSelf="center"
                        >
                          {domToReact(children)}
                        </Heading>
                      )
                    }
                  },
                })}

                <Box flex="grow" alignSelf="center" margin={{ left: '-15%' }}>
                  <Box direction="row" align="center" gap="50px">
                    <Box basis="1/2">
                      <Paragraph textAlign="end">Employee Code:</Paragraph>
                    </Box>
                    <Box align="start" basis="1/2">
                      <Heading
                        margin={{ vertical: 'small' }}
                        level={3}
                        color="brand"
                      >
                        {id}
                      </Heading>
                    </Box>
                  </Box>
                  <Box direction="row" align="center" gap="50px">
                    <Box basis="1/2">
                      <Paragraph textAlign="end">Employee Url:</Paragraph>
                    </Box>
                    <Box align="start" basis="1/2">
                      <a href={`http://www.blike.com/employee/${id}`}>
                        <Heading
                          margin={{ vertical: 'small' }}
                          level={3}
                          color="brand"
                        >
                          {`www.blike.com/employee/${id}`}
                        </Heading>
                      </a>
                    </Box>
                  </Box>
                </Box>

                <Box margin={{ vertical: 'large' }}>
                  {parser(editableRegions['Paragraph'], {
                    replace: ({ name, children }) => {
                      if (name === 'p') {
                        return (
                          <Paragraph textAlign="center" alignSelf="center">
                            {domToReact(children)}{' '}
                          </Paragraph>
                        )
                      }
                    },
                  })}
                </Box>
              </Box>
            ) : (
              <>
                <Box
                  style={{ position: 'relative' }}
                  justify="center"
                  align="center"
                >
                  <TitleSwoosh
                    style={{
                      position: 'absolute',
                      top: '15%',
                      bottom: 0,
                      margin: 'auto 0',
                      zIndex: -1,
                    }}
                    height="60%"
                  />
                  {name && (
                    <Heading
                      textAlign="center"
                      style={{ maxWidth: '680px' }}
                      alignSelf="center"
                      level={2}
                      size="xlarge"
                      margin={{ vertical: 'xlarge' }}
                    >
                      {`Blike & ${name}`}
                    </Heading>
                  )}
                </Box>

                <Heading margin={{ top: 'none', bottom: 'small' }} level={3}>
                  Confirm Your Account
                </Heading>
                <Rule margin={{ top: 'none', bottom: 'small' }} />
                <Paragraph>
                  {`We have just sent you a verification code ${userId}`}
                </Paragraph>
                <Form
                  value={value}
                  onChange={nextValue => setValue(nextValue)}
                  onReset={() => setValue({})}
                  onSubmit={onSubmit}
                >
                  <Box direction="column" gap="small" width={{ max: '50%' }}>
                    <FormTextInput
                      width="50%"
                      validate={null}
                      name="confirmation"
                      placeholder="Verification Code"
                    />

                    <Button
                      type="submit"
                      style={{
                        fontSize: 21,
                        width: 250,
                        marginBottom: 40,
                      }}
                      icon={
                        submitting ? (
                          <Spinner
                            style={{ margin: '0 auto' }}
                            alignSelf="center"
                            size="xsmall"
                          />
                        ) : null
                      }
                      primary
                      size="large"
                      label={submitting ? null : 'Confirm'}

                    />
                    <a href="#" onClick={resendCode}>
                      Resend Code
                    </a>
                  </Box>
                </Form>
              </>
            )}
          </Box>
        ) : (
          <Box
            align="center"
            flex
            fill
            justify="center"
            height={{ min: '80vh' }}
          >
            <Text>Fetching company details...</Text>
            <Box pad="large">
              <ClipLoader
                color={'#03E272'}
                loading={true}
                size={40}
                speedMultiplier={0.5}
              />
            </Box>
          </Box>
        )}
      </Box>
    </>
  )
}

export default Summary
