import { Link } from 'gatsby'
import { Box, Button } from 'grommet'
import React, { useState } from 'react'
import Swoosh from '../../assets/vectors/squiggle.svg'
import { AuthForm, Email, Password } from '../Forms/AuthForms'
import { useAuthentication } from '../../hooks/Auth'

const SignIn = ({ location }) => {
  
  const { login, state, setState } = useAuthentication(location)

  const handleUpdate = event => {
    const { name, value } = event.target
    
    if (name === 'email') {
      setState(current => ({
        ...current,
        [name]: value,
        username: value,
        error: '',
      }))
    }

    setState(current => ({
      ...current,
      [name]: value,
      error: '',
    }))
  }

  return (
    <Box align="center" justify="center" flex="grow">
      <Swoosh
        width="50%"
        style={{ position: 'absolute', zIndex: -1, pointerEvents: 'none' }}
      />
      <AuthForm title="Sign in to your account" error={state.error}>
        <Email
          handleUpdate={handleUpdate}
          email={state.email}
          autoComplete="on"
        />
        <Password
          handleUpdate={handleUpdate}
          password={state.password}
          autoComplete="on"
        />
        <p className="text-center">
          Forgot your password? <Link to="/reset">Reset password</Link>
        </p>
        <Button
          onClick={e => login(e)}
          type="submit"
          primary
          size="large"
          color="brand"
          style={{ textAlign: 'center' }}
          className="btn btn-primary btn-block"
          disabled={state.loading}
          label="Sign In"
        ></Button>
      </AuthForm>
    </Box>
  )
}

export default SignIn
