import { Box, Paragraph } from 'grommet';
import React from 'react';

export const Feature = ({ title, option }) => {
  return (
    <Box direction="row" justify="between" margin={{ top: 'xsmall' }}>
      <Paragraph size="small" margin="none" className="bold">
        {title}
      </Paragraph>
      <Paragraph size="small" margin="none" textAlign="end">
        {option}
      </Paragraph>
    </Box>
  );
};
