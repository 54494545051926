import API from '@aws-amplify/api'
import Auth from '@aws-amplify/auth'
import { navigate } from 'gatsby'
import {
  Anchor,
  Box,
  Button,
  Form,
  Grid,
  Heading,
  Paragraph,
  Spinner,
  Text,
  TextArea,
  Select,
  CheckBox,
  Layer,
} from 'grommet'
import { FormLock, View, Close, Pause } from 'grommet-icons'
import LogRocket from 'logrocket'
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Sticky from 'react-sticky-el'
import {
  usersAPI,
  emailCheckAPI,
  creditcheckAPI,
  subscriptionAPI,
} from '../../config'
import { fbEvent, gaEvent } from '../../helpers'
import { useResponsive } from '../../hooks'
import {
  placeSubscriptionOrder,
  setCreditCheck,
  clearOrders,
} from '../../state/order'
import Rule from '../Components/Rule'
import Sidebar from '../Components/Sidebar'
import MobileTopBar from '../Components/Sidebar/MobileTopBar'
import { FormTextInput } from '../Forms'
import ClipLoader from 'react-spinners/ClipLoader'
import AddressLookup from '../Components/AddressLookup'
import CreditCheck from '../Components/CreditCheck'
import GoCardless from '../Components/PaymentFlow'
import { switchPurchase, showError } from '../../state/ui'
import Number from '../Components/Number'
import { compareDocumentPosition } from 'domutils'

const defaultValue = {
  firstname: '',
  lastname: '',
  emailPersonal: '',
  firstname: '',
  lastname: '',
  house_number: '',
  town_or_city: '',
  postcode: '',
  mobileNumber: '',
}

export const useAuth = () => {
  let { name: usersApiName } = usersAPI
  const [loading, setLoading] = useState(true)

  const [isLoggedIn, setIsLoggedIn] = useState()
  const [user, setUser] = useState(null)

  useEffect(() => {
    getAuthedUser()
  }, [])

  const getAuthedUser = async () => {
    try {
      setLoading(true)
      await Auth.currentAuthenticatedUser()
      let response = await API.get(usersApiName, '/user')
      setUser(response.user)
      setIsLoggedIn(true)
      setLoading(false)
    } catch {
      setLoading(false)
      setIsLoggedIn(false)
    }
  }

  return { isLoggedIn, user, loading }
}

const FormSection = ({
  children,
  heading = null,
  number = null,
  rightItem = null,
  disabled = false,
  flexGrow = true,
}) => {
  const { isDesktop, isMobile } = useResponsive()

  return (
    <Box
      direction="row"
      gap="40px"
      style={{
        pointerEvents: disabled ? 'none' : null,
        opacity: disabled ? 0.3 : 1,
      }}
    >
      {isDesktop ? (
        number ? (
          <Number number={number} />
        ) : (
          <Box
            width={{ min: '50px', max: '50px' }}
            height="50px"
            align="center"
            shrink={false}
            justify="center"
          ></Box>
        )
      ) : null}
      <Box pad={{ bottom: 'medium' }} flex={flexGrow ? 'grow' : 'shrink'}>
        {heading && (
          <>
            <Box direction="row" justify="between" align="center">
              <Heading margin={{ top: 'none', bottom: 'small' }} level={3}>
                {heading}
              </Heading>
              <Box direction="row">{rightItem}</Box>
            </Box>

            <Rule margin={{ top: 'none', bottom: 'small' }} />
          </>
        )}
        {children}
      </Box>
    </Box>
  )
}

const PersonalDetails = ({ user, emailCheck }) => {
  const { isDesktop, isMobile } = useResponsive()

  return (
    <>
      <Box
        direction={isMobile ? 'column' : 'row'}
        gap={isDesktop ? 'medium' : 'none'}
      >
        <FormTextInput
          name="firstname"
          placeholder="First Name"
          disabled={user}
        />
        <FormTextInput
          name="lastname"
          placeholder="Last Name"
          disabled={user}
        />
      </Box>
      <Box
        direction={isMobile ? 'column' : 'row'}
        gap={isDesktop ? 'medium' : 'none'}
      >
        <FormTextInput
          onBlur={e => !user && emailCheck(e.target.value)}
          name="emailPersonal"
          placeholder="Email"
          disabled={user}
          validate={[
            {
              regexp: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
              message: 'Must be a valid email',
              status: 'error',
            },
          ]}
        />
        <FormTextInput
          name="mobileNumber"
          placeholder="Mobile Number"
          validate={null}
          required={false}
          disabled={user}
        />
      </Box>
    </>
  )
}

const CycleToWorkModal = ({ setShowModal }) => {
  const dispatch = useDispatch()
  const { isDesktop, isMobile, size } = useResponsive()

  const applyCycleWork = () => {
    dispatch(switchPurchase({ purchaseType: 'cycletowork' }))
    navigate('/planpicker')
  }

  return (
    <Layer
      modal
      responsive={false}
      margin="medium"
      onEsc={() => setShowModal(false)}
      onClickOutside={() => setShowModal(false)}
      full={isMobile ? 'horizontal' : false}
    >
      <Box
        flex
        margin="medium"
        style={{ overflowY: 'scroll' }}
        pad={{ top: isMobile ? 'large' : 'small' }}
      >
        <Button
          plain
          icon={<Close />}
          onClick={() => setShowModal(false)}
          style={{ position: 'absolute', right: 20, top: 10 }}
        />
        <Box className="editable" pad={'medium'} isMobile={isMobile} flex>
          <Box
            pad={{ vertical: 'small' }}
            align={'center'}
            flex="grow"
            alignSelf={'center'}
          >
            <Heading
              responsive={false}
              level={3}
              size={isMobile ? 'small' : 'medium'}
              textAlign="center"
              alignSelf={'center'}
              fill={false}
              margin={{
                bottom: 'medium',
                top: 'none',
                horizontal: 'auto',
              }}
            >
              Interested in Cycle to Work?
            </Heading>
            <Box
              flex
              alignSelf={'center'}
              width={'25%'}
              border={{ color: 'brand', bottom: { size: '1px' } }}
            />
          </Box>
          <Box flex style={{ overflow: 'hidden' }}>
            <Paragraph size={size} margin={{ bottom: 'medium' }}>
              Getting your e-bike via Cycle to Work is a great option, as you’ll
              save between 33-48% on the monthly cost. If you think you will be
              able to get your employer on board with Blike, then complete the
              Cycle to Work application. It may take several weeks for us to get
              your employer signed up. Otherwise, continue with subscription and
              order your bike now.
            </Paragraph>
          </Box>
          <Box
            direction={isMobile ? 'column' : 'row'}
            justify="center"
            gap="medium"
          >
            <Button
              secondary
              active
              size={isMobile ? 'medium' : 'large'}
              label="Apply To Cycle To Work"
              onClick={applyCycleWork}
              style={{ minWidth: size === 'small' ? null : 400 }}
            />
            <Button
              primary
              size={isMobile ? 'medium' : 'large'}
              label="Continue with Subscription"
              onClick={() => setShowModal(false)}
              style={{ minWidth: size === 'small' ? null : 400 }}
            />
          </Box>
        </Box>
      </Box>
    </Layer>
  )
}

const RegisterForm = ({ setStage }) => {
  const { email, employer, employerName } = useSelector(state => state.employee)
  const { id: orderId, email: orderEmail } = useSelector(state => state.order)

  const { isLoggedIn, user, loading } = useAuth()
  const [value, setValue] = useState({
    ...defaultValue,
  })

  let { name, path } = emailCheckAPI
  let { name: creditCheckName, path: creditCheckPath } = creditcheckAPI

  const emailCheck = email => {
    API.get(name, path, {
      queryStringParameters: {
        email,
      },
    })
      .then(response => {
        console.log(response)
        if (response.exists) {
          dispatch(
            showError({
              title: 'Email In Use',
              message:
                'An account with this email already exists. Please login to continue.',
              type: 'login',
            })
          )
        }
        // // setUser(response.user)
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    gaEvent('select-plan')
    fbEvent('InitiateCheckout')
    dispatch(clearOrders())
  }, [])

  useEffect(() => {
    if (email && !user && !loading) {
      emailCheck(email)
    }
    setValue({
      firstname: user ? user?.Name.split(/\s+/)[0].trim() : null,
      lastname: user ? user?.Name.split(/\s+/)[1].trim() : null,
      emailPersonal: email || user?.Email,
      companyCode: employer,
      companyName: employerName,
      homePostcode: user?.Postcode,
      mobileNumber: user?.Phone,
    })
  }, [user, loading, email])

  const dispatch = useDispatch()
  const { isDesktop, isMobile } = useResponsive()
  const [formStage, setFormStage] = useState()
  const [submitting, setSubmitting] = useState(false)

  const [runCheck, setRunCheck] = useState(false)
  const [checkingCredit, setCheckingCredit] = useState(false)
  const [creditPass, setCreditPass] = useState(false)
  const [isValidated, setIsValidated] = useState(false)

  const [selectValue, setSelectValue] = useState()
  const [showModal, setShowModal] = useState()

  const creditCheck = async () => {
    setCheckingCredit(true)
    dispatch(placeSubscriptionOrder({ user: '12313123123', value })) //user.userSub, value }))
  }

  useEffect(() => {
    if (selectValue === 'Employee') {
      setShowModal(true)
    }
  }, [selectValue])

  useEffect(() => {
    if (
      (value.employment === 'Unemployed' ||
      value.employment === 'Delivery Rider')
    ) {
      setTimeout(() => {
        setRunCheck(true)
        setCreditPass(false)
        setCheckingCredit(false)
      }, 3000)
    } else {
      orderId &&
        orderEmail &&
        checkingCredit &&
        API.post(creditCheckName, creditCheckPath, {
          body: {
            employment: value.employment,
            firstName: value.firstname,
            lastName: value.lastname,
            houseNo: value.house_number,
            street: value.line_3,
            city: value.town_or_city,
            postCode: value.postcode,
            orderID: orderId,
          },
        })
          .then(({ checkPass }) => {
            setRunCheck(true)
            setCreditPass(checkPass)
            setCheckingCredit(false)
            dispatch(setCreditCheck(checkPass)) //user.userSub, value }))
          })
          .catch(error => {
            dispatch(
              showError({
                title: 'Error',
                message:
                  'There was an issue with your eligibility check. This does not mean that you have failed but please contact us to proceed with your order.',
              })
            )
            dispatch(clearOrders())
            setCheckingCredit(false)
          })
    }
  }, [orderId, orderEmail, checkingCredit])

  const onSubmit = async () => {
    let { emailPersonal, password, firstname, lastname } = value
    console.log('THIS IS SUBMITED', value)
    dispatch(placeSubscriptionOrder({ user: '12313123123', value })) //user.userSub, value }))
  }

  useEffect(() => {
    let {
      emailPersonal,
      firstname,
      lastname,
      house_number,
      town_or_city,
      postcode,
      mobileNumber,
    } = value

    setIsValidated(
      emailPersonal &&
        firstname &&
        lastname &&
        house_number &&
        town_or_city &&
        postcode &&
        mobileNumber
    )
  }, [value])

  if (loading)
    return (
      <Box align="center" flex fill justify="center" height={{ min: '80vh' }}>
        <Text>Fetching your details...</Text>
        <ClipLoader
          color={'#03E272'}
          loading={true}
          size={40}
          speedMultiplier={0.5}
        />
      </Box>
    )

  return (
    <Box flex="grow" pad={{ left: isMobile ? 'none' : 'large' }}>
      <Form
        value={value}
        onChange={nextValue => {
          setValue(nextValue)
        }}
        onReset={() => setValue({})}
        onSubmit={onSubmit}
      >
        <FormSection number="1" heading="Employment Status">
          <Box width="50%" alignSelf="stretch" fill="horizontal">
            <Select
              name="employment"
              placeholder="Select your employment status..."
              options={[
                'Unemployed',
                'Retired',
                'Student',
                'Employee',
                'Self Employed',
                'Delivery Rider',
                'Part-time or casual',
                'Company Director',
              ]}
              value={selectValue}
              onChange={({ option }) => setSelectValue(option)}
            />
          </Box>
        </FormSection>

        {showModal && <CycleToWorkModal setShowModal={setShowModal} />}

        <FormSection
          disabled={!selectValue}
          number="2"
          heading="Personal details"
          rightItem={
            !isLoggedIn ? (
              <a
                href="#"
                onClick={() => navigate('/signin?redirect=checkout')}
                style={{ fontSize: isMobile ? 12 : null }}
              >
                Already have an account?
              </a>
            ) : null
          }
        >
          <PersonalDetails
            stage={formStage}
            setStage={setFormStage}
            user={user}
            emailCheck={emailCheck}
            value={value}
            setValue={setValue}
          />
        </FormSection>
        <FormSection number="3" heading="Your Address" disabled={!selectValue}>
          <AddressLookup
            stage={formStage}
            setStage={setFormStage}
            user={user}
            value={value}
            setValue={setValue}
          />
        </FormSection>
        <>
          <FormSection disabled={!selectValue} flexGrow={false}>
            <Box>
              <CreditCheck success={creditPass} complete={runCheck}>
                {!runCheck && (
                  <Button
                    onClick={creditCheck}
                    primary
                    size="large"
                    style={{ width: !isMobile ? 350 : '100%' }}
                    alignSelf="end"
                    disabled={!isValidated || checkingCredit}
                    icon={
                      checkingCredit ? (
                        <Spinner
                          style={{ margin: '0 auto' }}
                          alignSelf="center"
                          size="xsmall"
                        />
                      ) : null
                    }
                    label={checkingCredit ? null : 'Credit Check'}
                  />
                )}
              </CreditCheck>
            </Box>
          </FormSection>

          {creditPass && runCheck && <PaymentForm />}
        </>
      </Form>
    </Box>
  )
}

const PaymentForm = () => {
  const { isDesktop, isMobile } = useResponsive()
  const { id, email } = useSelector(state => state.order)
  const {
    accessoriesMonthlyTotal,
    selectedBike: { subPrice, model },
  } = useSelector(state => state.selection)
  const { path, name } = subscriptionAPI
  const [agree, setAgree] = useState(false)
  const [deliveryAgree, setDeliveryAgree] = useState(false)

  const bikes = useSelector(state => state.bikes.models.byId)
  const recycle = bikes[model].recycle

  const price = (
    parseFloat(subPrice) + parseFloat(accessoriesMonthlyTotal)
  ).toFixed(2)

  const confirmSubscription = async isSuccess => {
    // let cognitoSub = await createAccount()
    API.post(name, `${path}/confirmation`, {
      body: {
        subscriptionId: id,
        // cognitoSub,
        paymentStatus: isSuccess ? 'success' : 'fail',
      },
    })
      .then(_ => {
        navigate(`/orderconfirmation/${email}`)
      })
      .catch(error => {
        alert('There was an issue with your direct debit payment')
        console.log(error)
      })
  }

  const onSucess = async response => {
    navigate(`/orderconfirmation/${email}`)
  }

  const onError = response => {
    alert('there was an error with your payment')
    // confirmSubscription(false)
  }

  return (
    <FormSection number="4" heading="Joining Fee & Direct Debit">
      <Paragraph>
        {` To complete your order please pay the ${
          recycle ? '£48' : '£99'
        } joining fee and set up your Direct Debit. We will deduct your first instalment of £${price} when your bike is delivered.`}
      </Paragraph>

      <Box pad={{ bottom: 'medium' }}>
        <CheckBox
          checked={deliveryAgree}
          label={
            <Text>
              The bike will not be used for delivery or courier use
            </Text>
          }
          onChange={event => setDeliveryAgree(event.target.checked)}
        />
      </Box>

      <Box pad={{ bottom: 'medium' }}>
        <CheckBox
          checked={agree}
          label={
            <Text>
              I agree to the{' '}
              <a href="/terms-and-conditions" target="_blank">
                terms and conditions
              </a>
            </Text>
          }
          onChange={event => setAgree(event.target.checked)}
        />
      </Box>

      <GoCardless
        onSuccess={onSucess}
        onError={onError}
        agree={agree}
        deliveryAgree={deliveryAgree}
        value={parseFloat(accessoriesMonthlyTotal) + parseFloat(subPrice)}
      />
    </FormSection>
  )
}

const AboutForm = ({}) => {
  const { isDesktop, isMobile } = useResponsive()
  const { check, id } = useSelector(state => state.order)
  const [payment, setStage] = useState(false)

  return (
    <Box
      direction="column"
      justify="start"
      flex={false}
      fill
      margin={{ bottom: 'large' }}
      pad={{ horizontal: 'large' }}
    >
      <Grid columns={isMobile ? ['auto'] : ['350px', 'flex']}>
        {isDesktop && (
          <Box id="boundary">
            <Sticky
              mode="top"
              positionRecheckInterval={1}
              boundaryElement="#boundary"
            >
              <Box fill>
                <Sidebar step="plan" />
              </Box>
            </Sticky>
          </Box>
        )}
        {isMobile && (
          <Box flex>
            <MobileTopBar />
          </Box>
        )}
        <Box flex>
          <RegisterForm setStage={setStage} />
        </Box>
      </Grid>
    </Box>
  )
}

export default AboutForm
