import API from '@aws-amplify/api'
import Auth from '@aws-amplify/auth'
import { navigate } from 'gatsby'
import {
  Anchor,
  Box,
  Button,
  Form,
  Grid,
  Heading,
  Paragraph,
  Spinner,
  Text,
  TextArea,
  Select,
  CheckBox,
  Layer,
} from 'grommet'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Sidebar from '../../components/Components/Sidebar'
import { creditcheckAPI, emailCheckAPI, usersAPI } from '../../config'
import { clearOrders, matchAccountToOrder } from '../../state/order'
import MobileTopBar from '../Components/Sidebar/MobileTopBar'

import { FormLock, View } from 'grommet-icons'
import { useResponsive } from '../../hooks'
import { FormTextInput } from '../Forms'
import Rule from '../Components/Rule'
import Number from '../Components/Number'
import { showError } from '../../state/ui'
import { AppUser } from '../Auth'
import { MiddlewareArray } from '@reduxjs/toolkit'
import { gaEvent, fbEvent } from '../../helpers'
import { useLocation } from '@reach/router'

const CreatePassword = ({ email, setSuccess, orderNo }) => {
  const { isDesktop, isMobile } = useResponsive()
  const [reveal, setReveal] = useState(false)
  const [confirmReveal, setConfirmReveal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState({ password: '', passwordConfirm: '' })
  const dispatch = useDispatch()

  const onSubmit = async () => {
    if (value.password !== value.passwordConfirm) {
      alert("Passwords don't match")
      return
    }
    setLoading(true)
    try {
      let user = await Auth.signUp({
        username: email,
        password: value.password,
      })

      dispatch(
        matchAccountToOrder({
          cognitoSub: user.userSub,
          subscriptionId: orderNo,
        })
      )

      gaEvent('sign_up')
      fbEvent('CompleteRegistration')

      setLoading(false)
      setSuccess(true)
    } catch (err) {
      setLoading(false)
      dispatch(
        showError({
          title: 'Error Registering',
          message: err.message,
          type: null,
        })
      )
    }
  }

  return (
    <Form
      value={value}
      onChange={nextValue => {
        setValue(nextValue)
      }}
      onReset={() => setValue({})}
      onSubmit={onSubmit}
    >
      <Box
        direction={isMobile ? 'column' : 'row'}
        gap={isDesktop ? 'medium' : 'none'}
      >
        <Box
          fill
          direction="row"
          align="start"
          justify="start"
          style={{ position: 'relative' }}
        >
          <FormTextInput
            autoComplete="off"
            name="password"
            placeholder="Password"
            type={reveal ? 'text' : 'password'}
            info={'Must contain uppercase, lowercase, digits and one symbol'}
          />
          <Button
            style={{ position: 'absolute', right: 0, top: 13 }}
            icon={reveal ? <FormLock size="21px" /> : <View size="17px" />}
            onClick={() => setReveal(!reveal)}
          />
        </Box>
        <Box
          fill
          direction="row"
          align="start"
          justify="start"
          style={{ position: 'relative' }}
        >
          <FormTextInput
            autoComplete="off"
            name="passwordConfirm"
            placeholder="Confirm Password"
            type={confirmReveal ? 'text' : 'password'}
            validate={(val, form) => {
              if (val !== form.password) {
                return {
                  message: "Your passwords don't match",
                  status: 'error',
                }
              }
              return undefined
            }}
          />
          <Button
            style={{ position: 'absolute', right: 0, top: 13 }}
            icon={
              confirmReveal ? <FormLock size="21px" /> : <View size="17px" />
            }
            onClick={() => setConfirmReveal(!confirmReveal)}
          />
        </Box>
      </Box>
      <Button
        onClick={onSubmit}
        primary
        size="large"
        style={{ width: !isMobile ? 350 : '100%', marginTop: 20 }}
        alignSelf="end"
        disabled={loading}
        label={
          loading ? (
            <Spinner
              style={{ margin: '0 auto' }}
              alignSelf="center"
              size="xsmall"
            />
          ) : (
            'Create Account'
          )
        }
      />
    </Form>
  )
}

function useQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const Summary = ({ email }) => {
  let query = useQuery()

  //CHECk if user has been redirected from checkout
  let queryNo = query.get('orderno')
  let queryId = query.get('id')
  let { orderNumber, id } = useSelector(state => state.order)
  const [orderNo, setOrderNo] = useState()
  const [success, setSuccess] = useState(false)
  const { isDesktop, isMobile } = useResponsive()

  if (queryNo && queryId) {
    orderNumber = queryNo
    id = queryId
  }

  const dispatch = useDispatch()
  const isLoggedIn = AppUser.isLoggedIn()

  useEffect(() => {
    gaEvent('submit-form')
    fbEvent('Purchase')
    setOrderNo({ orderNumber, id })
    isUserLoggedIn(id)
    dispatch(clearOrders())
  }, [])

  const isUserLoggedIn = async orderNo => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      if (user && orderNo) {
        dispatch(
          matchAccountToOrder({
            cognitoSub: user.username,
            subscriptionId: orderNo,
          })
        )
      }
    } catch (err) {
      console.log('err', err)
    }
  }

  return (
    <>
      <Box
        // ref={ref}
        direction="column"
        justify="start"
        flex={false}
        fill
        pad={{ horizontal: 'large' }}
        margin={{ bottom: 'large' }}
      >
        <Grid
          fill="horizontal"
          columns={isMobile ? ['auto'] : ['350px', 'flex']}
          gap={isMobile ? 'none' : 'xlarge'}
        >
          {isDesktop && !queryNo ? (
            <Sidebar disabled />
          ) : (
            <Box flex="shrink">{!queryNo && <MobileTopBar />}</Box>
          )}

          <Box>
            <Heading>{'Confirmation'}</Heading>
            <Heading
              level="3"
              size="small"
            >{`Your order number is: ${orderNo?.orderNumber}`}</Heading>

            <Paragraph margin="none">
              Your bike & accessories have now been ordered. We will be in
              contact shortly about the delivery arrangements. You'll also
              receive our standard agreement via email which you need to e-sign.
            </Paragraph>

            {!isLoggedIn && (
              <Box margin={{ top: 'large' }}>
                <Heading margin={{ top: 'none', bottom: 'small' }} level={3}>
                  {'Create Your Account'}
                </Heading>
                <Rule margin={{ top: 'none', bottom: 'small' }} />

                {success ? (
                  <>
                    <Paragraph margin={{ top: 'small', bottom: 'medium' }}>
                      Your account has been successfully created. You will
                      receive a verifcation code via email. Please use{' '}
                      <a href="/signin">login into your account</a> and use this
                      code to verify your account.
                    </Paragraph>
                  </>
                ) : (
                  <>
                    <Paragraph margin={{ top: 'small', bottom: 'medium' }}>
                      You can now create your account by setting a password. You
                      will then get a confirmation code sent to your email
                      address.
                    </Paragraph>
                    <CreatePassword
                      email={email}
                      setSuccess={setSuccess}
                      orderNo={orderNo?.id}
                    />
                  </>
                )}
              </Box>
            )}
          </Box>
        </Grid>
      </Box>
    </>
  )
}

export default Summary
