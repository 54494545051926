import React, { useEffect, useState, useRef } from 'react'
import {
  Box,
  Heading,
  Button,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TableBody,
} from 'grommet'
import { useResponsive, useWindowSize } from '../../../hooks'
import { numberWithCommas } from '../../../helpers'
import { InfoPoint } from '../../Components/InfoPoint'
import styled from 'styled-components'

export const StyledTable = styled(Table)`
  th {
    font-size: 14px !important;
    background: #fff;
    font-weight: 700 !important;
    border-bottom: none !important;
  }
  tr {
    &:nth-child(odd) {
      border: none;
      background: #f7f7f7;
    }
    &:nth-child(even) {
      border: none;
      background: #fff;
    }
    /* &:nth-child(4),
    &:nth-child(5) {
      border: none;
      background: #fff;

      td {
        font-weight: bold;
      }
    } */

    td {
      border: none;
    }
  }
`

export const StyledTableCell = styled(TableCell)`
  /* background: red !important; */
  /* padding: ${props => (props.isMobile ? 'palevioletred' : null)}; */
  font-size: ${props => (props.isMobile ? '10px' : '14px')};
  /* background: ${props => (props.isMobile ? 'palevioletred' : null)}; */

  p{
    font-size: ${props => (props.isMobile ? '10px' : '14px')};
    line-height: ${props => (props.isMobile ? '10px' : '14px')};
  }
`

export const DesktopTable = ({ quote, selectPlan, infoPoints }) => {
  const { size, isMobile } = useResponsive()
  const [quoteData, setQuoteData] = useState(null)
  const [tableHeight, setTableHeight] = useState(0)
  const [headingHeight, setHeadingHeight] = useState(0)

  const tableRef = useRef(null)
  const headingRef = useRef(null)

  const { width } = useWindowSize()
  useEffect(() => {
    setTableHeight(tableRef.current.clientHeight)
    setHeadingHeight(headingRef.current.clientHeight)
  }, [width])

  useEffect(() => {
    if (quote['12'] && quote['24'] && quote['36']) {
      const data = {
        id: quote.id,
        net: [
          `£${numberWithCommas(parseInt(quote['12'].net).toFixed(0))}`,
          `£${numberWithCommas(parseInt(quote['24'].net).toFixed(0))}`,
          `£${numberWithCommas(parseInt(quote['36'].net).toFixed(0))}`,
        ],
        columns: ['12', '24', '36'],
        rows: [
          [
            <Box direction="row" align="center">
              <Paragraph size={size}>
                {'Gross monthly salary deduction'}
              </Paragraph>
              <InfoPoint info={infoPoints['Gross Monthly']} />
            </Box>,
            `£${parseInt(quote['12'].gross)}`,
            `£${parseInt(quote['24'].gross)}`,
            `£${parseInt(quote['36'].gross)}`,
          ],
          [
            <Box direction="row" align="center">
              <Paragraph size={size}>{'Your total package value'}</Paragraph>
              <InfoPoint info={infoPoints['Total Package Value']} />
            </Box>,
            `£${numberWithCommas(parseInt(quote['12'].packageValue))}`,
            `£${numberWithCommas(parseInt(quote['24'].packageValue))}`,
            `£${numberWithCommas(parseInt(quote['36'].packageValue))}`,
          ],
          [
            <Box direction="row" align="center">
              <Paragraph size={size}>{'Optional ownership payment'}</Paragraph>
              <InfoPoint info={infoPoints['Optional Ownership Payment']} />
            </Box>,
            `£${numberWithCommas(parseInt(quote['12'].finalPayment))}`,
            `£${numberWithCommas(parseInt(quote['24'].finalPayment))}`,
            `£${numberWithCommas(parseInt(quote['36'].finalPayment))}`,
          ],
          [
            <Box direction="row" align="center">
              <Paragraph size={size}>{'Your estimated saving £'}</Paragraph>
              <InfoPoint info={infoPoints['Your Estimated Saving £']} />
            </Box>,
            `£${numberWithCommas(parseInt(quote['12'].saving))}`,
            `£${numberWithCommas(parseInt(quote['24'].saving))}`,
            `£${numberWithCommas(parseInt(quote['36'].saving))}`,
          ],
          [
            <Box direction="row" align="center">
              <Paragraph size={size}>{'Your estimated saving %'}</Paragraph>
              <InfoPoint info={infoPoints['Your Estimated Saving %']} />
            </Box>,
            `${(quote['12'].savingPercent * 100).toFixed(2)}%`,
            `${(quote['24'].savingPercent * 100).toFixed(2)}%`,
            `${(quote['36'].savingPercent * 100).toFixed(2)}%`,
          ],
          ['Hassle Free Cycling', `Priceless`, `Priceless`, `Priceless`],
        ],
      }
      setQuoteData(data)
    }
  }, [quote])

  return (
    <StyledTable alignSelf="stretch">
      <TableBody ref={tableRef}>
        <TableRow style={{ backgroundColor: 'white' }}>
          <StyledTableCell isMobile={isMobile} align="center"></StyledTableCell>
          <StyledTableCell isMobile={isMobile} align="center"></StyledTableCell>
          <StyledTableCell
            ref={headingRef}
            isMobile={isMobile}
            align="center"
            style={{ position: 'relative' }}
          >
            <Heading margin={{ top: 'small', bottom: 'medium' }} level={3}>
              Most Popular
            </Heading>
            <Box
              fill
              round
              style={{
                position: 'absolute',
                height: (tableHeight - headingHeight + 50) || 0,
                top: '100%',
                pointerEvents: 'none',
                boxShadow: `0px 0px 41px 3px rgba(0,0,0,0.11)`,
              }}
            ></Box>
          </StyledTableCell>
          <StyledTableCell isMobile={isMobile} align="center"></StyledTableCell>
        </TableRow>
        <TableRow style={{ backgroundColor: 'white' }}>
          <StyledTableCell isMobile={isMobile} align="start">
            <Heading margin={{ vertical: 'small' }} level={5}>
              Plan length
            </Heading>
            <Box direction="row">
              <Heading
                margin={{ top: 'none', bottom: 'small' }}
                color="brand"
                level={3}
              >
                Net Monthly
              </Heading>
              <InfoPoint info={infoPoints['Net Monthly']} />
            </Box>
          </StyledTableCell>
          {quoteData &&
            quoteData.columns.map((row, index) => (
              <StyledTableCell isMobile={isMobile} align="center" key={index}>
                <Heading margin={{ vertical: 'small' }} level={5}>
                  {`${row} months`}
                </Heading>
                <Heading margin={{ top: 'none', bottom: 'small' }} level={3}>
                  {quoteData.net[index]}
                </Heading>
              </StyledTableCell>
            ))}
        </TableRow>
        {quoteData &&
          quoteData.rows.map((row, index) => (
            <TableRow key={index}>
              {row.map((item, index) => (
                <StyledTableCell
                  isMobile={isMobile}
                  key={index}
                  align={index === 0 ? 'left' : 'center'}
                >
                  {item}
                </StyledTableCell>
              ))}
            </TableRow>
          ))}
        <TableRow style={{ backgroundColor: 'white' }}>
          <StyledTableCell
            isMobile={isMobile}
            align={'center'}
          ></StyledTableCell>
          {quoteData &&
            quoteData.columns.map((timeLength, index) => (
              <StyledTableCell isMobile={isMobile} key={index}>
                <Button
                  onClick={() => selectPlan(timeLength)}
                  alignSelf="center"
                  primary
                  size="small"
                  label="Select"
                />
              </StyledTableCell>
            ))}
        </TableRow>
      </TableBody>
    </StyledTable>
  )
}
