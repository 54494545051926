import {
  Box,
  Grid,
  Heading,
  Paragraph,
  Anchor,
  Table,
  TableRow,
  TableHeader,
  TableCell,
  TableBody,
} from 'grommet'
import React from 'react'
import { useSelector } from 'react-redux'
import Rule from '../../Components/Rule'
import { useEditableRegions, useCheckoutPhase } from '../../../hooks'
import { AccordionArrow } from '../AccordionArrow'

export const SelectedPlanHeader = ({ mobile, active, price }) => {
  const selectedPlan = useSelector(state => state.selection.selectedPlan)
  const { step, stepName } = useCheckoutPhase()
  const { purchaseType } = useSelector(state => state.ui)

  return (
    <Box flex={false}>
      <Box
        direction="row"
        justify="between"
        align="center"
        margin={{ top: mobile ? 'none' : 'large' }}
      >
        <Box
          direction="row"
          align="center"
          justify="between"
          gap="10px"
          fill="horizontal"
        >
          <Box
            direction="row"
            align="center"
            justify="start"
            margin="none"
            pad="none"
            gap="10px"
          >
            {mobile && <AccordionArrow active={active} />}

            <Heading
              margin="none"
              level={4}
              color="brand"
              className="uppercase"
            >
              Plan
            </Heading>
          </Box>

          {price && purchaseType === 'subscription' && mobile ? (
            <Box
              direction="column"
              align="end"
              justify="between"
              margin="none"
              pad="none"
            >
              <Heading margin="none" level={4}>{`£${price}`}</Heading>
              <Paragraph
                size="xsmall"
                margin="none"
                style={{ top: -5, position: 'relative' }}
                color="#999999"
              >
                per month
              </Paragraph>
            </Box>
          ) : (
            <Box direction="row" align="center" gap="large">
              {(mobile && active) || !mobile
                ? step > 2 && (
                    <Anchor
                      size="xsmall"
                      margin="none"
                      href="/planpicker"
                      color="black"
                    >
                      <Heading margin="none" level={5} size="xsmall">
                        CHANGE
                      </Heading>
                    </Anchor>
                  )
                : selectedPlan?.plan?.net && (
                    <Heading margin="none" level={5} size="xsmall">
                      {`£${selectedPlan.plan.net}`}
                    </Heading>
                  )}
            </Box>
          )}
        </Box>
      </Box>
      <Rule margin={{ top: 'xsmall', bottom: 'small' }} />
    </Box>
  )
}

export const SelectedPlan = ({ stepName, step, terms, net, length }) => {
  const selectedPlan = useSelector(state => state.selection.selectedPlan)

  return (
    <Box flex={false}>
      {length ? (
        <>
          <Grid
            margin={{ vertical: 'none' }}
            gap="small"
            fill="horizontal"
            columns={['auto', 'auto']}
            rows={['flex']}
          >
            <Box justify="center">
              <Heading
                margin={'none'}
                level={5}
                color="black"
                className="uppercase"
              >
                {'Plan Length'}
              </Heading>
            </Box>
            <Box justify="center" align="end">
              <Heading
                margin={'none'}
                level={5}
                color="black"
                className="uppercase"
              >
                {`${length} months`}
              </Heading>
            </Box>
          </Grid>
          <Box align="end">
            <Heading margin="none" level={3}>{`£${net}`}</Heading>
            <Paragraph color="#999999" size="small" margin="none">
              {'per month (net)'}
            </Paragraph>
          </Box>
        </>
      ) : (
        <Paragraph margin="none" size="small" color="#999999">
          {!selectedPlan
            ? stepName === 'plan'
              ? 'Please enter your salary and select your plan'
              : 'Your plan is calculated in the next step'
            : 'Enter your salary to calculate your plan'}
        </Paragraph>
      )}
      {stepName === 'register' && (
        <Box className="editable smallprint" pad={{ vertical: 'large' }}>
          {terms}
        </Box>
      )}
    </Box>
  )
}

export const SelectedPlanSubscription = ({
  price,
  ownership,
  startDate,
  discount,
  recycle,
}) => {
  // const selectedPlan = useSelector(state => state.selection.selectedPlan)

  return (
    <Box flex={false}>
      {price && (
        <Box align="end">
          <Heading margin="none" level={3}>{`£${price}`}</Heading>
          <Paragraph color="#999999" size="small" margin="none">
            {'per month'}
          </Paragraph>
        </Box>
      )}

      <Box>
        <Table margin={{ top: 'small' }}>
          <TableBody>
            {discount?.code && (
              <TableRow>
                <TableCell scope="col" pad={{ bottom: 'xsmall' }}>
                  <Paragraph
                    size="small"
                    color="#000000"
                    style={{ fontWeight: 'bold' }}
                    margin="none"
                  >
                    {`${discount?.description}`}
                  </Paragraph>
                </TableCell>
                <TableCell scope="col" pad="none" align="right">
                  <Paragraph size="small" color="#000000" margin="none">
                    {`-£${discount?.amount}`}
                  </Paragraph>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell scope="col" pad={{ bottom: 'xsmall' }}>
                <Paragraph
                  size="small"
                  color="#000000"
                  style={{ fontWeight: 'bold' }}
                  margin="none"
                >
                  Joining Fee
                </Paragraph>
              </TableCell>
              <TableCell scope="col" pad="none" align="right">
                <Paragraph size="small" color="#000000" margin="none">
                  {`${recycle ? '£48' : '£99'} one-off`}
                </Paragraph>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="col" pad={{ bottom: 'xsmall' }}>
                <Paragraph
                  size="small"
                  color="#000000"
                  style={{ fontWeight: 'bold' }}
                  margin="none"
                >
                  Plan length
                </Paragraph>
              </TableCell>
              <TableCell scope="col" pad="none" align="right">
                <Paragraph size="small" color="#000000" margin="none">
                  Monthly rolling
                </Paragraph>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="col" pad={{ bottom: 'xsmall' }}>
                <Paragraph
                  size="small"
                  color="#000000"
                  style={{ fontWeight: 'bold' }}
                  margin="none"
                >
                  Type
                </Paragraph>
              </TableCell>
              <TableCell scope="col" pad="none" align="right">
                <Paragraph size="small" color="#000000" margin="none">
                  Consumer Hire
                </Paragraph>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell scope="col" pad={{ bottom: 'xsmall' }}>
                <Paragraph
                  size="small"
                  color="#000000"
                  style={{ fontWeight: 'bold' }}
                  margin="none"
                >
                  Cancellation
                </Paragraph>
              </TableCell>
              <TableCell scope="col" pad="none" align="right">
                <Paragraph size="small" color="#000000" margin="none">
                  30 days notice (no fees)
                </Paragraph>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="col" pad={{ bottom: 'xsmall' }}>
                <Paragraph
                  size="small"
                  color="#000000"
                  style={{ fontWeight: 'bold' }}
                  margin="none"
                >
                  Payments
                </Paragraph>
              </TableCell>
              <TableCell scope="col" pad="none" align="right">
                <Paragraph size="small" color="#000000" margin="none">
                  Monthly in advance
                </Paragraph>
              </TableCell>
            </TableRow>

            {ownership && (
              <TableRow>
                <TableCell scope="col" pad={{ bottom: 'xsmall' }}>
                  <Paragraph
                    size="small"
                    color="#000000"
                    style={{ fontWeight: 'bold' }}
                    margin="none"
                  >
                    Ownership
                  </Paragraph>
                </TableCell>
                <TableCell scope="col" pad="none" align="right">
                  <Paragraph size="small" color="#000000" margin="none">
                    {`£${ownership} after 36m`}
                  </Paragraph>
                </TableCell>
              </TableRow>
            )}
            {startDate && (
              <TableRow>
                <TableCell scope="col" pad={{ bottom: 'xsmall' }}>
                  <Paragraph
                    size="small"
                    color="#000000"
                    style={{ fontWeight: 'bold' }}
                    margin="none"
                  >
                    Est Delivery
                  </Paragraph>
                </TableCell>
                <TableCell scope="col" pad="none" align="right">
                  <Paragraph size="small" color="#000000" margin="none">
                    {startDate}
                  </Paragraph>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
    </Box>
  )
}
