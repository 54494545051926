import { Box, Heading, Anchor, Button, Paragraph } from 'grommet'
import React from 'react'
import { useSelector } from 'react-redux'
import Rule from '../../Components/Rule'
import { Feature } from './Feature'
import { BikeImage } from './BikeImage'
import { FormUp } from 'grommet-icons'
import { AccordionArrow } from '../AccordionArrow'

export const SelectedHeader = ({
  mobile,
  active,
  readOnly = false,
  header = 'Bike',
  step,
  subPrice = null,
  rrp,
  purchaseType,
}) => {
  return (
    <Box flex={false}>
      <Box direction="row" justify="between" align="center">
        <Box direction="row" align="center" justify="center" gap="10px">
          {mobile && <AccordionArrow active={active} />}
          <Heading margin="none" level={4} color="brand" className="uppercase">
            {header}
          </Heading>
        </Box>

        {(step < 3 || purchaseType === 'subscription') && (
          <TotalPrice
            subPrice={subPrice}
            rrp={rrp}
            purchaseType={purchaseType}
          />
        )}

        {/* <Box direction="row" align="center" gap="large"> */}
        {/* <Heading margin="none" level={4}>{`£${priceFigure.toFixed(0)}`}</Heading> */}

        {/* {(mobile && active) || !mobile ? (
            step > 1 &&
            !readOnly && (
              <Anchor size="xsmall" margin="none" href="/bikes" color="black">
                <Heading margin="none" level={5} size="xsmall">
                  CHANGE
                </Heading>
              </Anchor>
            )
          ) : (
            <Heading margin="none" level={5} size="xsmall">
              {bikesById[model]['Display Name']}
            </Heading>
          )} */}

        {/* </Box> */}
      </Box>

      <Rule margin={{ top: 'xsmall', bottom: 'small' }} />
    </Box>
  )
}

const TotalPrice = ({ subPrice, rrp, purchaseType }) => {
  const priceMessage = purchaseType === 'subscription' ? `per month` : ''
  const priceFigure =
    purchaseType === 'subscription'
      ? subPrice?.toFixed(2)
      : rrp?.replace('£', '')

  return (
    <Box>
      {/* <Rule margin={{ top: 'small', bottom: 'small' }} /> */}
      <Box
        direction="column"
        align="end"
        justify="between"
        margin="none"
        pad="none"
      >
        <Heading margin="none" level={4}>{`£${priceFigure}`}</Heading>
        <Paragraph
          size="xsmall"
          margin="none"
          style={{ top: -5, position: 'relative' }}
          color="#999999"
        >
          {priceMessage}
        </Paragraph>
      </Box>
    </Box>
  )
}

export const SelectedBike = ({
  brand,
  model,
  image,
  size,
  color,
  recycle,
  includedAccessories,
  displayName,
}) => {
  return (
    <Box flex={false}>
      {displayName ? (
        <>
          <BikeImage image={image} brand={brand} model={model} />
          {recycle && (
            <Paragraph
              size="large"
              margin={{ top: 'medium', bottom: 'none' }}
              color="brand"
            >
              ReCycle
            </Paragraph>
          )}
          <Heading
            truncate
            level={4}
            size="small"
            margin={{ top: 'none', bottom: 'none' }}
          >
            {displayName}
          </Heading>
        </>
      ) : null}
      <>
        <Feature title="Color" option={color} />
        <Feature title="Size" option={size} />
        {includedAccessories?.length && (
          <Feature title="Included" option={includedAccessories.join(', ')} />
        )}
      </>
    </Box>
  )
}
