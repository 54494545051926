import Amplify from '@aws-amplify/core'
import { Router } from '@reach/router'
import React, { useEffect } from 'react'

import config from '../aws-exports'
import About from '../components/Pages/About'
import Accessories from '../components/Pages/Accessories'
import Bike from '../components/Pages/Bike'
import Contract from '../components/Pages/Contract'
import EmployeeLandingPage from '../components/Pages/EmployeeLandingPage'
import Employer from '../components/Pages/Employer'
import EmployerConfirmation from '../components/Pages/EmployerConfirmation'
import EmployerSignUpConfirmation from '../components/Pages/EmployerSignupConfirmation'
import EmployerLandingPage from '../components/Pages/EmployerLandingPage'
//NEW PAGES
import Form from '../components/Pages/Form'
import FormSubscription from '../components/Pages/FormSubscription'

import Grid from '../components/Pages/Grid'
import GridUsed from '../components/Pages/GridUsed'

import Index from '../components/Pages/Home'
import InviteLandingPage from '../components/Pages/InviteLandingPage'
import Orders from '../components/Pages/Orders'
import Retrieval from '../components/Pages/Retrieval'

import Plan from '../components/Pages/Plan'
import Privacy from '../components/Pages/Privacy'
import Reset from '../components/Pages/Reset'
import Verify from '../components/Pages/Verify'
import SignIn from '../components/Pages/SignIn'
import SignUp from '../components/Pages/SignUp'
import BikeShow from '../components/Pages/BikeShow'
import BikeShow2 from '../components/Pages/BikeShow2'

import Summary from '../components/Pages/Summary'
import SummaryLoggedIn from '../components/Pages/SummaryLoggedIn'

import PrivateRoute from '../components/Routes/PrivateRoute'
import PublicRoute from '../components/Routes/PublicRoute'

import Finance from '../components/Pages/Finance'

import * as queryString from 'query-string'
import { useDispatch, useSelector } from 'react-redux'
import { switchPurchase } from '../state/ui'

if (typeof window !== 'undefined') {
  // window.LOG_LEVEL = 'DEBUG'
}

const App = ({ location }) => {
  const { cycletowork } = queryString.parse(location.search)
  const dispatch = useDispatch(switchPurchase)

  useEffect(() => {
    if (cycletowork === 'true') {
      dispatch(switchPurchase({ purchaseType: 'cycletowork' }))
    } else if (cycletowork === 'false') {
      dispatch(switchPurchase({ purchaseType: 'subscription' }))
    }
  }, [])

  return (
    <Router>
      <PrivateRoute path="/profile" title="Your account" component={Orders} />
      <PublicRoute path="/signin" title="Sign In" component={SignIn} />
      <PublicRoute path="/signup" title="Sign Up" component={SignUp} />
      <PublicRoute path="/reset" title="Reset Password" component={Reset} />
      <PublicRoute
        path="/verify/:id"
        title="Verify Account"
        component={Verify}
      />
      <PublicRoute path="/register" title="Register" component={Form} />
      <PublicRoute
        path="/checkout"
        title="Checkout"
        component={FormSubscription}
      />
      <PublicRoute path="/bike/:id/" title="Choose Bike" component={Bike} />
      <PublicRoute path="/recycle/:id" title="Choose Bike" component={Bike} />
      <PublicRoute path="/bikes" title="Our Bikes" component={Grid} />
      <PublicRoute path="/recycle" title="Our Bikes" component={GridUsed} />
      <PublicRoute
        path="/accessorypicker"
        title="Choose Accessories"
        component={Accessories}
      />
      <PublicRoute path="/planpicker" title="Choose Plan" component={Plan} />
      <PublicRoute
        path="/confirmation/:userId"
        title="Account Confirmation"
        component={Summary}
      />
      <PublicRoute
        path="/orderconfirmation/:email"
        title="Order Confirmation"
        component={SummaryLoggedIn}
      />
      <PublicRoute
        path="/employer-confirmation/:sub/:name/:id"
        title="Account Confirmation"
        component={EmployerConfirmation}
      />
      <PublicRoute
        path="/employer-confirmation/:sub"
        title="Account Confirmation"
        component={EmployerSignUpConfirmation}
      />
      <PublicRoute
        path="/employer-signup/*employerId"
        title="Employer Signup"
        component={Employer}
      />
      <PublicRoute
        path="/honbike"
        title="Honbike x Blike"
        component={InviteLandingPage}
      />
      <PublicRoute
        path="/employer/:employer"
        title="Landing Page"
        component={EmployerLandingPage}
        noMenu
      />
      <PublicRoute
        path="/employee/*employerID"
        title="Landing Page"
        component={EmployeeLandingPage}
        noMenu
      />
      <PublicRoute
        path="/order/*orderId"
        title="Order Retrieval"
        component={Retrieval}
      />
      <PublicRoute
        path="/cycleshow/*brand"
        title="Cycle Show Offer"
        component={BikeShow}
      />
      <PublicRoute
        path="/fullycharged/*brand"
        title="Fully Charged"
        component={BikeShow2}
      />
      <PublicRoute path="/finance" title="Finance" component={Finance} />
    </Router>
  )
}

export default App
