import React, { useEffect, useState } from 'react'
import {
  useGoCardlessDropin,
  GoCardlessDropinOptions,
  GoCardlessDropinOnSuccess,
} from '@gocardless/react-dropin'
import { Button, Paragraph } from 'grommet'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from '@reach/router'

import { goCardlessAPI } from '../../config'
import API from '@aws-amplify/api'

const DropinButton = ({ disabled, ...options }) => {
  const { open, ...rest } = useGoCardlessDropin({ ...options })
  console.log('options', options)
  return (
    <Button
      disabled={disabled}
      size="large"
      style={{ maxWidth: 350 }}
      primary
      type="button"
      onClick={() => open()}
      label="Direct Debit"
    />
  )
}

const Payment = ({ agree, deliveryAgree, value, onSuccess, onError }) => {
  const { path, name } = goCardlessAPI
  const [flowID, setFlowID] = useState(null)
  let { id: orderID, email } = useSelector(state => state.order)
  const location = useLocation()
  const selectedId = useSelector(state => state.selection.selectedBike.model)
  const bikes = useSelector(state => state.bikes.models.byId)
  const recycle = bikes[selectedId].recycle

  console.log('FLOW CREATED orderID', orderID)

  if (location.pathname.includes('/order/')) {
    orderID = location.pathname.replace('/order/', '')
  }

  console.log('orderID', orderID)

  const createFlow = async () => {
    try {
      let {
        billingRequestFlow: { id },
        billingRequestFlow,
      } = await API.post(name, path, {
        body: {
          orderID,
          paymentRequest: {
            description: 'Setup Payment',
            amount: (recycle ? 50 * 100 : 99 * 100).toFixed(0),
            currency: 'GBP',
            app_fee: (recycle ? 50 * 100 : 99 * 100).toFixed(0),
          },
        },
      })
      console.log('FLOW CREATED')
      setFlowID(id)
    } catch (err) {
      console.log('err', err)
    }
  }

  useEffect(() => {
    createFlow()
  }, [])

  // Only show the button once we have a Billing Request Flow ID
  return flowID === null ? (
    <div className="loader"></div>
  ) : (
    <>
      <DropinButton
        disabled={!agree || !deliveryAgree}
        billingRequestFlowID={flowID}
        environment={'live'}
        onSuccess={onSuccess}
        onError={onError}
      />
    </>
  )
}

export default Payment
