import React from 'react'
import { Box } from 'grommet'
import { useResponsive } from '../../hooks'

const Rule = ({ fill = 'horizontal', margin, ...rest }) => {
  const { isMobile } = useResponsive()
  const responsiveMargin = margin || {
    vertical: isMobile ? 'large' : 'small',
    horizontal: 'none',
  }
  return (
    <Box
      margin={responsiveMargin}
      fill={fill}
      border={{
        color: 'border',
        size: '1px',
        style: 'solid',
        side: 'top',
      }}
      {...rest}
    />
  )
}

export default Rule
