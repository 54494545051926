import { Box, Heading, Paragraph, Text, Anchor } from 'grommet'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Rule from '../../Components/Rule'
import { ProductOptions } from './ProductOptions'
import { AccordionArrow } from '../AccordionArrow'

export const AccessoriesHeader = ({
  mobile,
  active,
  total,
  step,
  readOnly = false,
  monthlyTotal,
  purchaseType,
}) => {
  return (
    <Box flex={false}>
      <Box
        direction="row"
        justify="between"
        align="center"
        margin={{ top: mobile ? 'none' : 'large' }}
      >
        <Box
          direction="row"
          align="center"
          justify="between"
          gap="10px"
          fill="horizontal"
        >
          <Box direction="row" gap="10px" align="center">
            {mobile && <AccordionArrow active={active} />}

            <Heading
              margin="none"
              level={4}
              color="brand"
              className="uppercase"
            >
              Accessories
            </Heading>
          </Box>

          {purchaseType === 'subscription' ? (
            <Box
              direction="column"
              align="end"
              justify="between"
              margin="none"
              pad="none"
            >
              <Heading
                margin={{ vertical: 'none' }}
                level={4}
                color="black"
                className="uppercase"
              >
                {`£${purchaseType === 'subscription' ? monthlyTotal : total}`}
              </Heading>
              <Paragraph
                size="xsmall"
                margin="none"
                style={{ top: -5, position: 'relative' }}
                color="#999999"
              >
                {'per month'}
              </Paragraph>
            </Box>
          ) : null}
        </Box>

        {/* <Box direction="row" align="center" gap="large">
          {(mobile && active) || !mobile
            ? step > 1 &&
              !readOnly && (
                <Anchor
                  size="xsmall"
                  margin="none"
                  href="/accessorypicker"
                  color="black"
                >
                  <Heading margin="none" level={5} size="xsmall">
                    CHANGE
                  </Heading>
                </Anchor>
              )
            : (total || step >= 0) && (
                <Heading margin="none" level={5} size="xsmall">
                  {`£${total}`}
                </Heading>
              )}

        </Box> */}
      </Box>
      <Rule margin={{ top: 'xsmall', bottom: 'small' }} />
    </Box>
  )
}

export const Accessories = ({
  disabled,
  stepName,
  bike,
  step,
  accessories,
  total,
  monthlyTotal,
}) => {
  const accessoriesById = useSelector(state => state.accessories.byId)
  const { purchaseType } = useSelector(state => state.ui)

  return (
    <Box flex={false}>
      {accessories?.length ? (
        <>
          <Box style={{ position: 'relative' }}>
            <Box
              className='hideScrollbar'
              flex={false}
              // height={'250px'}
              style={{ overflowY: 'scroll' }}
              pad={{ bottom: '30px' }}
            >
              {accessories.map((item, index) => {
                return (
                  <ProductOptions
                    index={index}
                    disabled={disabled}
                    key={index}
                    item={accessoriesById[item]}
                    bike={bike}
                  />
                )
              })}
            </Box>

            <div
              style={{
                pointerEvents: 'none',
                width: '100%',
                position: 'absolute',
                bottom: 0,
                height: 50,
                background: `linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)`,
              }}
            ></div>
          </Box>

          <Box>
            {/* <Paragraph
              margin={{ top: 'xsmall', bottom: 'none' }}
              size="xsmall"
              color="#999999"
            >
              This cost will be spread across the length of your plan
            </Paragraph> */}
          </Box>
        </>
      ) : (
        <Paragraph margin="none" size="small" color="#999999">
          {stepName === 'accessories'
            ? 'Please choose your accessories'
            : 'No accessories chosen'}
        </Paragraph>
      )}
    </Box>
  )
}
