import React, { useState } from 'react'
import AsyncSelect from 'react-select/async'
import axios from 'axios'
import _ from 'lodash'
import { TextInput, Button, Box, Spinner } from 'grommet'
import Select from 'react-select'
import { postcodeAPI } from '../../config'
import API from '@aws-amplify/api'
import { FormTextInput } from '../Forms'
import { current } from '@reduxjs/toolkit'
import { isValid } from 'postcode'
import { useDispatch } from 'react-redux'
import { showError } from '../../state/ui'

const AsyncSearchBar = ({ value, setValue }) => {
  let { name, path } = postcodeAPI

  //set default query terms
  const [results, setResults] = useState([
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ])
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [isOpen, setMenuOpen] = useState(false)
  const [formValue, setFormValue] = useState(false)
  const [manual, setManual] = useState(false)
  const dispatch = useDispatch()

  const loadOptions = async () => {
    setLoading(true)
    if (isValid(query)) {
      let data = await API.get(name, path, {
        queryStringParameters: {
          search: query,
        },
      })
      setLoading(false)
      setResults(data)
      setMenuOpen(true)
    } else {
      setLoading(false)
      dispatch(
        showError({
          title: 'Poscode Error',
          message: 'Please enter a valid UK postcode to find your address',
          type: null,
        })
      )
    }
  }

  return (
    <>
      <Box direction="row" margin={{ bottom: 'medium' }} gap="medium">
        <FormTextInput
          name="postcode"
          placeholder="Enter your postcode ..."
          onChange={e => setQuery(e.target.value)}
          value={query}
        />

        <Button
          onClick={loadOptions}
          disabled={!query}
          size={'large'}
          pad="none"
          style={{ width: 150, height: 50 }}
          primary
          label={
            loading ? (
              <Spinner
                style={{ margin: '0 auto' }}
                alignSelf="center"
                size="xsmall"
              />
            ) : (
              'Lookup'
            )
          }
        />
      </Box>

      {isOpen && (
        <Select
          options={results}
          menuIsOpen={isOpen}
          onChange={({ value }) => {
            setFormValue(value)
            setValue(current => {

              // let house_number = value.formatted_address[0];
              // let building_name = value.formatted_address[1];

              // if(building_name === ""){
              //   house_number = value.building_number;
              //   building_name = value.formatted_address[0].replace(house_number, "").trim();
              // }

              return {
                ...current,
                house_number: value.formatted_address[0],
                building_name: value.formatted_address[1],
                line_3: value.formatted_address[2],
                town_or_city: value.formatted_address[3],
              }
            })
            setMenuOpen(false)
          }}
        />
      )}
      {(formValue || manual) && (
        <Box gap="small">
          <FormTextInput
            name="house_number"
            placeholder="House Number"
            value={value.house_number}
          />
          <FormTextInput
            name="building_name"
            placeholder="Building"
            value={value.building_name}
          />
          <FormTextInput
            name="line_3"
            placeholder="Address 3"
            value={value.line_3}
            required={false}
          />
          <FormTextInput
            name="town_or_city"
            placeholder="City"
            value={value.town_or_city}
          />
        </Box>
      )}

      {!manual && !formValue && (
        <a href="#" onClick={() => setManual(current => !current)}>
          Enter manually
        </a>
      )}
    </>
  )
}

export default AsyncSearchBar
