import {
  faGears,
  faRoute,
  faTruck,
  faRecycle,
  faWeightHanging,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from 'gatsby'
import {
  Anchor,
  Box,
  Button,
  FormField,
  Grid,
  Heading,
  Image,
  Layer,
  Paragraph,
  RadioButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
  ResponsiveContext,
} from 'grommet'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bikeAPI } from '../../config'
import { useResponsive } from '../../hooks'
import { fetchBikeInfo, selectBikeById, selectBike } from '../../state/bikes'
import {
  selectedColor,
  selectedModel,
  selectedSize,
} from '../../state/selection'
import { Close } from 'grommet-icons'
import ClipLoader from 'react-spinners/ClipLoader'

import BikeIcon1 from '../../assets/vectors/bike-icon-01.svg'
import BikeIcon2 from '../../assets/vectors/bike-icon-02.svg'
import BikeIcon3 from '../../assets/vectors/bike-icon-03.svg'

import { useEditableRegions } from '../../hooks'
import parser from 'html-react-parser'
import styled from 'styled-components'
import { numberWithCommas, toTitleCase } from '../../helpers'
import { gaEvent, fbEvent } from '../../helpers'

const StyledFormField = styled(FormField)`
  > div {
    border-color: transparent;
    padding: 0;
  }
`

const StyledTable = styled(Table)`
  th {
    font-size: 14px !important;
    background: #fff;
    font-weight: 700 !important;
    border-bottom: none !important;
  }
  tr {
    &:nth-child(odd) {
      border: none;
      background: #f7f7f7;
    }
    &:nth-child(even) {
      border: none;
      background: #fff;
    }
    /* &:nth-child(4),
    &:nth-child(5) {
      border: none;
      background: #fff;

      td {
        font-weight: bold;
      }
    } */

    td {
      border: none;
    }
  }
`

const StyledTableCell = styled(TableCell)`
  /* background: red !important; */
  /* padding: ${props => (props.isMobile ? 'palevioletred' : null)}; */
  font-size: ${props => (props.isMobile ? '10px' : '14px')};
  /* background: ${props => (props.isMobile ? 'palevioletred' : null)}; */

  p{
    font-size: ${props => (props.isMobile ? '10px' : '14px')};
    line-height: ${props => (props.isMobile ? '10px' : '14px')};
  }
`

const Rule = ({ margin }) => {
  const { isMobile } = useResponsive()
  const marginResponsive = {
    vertical: isMobile ? 'medium' : 'small',
    horizontal: 'none',
  }

  return (
    <Box
      margin={marginResponsive}
      fill="horizontal"
      border={{
        color: 'border',
        size: '1px',
        style: 'solid',
        side: 'top',
      }}
    />
  )
}

const Feature = ({ text, index }) => {
  const icons = [
    <BikeIcon1 width="35px" height="35px" />,
    <BikeIcon2 width="35px" height="35px" />,
    <BikeIcon3 width="35px" height="35px" />,
  ]
  return (
    <Box direction="row" flex="grow" margin="none" align="center" justify="end">
      <Box
        flex={false}
        round="full"
        background="#E2F7E2"
        height="55px"
        width="55px"
        align="center"
        justify="center"
      >
        {icons[index]}
      </Box>
      <Paragraph margin={{ left: 'small', vertical: 'none' }}>{text}</Paragraph>
    </Box>
  )
}

const ColorPicker = ({ colors }) => {
  const dispatch = useDispatch()
  const color = useSelector(state => state.selection.selectedBike.color)
  const [active, setActive] = useState(0)

  useEffect(() => {
    setActive(0)
    dispatch(
      selectedColor({
        hex: colors[0].Hex,
        color: colors[0].Name,
        image: colors[0]['File Name'],
      })
    )
  }, [])

  const onSelect = index => {
    setActive(index)
    dispatch(
      selectedColor({
        hex: colors[index].Hex,
        color: colors[index].Name,
        image: colors[index]['File Name'],
      })
    )
  }

  return (
    <Box direction="row" align="center">
      <Heading
        style={{ width: 40 }}
        margin={{ top: 'none', bottom: 'small', right: 'medium' }}
        level={5}
      >
        Colour
      </Heading>
      <Box direction="row" wrap flex={false}>
        {colors?.length &&
          colors.map(({ bike, Hex, Name }, index) => {
            const border =
              Hex.toLowerCase() === '#ffffff' ? '#e3e3e3' : 'transparent'
            return (
              <Anchor
                key={index}
                href="#"
                margin={{ right: 'small', bottom: '0px' }}
                onClick={() => onSelect(index)}
              >
                <Box
                  className={`colorpicker ${color === Name ? 'active' : ''}`}
                  style={{
                    boxShadow: `0 0 0 2px ${border}`,
                  }}
                  round="full"
                  height="40px"
                  width="40px"
                  background={Hex}
                />
              </Anchor>
            )
          })}
      </Box>
    </Box>
  )
}

const Price = ({ price = '', subPriceWithLock = '', recycle = false }) => {
  const { purchaseType } = useSelector(state => state.ui)
  return (
    <Box direction="column" flex align="start">
      <Heading margin="none" size="medium">
        {purchaseType === 'subscription'
          ? `£${subPriceWithLock?.toFixed(0)}`
          : price}
      </Heading>
      <Paragraph size={'small'} margin="none" color="#999999">
        {purchaseType === 'subscription'
          ? `per month insured and maintained plus one-off ${
              recycle ? '£48' : '£99'
            } joining fee. Min. term 1 month; own after 36 months`
          : 'per month after tax insured and maintained'}
      </Paragraph>
    </Box>
  )
}

const Specs = ({ bike }) => {
  const specs = Object.keys(bike).filter(key => key.includes('Spec'))

  const specTitles = specs.map(title => [
    title.replace('Spec', ''),
    bike[title],
  ])

  const data = {
    columns: ['Feature', 'Info'],
    rows: specTitles,
  }

  return (
    <Box fill margin={{ top: 'large' }}>
      <StyledTable alignSelf="stretch">
        <TableHeader>
          <TableRow>
            {data.columns.map((column, index) => (
              <StyledTableCell key={index} scope="col" align={'left'}>
                {column}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.rows.map((row, index) => (
            <TableRow key={index}>
              {row.map((item, index) => (
                <StyledTableCell key={index} align={'left'}>
                  {item.includes('http') ? (
                    <a href={item} target="_blank">
                      Click here
                    </a>
                  ) : (
                    item
                  )}
                </StyledTableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
    </Box>
  )
}

const PriceCalculator = ({ bike }) => {
  const { editableRegions } = useEditableRegions('Monthly Payment Calculator')
  const { isDesktop, isMobile } = useResponsive()
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState(0)

  const data = {
    columns: [
      'Tax Band (Taxable Income)',
      '12 months',
      '24 months',
      '36 months',
    ],
    rows: [
      [
        'Basic (£12,571 to £50,270 p.a)',
        `£${bike['12m basic'].toFixed(2)}`,
        `£${bike['24m basic'].toFixed(2)}`,
        `£${bike['34m basic'].toFixed(2)}`,
      ],
      [
        'Higher (£50,271 to £150,000 p.a.)',
        `£${bike['12m high'].toFixed(2)}`,
        `£${bike['24m high'].toFixed(2)}`,
        `£${bike['34m high'].toFixed(2)}`,
      ],
      [
        'Additional (over £150,000 p.a)',
        `£${bike['12m additional'].toFixed(2)}`,
        `£${bike['24m additional'].toFixed(2)}`,
        `£${bike['34m additional'].toFixed(2)}`,
      ],
      [
        'Total Package Value',
        `£${numberWithCommas(bike['TPV 12m'].toFixed(2))}`,
        `£${numberWithCommas(bike['TPV 24m'].toFixed(2))}`,
        `£${numberWithCommas(bike['TPV 36m'].toFixed(2))}`,
      ],
      [
        'Bike RRP',
        `£${numberWithCommas(bike['RRP'])}`,
        `£${numberWithCommas(bike['RRP'])}`,
        `£${numberWithCommas(bike['RRP'])}`,
      ],
    ],
  }

  const onSelectRow = value => {
    value < 3 && setSelected(value)
  }

  return (
    <>
      <Box flex={false}>
        <Button
          style={{
            color: 'white',
            fontSize: 12,
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 15,
            paddingRight: 15,
          }}
          color="black"
          active
          secondary
          size="small"
          fill={isMobile}
          alignSelf="start"
          onClick={() => setOpen(true)}
          label="Cycle to work"
        ></Button>
      </Box>

      {open ? (
        <Layer
          light
          onEsc={() => setOpen(false)}
          onClickOutside={() => setOpen(false)}
        >
          <Box
            width={{ min: 'large' }}
            height={{ min: 'small' }}
            align="center"
            justify="center"
            direction="column"
            alignSelf="center"
            pad="large"
          >
            <Box fill justify="between" direction="row">
              <Heading margin="none" level={3}>
                Cycle to Work Price Calculator
              </Heading>
              <Button plain icon={<Close />} onClick={() => setOpen(false)} />
            </Box>
            <Box alignSelf="start" direction="row">
              <Paragraph margin={{ vertical: 'small' }} fill>
                {/* {`Cost per month (after tax) including insurance and maintenance`} */}
                {`With Blike Cycle to Work you’ll save between 33-48% on the monthly cost. The table show cost per month (after tax) including insurance & maintenance`}
              </Paragraph>
            </Box>
            <Rule />
            <StyledTable alignSelf="stretch">
              <TableHeader>
                <TableRow>
                  {data.columns.map((column, index) => (
                    <StyledTableCell
                      key={index}
                      scope="col"
                      align={index === 0 ? 'left' : 'center'}
                    >
                      {column}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {data.rows.map((row, index) => (
                  <TableRow
                    onClick={() => onSelectRow(index)}
                    key={index}
                    style={{
                      outline:
                        index === selected ? '2px solid #03E272' : 'none',
                    }}
                  >
                    {row.map((item, index) => (
                      <StyledTableCell
                        key={index}
                        align={index === 0 ? 'left' : 'center'}
                      >
                        {item}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>

            <Box margin={{ top: 'medium' }}>
              <Box className="editable smallprint">
                {parser(editableRegions['Footer Terms'])}
              </Box>
            </Box>
          </Box>
        </Layer>
      ) : null}
    </>
  )
}

const SizePicker = ({ sizes }) => {
  const dispatch = useDispatch()
  const size = useSelector(state => state.selection.selectedBike.size)

  const sizeNames = sizes.map(e => e['Display Name'])

  useEffect(() => {
    if (sizes.length === 1) {
      dispatch(selectedSize(sizes[0]['Display Name']))
    }
  }, [])

  // useEffect(()=>{
  //   console.log("SIZE", size);
  // },[size])

  return (
    <Box direction="row" align="center">
      <Heading
        margin={{ top: 'none', bottom: 'small', right: 'medium' }}
        level={5}
        style={{ width: 40 }}
      >
        Size
      </Heading>
      <StyledFormField name="TYPE" required margin="none">
        <RadioButtonGroup
          gap="xsmall"
          onChange={({ target: { value } }) => dispatch(selectedSize(value))}
          value={size}
          size="small"
          direction="column"
          style={{ borderColor: 'black' }}
          name="TYPE"
          options={sizeNames}
        />
      </StyledFormField>
    </Box>
  )
}

const getImageURL = ({ bike, imageName }) => {
  const brand = bike?.Brands?.toLowerCase().replace(/ /g, '') || null
  const model = bike?.Model?.toLowerCase().replace(/ /g, '') || null

  return imageName && brand && model
    ? `https://blike.imgix.net/${brand}/${model}/${encodeURIComponent(
        imageName
      )}?w=800&q=64&auto=format`
    : null
}

const BikeImage = ({ bike }) => {
  const [url, setURL] = useState(null)
  const color = useSelector(state => state.selection.selectedBike.hex)

  //CREATE BIKE COLOR NAMES

  useEffect(() => {
    let currentColor = bike.colors.filter(obj => {
      return obj.Hex === color
    })

    const imageName = currentColor[0] ? currentColor[0]['File Name'] : null
    const url = getImageURL({ bike, imageName })
    setURL(url)
  }, [color])

  return url ? <Image fill fit="contain" src={url} /> : null
}

const RecycleDeliveryTime = ({ deliveryInfo }) => {
  return (
    <>
      <FontAwesomeIcon
        // style={{ color: delivery === 'Sold Out' ? '#C64402' : null }}
        icon={faRecycle}
        size={'lg'}
      />
      <Paragraph
        size="xsmall"
        margin={{ left: 'xsmall', vertical: 'none' }}
        className="light"
        color="primary"
      >
        {deliveryInfo}
      </Paragraph>
    </>
  )
}

const DeliveryTime = ({ deliveryInfo }) => {
  console.log('DELIVERY INFO', deliveryInfo)
  //Ampler Stella Red Small
  const size = useSelector(state => state.selection.selectedBike.size)
  const color = useSelector(state => state.selection.selectedBike.color)
  const deliveryTime = deliveryInfo.filter(
    e => e['Colours'] === color && e['Size Display Name'] === size
  )

  const delivery = deliveryTime.length
    ? `${deliveryTime[0]['Display_Days_Final']}`
    : null

  return (
    <>
      <FontAwesomeIcon
        style={{ color: delivery === 'Sold Out' ? '#C64402' : null }}
        icon={faTruck}
        size={'xs'}
      />
      <Paragraph
        size="xsmall"
        margin={{ left: 'xsmall', vertical: 'none' }}
        className="light"
        color={delivery === 'Sold Out' ? 'status-critical' : null}
      >
        {delivery ? delivery : 'Select size for delivery time'}
      </Paragraph>
    </>
  )
}

const LifeStyleImages = ({ bike }) => {
  const [imageOne, setImageOne] = useState(null)
  const [imageTwo, setImageTwo] = useState(null)
  const { size, isDesktop, isMobile } = useResponsive()

  useEffect(() => {
    setImageOne(getImageURL({ bike, imageName: 'lifestyle1.jpg' }))
    setImageTwo(getImageURL({ bike, imageName: 'lifestyle2.jpg' }))
  }, [bike])

  return (
    <Grid
      margin={{ top: 'large' }}
      fill
      gap={isMobile ? 'medium' : 'none'}
      columns={isMobile ? ['flex'] : ['auto', 'auto']}
    >
      <Box width="auto" height="600px" background="#e3e3e3">
        {imageOne && <Image fill fit={'cover'} src={imageOne} />}
      </Box>
      <Box width="auto" height="600px" background="#e3e3e3">
        {imageTwo && <Image fill fit={'cover'} src={imageTwo} />}
      </Box>
    </Grid>
  )
}

const ResponsiveGrid = ({ children, areas, ...props }) => {
  const size = React.useContext(ResponsiveContext)
  const gridAreas = areas[size]
  console.log(gridAreas)

  return (
    <Grid areas={areas[size]} {...props}>
      {children}
    </Grid>
  )
}

const BikeInfoArea = ({ bike, id, recycle = false }) => {
  const dispatch = useDispatch()
  const { size, isDesktop, isMobile } = useResponsive()

  const { selectedBike } = useSelector(state => state.selection)

  const selectBike = e => {
    let includedAccessories = bike['Included Accessories']
    e.preventDefault()

    gaEvent('add-accessory', selectedBike)
    fbEvent('AddToCart', selectedBike)

    dispatch(
      selectedModel({
        model: id,
        price: bike.RRP,
        subPrice: bike.subPrice,
        includedAccessories,
        recycle,
      })
    )
    navigate('/accessorypicker')
  }

  return (
    <Box>
      <Box>
        {recycle && (
          <Paragraph
            size="large"
            margin={{ top: 'medium', bottom: 'none' }}
            color="brand"
          >
            ReCycle
          </Paragraph>
        )}
        <Heading level={2} margin="none">
          {bike['Display Name']}
        </Heading>
        <Paragraph>{bike.Discription}</Paragraph>

        <Box
          gap="small"
          direction={isMobile ? 'column' : 'row'}
          justify="between"
          align={isMobile ? 'start' : 'center'}
        >
          <Price
            recycle={recycle}
            price={bike['Headline Price']}
            subPrice={bike['Sub Monthly']}
            subPriceWithLock={bike['Display Sub Monthly -including 100 lock']}
          />
          {isDesktop && <PriceCalculator bike={bike} />}
        </Box>

        <Rule />
        {bike['colors'] && <ColorPicker colors={bike['colors']} />}
        <Rule />
        {bike['sizes'] && <SizePicker sizes={bike['sizes']} />}
        <Rule />
      </Box>
      <Box direction="column">
        <Box direction="row" justify="start">
          {recycle ? (
            <RecycleDeliveryTime deliveryInfo={bike['Recycle Delivery Info']} />
          ) : (
            <DeliveryTime deliveryInfo={bike['deliveryInfo']} />
          )}
        </Box>
        <Box
          margin={{ top: 'medium' }}
          animate={'fadeIn'}
          style={{ position: 'relative' }}
        >
          <Button
            fill={true}
            disabled={!selectedBike.size}
            onClick={selectBike}
            alignSelf="start"
            color="brand"
            style={{
              fontSize: 20,
              width: isMobile ? 'none' : '50%',
            }}
            primary
            round
            size="large"
            label="Get this bike"
          />
        </Box>
      </Box>
    </Box>
  )
}

const BikeImageArea = ({ bike }) => {
  const { size, isDesktop, isMobile } = useResponsive()

  return (
    <Box
      animate={'fadeIn'}
      margin={{ bottom: isMobile ? 'large' : 'none' }}
      justify="start"
      align="start"
      flex
      alignSelf="stretch"
      fill="horizontal"
      style={{ position: 'relative' }}
    >
      <Box
        flex={false}
        justify="center"
        height={isMobile ? '100%' : 'calc(100% - 100px)'}
        fill="horizontal"
        margin={'none'}
        style={{ position: 'relative' }}
      >
        <Box
          fill
          style={{
            position: isMobile ? 'relative' : 'absolute',
            top: 0,
            left: 0,
          }}
          justify="center"
          align="center"
        >
          {bike['colors'] && <BikeImage bike={bike} colors={bike['colors']} />}
        </Box>
      </Box>
      {isDesktop && (
        <Box
          style={{ position: 'absolute', bottom: 0, overflow: 'hidden' }}
          height="15%"
          fill="horizontal"
          direction="row"
          align="end"
          justify="between"
          gap="small"
        >
          <Feature index={0} text={bike['Range (km)']} />
          <Feature index={1} text={bike['Weight ']} />
          <Feature index={2} text={bike['Gears']} />
        </Box>
      )}
    </Box>
  )
}

const Bike = ({ id, location }) => {
  const recycle = location?.pathname.includes('recycle')
  const dispatch = useDispatch()
  const { editableRegions } = useEditableRegions('Bike info')
  const { size, isDesktop, isMobile } = useResponsive()
  const { purchaseType } = useSelector(state => state.ui)

  const areas = {
    small: ['auto'],
    medium: ['medium', 'auto'],
    large: ['medium', 'auto'],
  }

  const bike = useSelector(state => selectBikeById(state, id))

  useEffect(() => {
    dispatch(fetchBikeInfo(id, recycle))
  }, [])

  useEffect(() => {
    if (!bike) return
    gaEvent('bike-view', bike)
    fbEvent('ViewContent', bike)
  }, [bike])

  return (
    <>
      {bike?.sizes ? (
        <Box
          pad={{ top: 'large', horizontal: 'large' }}
          animate={'fadeIn'}
          direction="column"
          justify="start"
          flex={false}
          margin={{ bottom: 'large' }}
        >
          <Grid
            flex
            columns={areas[size]}
            gap={{ column: 'large' }}
            style={{ gridAutoFlow: 'dense' }}
          >
            {isMobile ? (
              <>
                <BikeImageArea bike={bike} id={id} />
                <BikeInfoArea bike={bike} id={id} recycle={recycle} />
              </>
            ) : (
              <>
                <BikeInfoArea bike={bike} id={id} recycle={recycle} />
                <BikeImageArea bike={bike} id={id} />
              </>
            )}
          </Grid>
          <LifeStyleImages bike={bike} />
          <Specs bike={bike} />
        </Box>
      ) : (
        <Box align="center" flex fill justify="center" height={{ min: '80vh' }}>
          <Text>Fetching bike...</Text>
          <Box pad="large">
            <ClipLoader
              color={'#03E272'}
              loading={true}
              size={40}
              speedMultiplier={0.5}
            />
          </Box>
        </Box>
      )}

      <Box className="editable smallprint" pad={{ horizontal: 'large' }}>
        {purchaseType === 'subscription'
          ? parser(editableRegions['Footer note subscription'])
          : parser(editableRegions['Footer note'])}
      </Box>
    </>
  )
}

export default Bike
