import React, { useEffect, useState, useContext, useRef } from 'react'
import {
  Box,
  Heading,
  Card,
  Form,
  Grid,
  Button,
  CheckBox,
  Paragraph,
} from 'grommet'

import axios from 'axios'

import { Add } from 'grommet-icons'

import { useResponsive } from '../../hooks'

import { navigate } from 'gatsby'

import { employersAPI } from '../../config'

import { useDispatch, useSelector } from 'react-redux'
import Rule from '../Components/Rule'

const everSignObject = {
  sandbox: 1,
  template_id: 'd70bbace47644548a393b068c3aca576',
  title: 'My New Document',
  message: 'This is my message.',
  client: '',
  embedded_signing_enabled: 1,
  signers: [
    {
      role: 'Green Switch',
      name: 'Tim Carrigan',
      email: 'tim@greenswitchgroup.com',
      language: 'en',
      deliver_email: 1,
    },
    {
      role: 'Customer',
      name: 'James Rhodes',
      email: 'rhodes.james@gmail.com',
      message: '',
      deliver_email: 1,
      language: 'en',
    },
  ],
}

const ContractForm = ({ location }) => {
  let {
    state: { employer, contacts },
  } = location

  const [loading, setLoading] = useState(true)
  const [url, setUrl] = useState(false)
  const [complete, setComplete] = useState(false)

  useEffect(() => {
    console.log(employer)

    {contacts && contacts.length &&
      axios
      .post(
        'https://api.eversign.com/api/document?access_key=3901c108d753bc6ef76447578fa7fe66&business_id=307763&document_hash=901c108d753bc6ef76447578fa7fe66',
        {
          ...everSignObject,
          fields: [
            // {
            //   identifier: 'text_wLtiiD3BbgNp6B',
            //   value: 'Agreement Date',
            // },
            {
              identifier: 'text_O03k0iSC2yVd0q',
              value: employer.companyName,
            },
            {
              identifier: 'text_p77M9JD1OpLpEp',
              value: employer.companyNumber,
            },
            {
              identifier: 'text_0RpWDaiRsy5JCv',
              value: employer.companyAddress1,
            },
            {
              identifier: 'text_VqEVcTImGAnKWy',
              value: `${contacts[0].companyContactFirstname} ${contacts[0].companyContactLastname}`
            },
            {
              identifier: 'text_lTPWvTFUtGwoyU',
              value: contacts[0].department,
            },
            {
              identifier: 'text_6fsxFUemu9y8ET',
              value: contacts[0].companyContactEmail,
            },
            {
              identifier: 'text_tZ376Brp9GRems',
              value: contacts[0].companyContactPhone,
            },
            {
              identifier: 'text_6s0U9AEif6EC7v',
              value: 'FCA Registration Number',
            },
            {
              identifier: 'text_QxnxR8tFnitgQZ',
              value: 'GS Name',
            },
            {
              identifier: 'text_0ausy5mfcCgQ5O',
              value: 'GS Job Title',
            },
            {
              identifier: 'text_1wtFFKDOpgVhCk',
              value: 'Text',
            },
            {
              identifier: 'text_D7kqhw8ZJ6gi8N',
              value: 'GS phone',
            },
            {
              identifier: 'text_dPvq2e0Bw6cKuQ',
              value: 'Service Start Date',
            },
            {
              identifier: 'text_jmTd6hzKROJ40L',
              value: employer.companyName,
            },
            {
              identifier: 'text_hYTzO1TkwHPFRC',
              value: `${contacts[0].companyContactFirstname} ${contacts[0].companyContactLastname}`,
            },
            {
              identifier: 'text_hMsOt93Wo3tAiA',
              value: 'Payment Days',
            },
            {
              identifier: 'text_K3WLDBFBKazhUE',
              value: 'Interest Rate',
            },
            {
              identifier: 'text_eb0AKzxYMhwgbj',
              value: 'Insurance Cover',
            },
            {
              identifier: 'text_ntj3Gdi4ahDUFS',
              value: 'GS Name',
            },
            {
              identifier: 'text_06cgQnxwqxAC3Q',
              value: employer.companyName,
            },
          ],
        }
      )
      .then(response => {
        console.log(response)
        setUrl(response.data.signers[0].embedded_signing_url)
        setLoading(false)
      })
    }
    
  }, [])

  const handler = event => {
    if (event.data === 'event_signed') {
      setComplete(true)
    }
    // const data = JSON.parse(event.data);
    // console.log(data);
    // console.log("Hello World?", data)
  }

  useEffect(() => {
    window.addEventListener('message', handler)

    // clean up
    return () => window.removeEventListener('message', handler)
  }, []) // empty array => run only once

  return (
    <Box style={{ position: 'relative' }}>
      <Box
        direction="column"
        justify="start"
        flex={false}
        margin={{ bottom: 'large' }}
      >
        <Grid fill columns={['flex']} rows={['flex']} gap="xlarge">
          <Box>
            {complete ? (
              <Box height="large">
                <h1>Thank you!</h1>
                <p>We'll be in touch</p>
              </Box>
            ) : (
              url && <iframe src={url} height="800" />
            )}
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}

export default ContractForm
