import { Box, Button, Heading } from 'grommet'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Close } from 'grommet-icons'
import {
  useResponsive,
  useCheckoutPhase,
  useEditableRegions,
} from '../../../hooks'
import { Accessories, AccessoriesHeader } from './Accessories'
import {
  SelectedPlan,
  SelectedPlanHeader,
  SelectedPlanSubscription,
} from './SelectedPlan'
import { SelectedBike, SelectedHeader } from './SelectedBike'
import { useSelector } from 'react-redux'
import parser from 'html-react-parser'
import { discountSelector } from '../../../state/discount';

const Sidebar = () => {
  const { isMobile } = useResponsive()
  const { purchaseType } = useSelector(state => state.ui)

  //SELECTED BIKE
  const image = useSelector(state => state.selection.selectedBike.image)
  const selectedId = useSelector(state => state.selection.selectedBike.model)
  const bikes = useSelector(state => state.bikes.models.byId)

  //ACCESSORIES
  const { step, stepName } = useCheckoutPhase()
  const bikesById = useSelector(state => state.bikes.models.byId)
  const { accessoriesMonthlyTotal, accessoriesTotal } = useSelector(
    state => state.selection
  )

  const discount = useSelector(discountSelector);


  const selectedAccessories = useSelector(
    state => state.selection.selectedAccessories
  )

  const { model, size, color, includedAccessories } = useSelector(
    state => state.selection.selectedBike
  )

  const selectedPlan = useSelector(state => state.selection.selectedPlan)

  const { editableRegions } = useEditableRegions('Sidebar')

  console.log('discount', discount)
  return (
    <Box
      flex={false}
      className='hideScrollbar'
      style={{ overflowY: 'scroll' }}
      height="100vh"
      pad={{
        right: 'large',
        top: isMobile ? 'xlarge' : '25px',
        bottom: 'large',
      }}
      border={{
        color: 'border',
        size: '1px',
        style: 'solid',
        side: 'right',
      }}
    >
      <SelectedHeader
        step={step}
        subPrice={bikes[selectedId].subPrice}
        rrp={bikes[selectedId].price}
        purchaseType={purchaseType}
      />
      <SelectedBike
        step={step}
        displayName={bikes[selectedId]['Display Name']}
        brand={bikes[selectedId].brand}
        model={bikes[selectedId].Model}
        recycle={bikes[selectedId].recycle}
        purchaseType={purchaseType}
        subPrice={bikes[selectedId].subPrice}
        rrp={bikes[selectedId].RRP}
        image={image}
        size={size}
        color={color}
        includedAccessories={includedAccessories}
      />
      <AccessoriesHeader
        step={step}
        total={accessoriesTotal}
        monthlyTotal={accessoriesMonthlyTotal}
        purchaseType={purchaseType}
      />
      <Accessories
        step={step}
        disabled={step < 1}
        bike={bikesById[selectedId]}
        accessories={selectedAccessories}
        total={accessoriesTotal}
        monthlyTotal={accessoriesMonthlyTotal}
      />
      {purchaseType === 'subscription' ? (
        <>
          <SelectedPlanHeader
            price={(
              bikes[selectedId].subPrice + parseFloat(accessoriesMonthlyTotal)
            ).toFixed(2)}
          />
          <SelectedPlanSubscription
            // step={step}
            recycle={bikes[selectedId].recycle}
            discount={discount}
            ownership={(bikes[selectedId].RRP * 0.12).toFixed(2)}
            price={(
              bikes[selectedId].subPrice + parseFloat(accessoriesMonthlyTotal) - discount.amount).toFixed(2)}
            startDate={bikes[selectedId]['Lead time']}
          />
        </>
      ) : (
        <>
          <SelectedPlanHeader net={selectedPlan?.plan?.net} />
          <SelectedPlan
            recycle={bikes[selectedId].recycle}
            step={step}
            length={selectedPlan?.length}
            net={selectedPlan?.plan?.net}
            stepName={stepName}
            terms={parser(editableRegions['Footer Terms'])}
          />
        </>
      )}
    </Box>
  )
}

export default Sidebar
