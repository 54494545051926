import {
  Box,
  Button,
  Heading,
  Paragraph,
  Table,
  TableCell,
  TableBody,
  TableRow,
  Accordion,
  AccordionPanel,
  Anchor,
} from 'grommet'
import React, { useEffect, useState } from 'react'
import { numberWithCommas } from '../../../helpers'
import { useResponsive } from '../../../hooks'
import styled from 'styled-components'
import { InfoPoint } from '../../Components/InfoPoint'
import { CircleInformation, Close } from 'grommet-icons'

const StyledTable = styled(Table)`
  width: 100%;

  th {
    font-size: 14px !important;
    background: #fff;
    font-weight: 700 !important;
    border-bottom: none !important;
  }
  tr {
    &:nth-child(odd) {
      border: none;
      background: #f7f7f7;
    }
    &:nth-child(even) {
      border: none;
      background: #fff;
    }
    /* &:nth-child(4),
    &:nth-child(5) {
      border: none;
      background: #fff;

      td {
        font-weight: bold;
      }
    } */

    td {
      border: none;
    }
  }
`

const StyledTableCell = styled(TableCell)`
  font-size: 12px;

  p {
    font-size: 12px;
    line-height: 14px;
  }
`

function Row({
  selectPlan,
  timeLength,
  net,
  highlight = false,
  setActiveIndex,
  index,
  activeIndex,
}) {
  return (
    <Box
      direction="column"
      align="start"
      justify="between"
      pad={{ vertical: 'large', horizontal: 'medium' }}
      style={{
        position: 'relative',
        zIndex: highlight ? 100 : 0,
      }}
      background={highlight ? 'transparent' : 'background-back'}
    >
      <Box direction="row" justify="between" align="center" fill>
        {highlight && (
          <Box
            round
            flex={false}
            width="calc(100% + 20px)"
            style={{
              top: 0,
              left: '-10px',
              maxWidth: 'none',
              position: 'absolute',
              height: '100%',
              zIndex: -1,
              boxShadow: highlight ? `0px 0px 41px 3px rgba(0,0,0,0.1)` : null,
            }}
            background="white"
          ></Box>
        )}
        <Box direction="column">
          {highlight && (
            <Heading
              style={{ position: 'absolute', top: 10 }}
              margin="none"
              level={5}
              size="xsmall"
              color="brand"
            >
              MOST POPULAR
            </Heading>
          )}
          <Heading margin="none" level={3}>{`${timeLength} Months`}</Heading>
        </Box>

        <Heading level={2} margin="none" size="small">{`£${numberWithCommas(
          parseInt(net).toFixed(0)
        )}`}</Heading>
        <Button
          onClick={() => selectPlan(timeLength)}
          primary
          size="small"
          label="Select"
        />
      </Box>
      <Box direction="row" align="center">
        {/* <Button
          fill="horizontal"
          alignSelf="center"
          plain
          s
          margin={{ vertical: 'xsmall' }}
          size="xsmall"
          label="View Cost Info"
        /> */}
        <Anchor
          size="xsmall"
          margin={{ right: '5px' }}
          href="#"
          color="text-weak"
          onClick={() => setActiveIndex(index)}
        >
          <Box direction="row" gap="xsmall">
            <Heading margin="none" level={5} size="xsmall">
              {activeIndex === index  ? "HIDE DETAILS" : "SHOW DETAILS"}
            </Heading>
            <CircleInformation size="13px" color="text-weak" />
          </Box>
        </Anchor>
      </Box>
    </Box>
  )
}

const PriceBreakdown = ({ quote, timeLength, infoPoints }) => {
  const [quoteData, setQuoteData] = useState(null)
  const { size, isMobile } = useResponsive()

  useEffect(() => {
    if (quote[timeLength] && quote['24'] && quote['36']) {
      const data = {
        id: quote.id,
        net: [
          `£${numberWithCommas(parseInt(quote[timeLength].net).toFixed(0))}`,
        ],
        columns: [timeLength],
        rows: [
          [
            <Box direction="row" align="center">
              <Paragraph size={size}>
                {'Gross month salary deduction'}
              </Paragraph>
              <InfoPoint info={infoPoints['Gross Monthly']} />
            </Box>,
            `£${parseInt(quote[timeLength].gross)}`,
          ],
          [
            <Box direction="row" align="center">
              <Paragraph size={size}>{'Your total package value'}</Paragraph>
              <InfoPoint info={infoPoints['Total Package Value']} />
            </Box>,
            `£${numberWithCommas(parseInt(quote[timeLength].packageValue))}`,
          ],
          [
            <Box direction="row" align="center">
              <Paragraph size={size}>{'Optional ownership payment'}</Paragraph>
              <InfoPoint info={infoPoints['Optional Ownership Payment']} />
            </Box>,
            `£${numberWithCommas(parseInt(quote[timeLength].finalPayment))}`,
          ],
          [
            <Box direction="row" align="center">
              <Paragraph size={size}>{'Your estimated saving £'}</Paragraph>
              <InfoPoint info={infoPoints['Your Estimated Saving £']} />
            </Box>,
            `£${numberWithCommas(parseInt(quote[timeLength].saving))}`,
          ],
          [
            <Box direction="row" align="center">
              <Paragraph size={size}>{'Your estimated saving %'}</Paragraph>
              <InfoPoint info={infoPoints['Your Estimated Saving %']} />
            </Box>,
            `${(quote[timeLength].savingPercent * 100).toFixed(2)}%`,
          ],
          ['Hassle Free Cycling', `Priceless`],
        ],
      }
      setQuoteData(data)
    }
  }, [quote, timeLength])

  return (
    <StyledTable alignSelf="stretch">
      <TableBody>
        {quoteData &&
          quoteData.rows.map((row, index) => (
            <TableRow key={index}>
              {row.map((item, index) => (
                <StyledTableCell
                  justify="center"
                  isMobile={true}
                  key={index}
                  align={index === 0 ? 'left' : 'center'}
                >
                  {item}
                </StyledTableCell>
              ))}
            </TableRow>
          ))}
      </TableBody>
    </StyledTable>
  )
}

export const MobileTable = ({ quote, selectPlan, infoPoints }) => {
  const [activeIndex, setActiveIndex] = useState(-1)

  const setActivePanel = index => {
    if (activeIndex === index) {
      setActiveIndex(-1)
    } else {
      setActiveIndex(index)
    }
  }

  return (
    <Box direction="column">
      <Box direction="row" justify="between">
        <Heading
          margin={{ horizontal: 'none', vertical: 'small' }}
          level={4}
          color="brand"
        >
          Plan Length
        </Heading>
        <Box direction="row">
          <Heading
            margin={{ horizontal: 'none', vertical: 'small' }}
            level={4}
            color="brand"
          >
            Net Monthly
          </Heading>
          <InfoPoint info={infoPoints['Net Monthly']} />
        </Box>
      </Box>
      <Accordion activeIndex={activeIndex}>
        <AccordionPanel
          header={
            <Row
              timeLength={'12'}
              net={quote['12'].net}
              selectPlan={selectPlan}
              index={0}
              activeIndex={activeIndex}
              setActiveIndex={setActivePanel}
            />
          }
        >
          <PriceBreakdown
            timeLength={'12'}
            quote={quote}
            infoPoints={infoPoints}
          />
        </AccordionPanel>
        <AccordionPanel
          header={
            <Row
              timeLength={'24'}
              net={quote['24'].net}
              selectPlan={selectPlan}
              highlight
              index={1}
              activeIndex={activeIndex}
              setActiveIndex={setActivePanel}
            />
          }
        >
          <PriceBreakdown
            timeLength={'24'}
            quote={quote}
            infoPoints={infoPoints}
          />
        </AccordionPanel>
        <AccordionPanel
          header={
            <Row
              timeLength={'36'}
              net={quote['36'].net}
              selectPlan={selectPlan}
              index={2}
              activeIndex={activeIndex}
              setActiveIndex={setActivePanel}
            />
          }
        >
          <PriceBreakdown
            timeLength={'36'}
            quote={quote}
            infoPoints={infoPoints}
          />
        </AccordionPanel>
      </Accordion>
    </Box>
  )
}
