import React from 'react';
import { Box } from 'grommet';
import { useResponsive } from '../../../hooks';
import { useEditableRegions } from '../../../hooks';
import parser from 'html-react-parser';
import { MobileTable } from './MobileTable';
import { DesktopTable } from './DesktopTable';

export const QuoteTable = ({ quote, selectPlan }) => {
  const { editableRegions, infoPoints } = useEditableRegions('Select Your Plan');
  const { size, isMobile } = useResponsive();

  return (
    <Box height="auto" flex="shrink" >
      {isMobile ? (
        <MobileTable
          quote={quote}
          selectPlan={selectPlan}
          infoPoints={infoPoints} />
      ) : (
        <DesktopTable
          quote={quote}
          selectPlan={selectPlan}
          infoPoints={infoPoints} />
      )}
      <Box className="editable smallprint" pad={{ vertical: 'large' }}>
        {parser(editableRegions['Plan Terms'] || "")}
      </Box>
    </Box>
  );
};
