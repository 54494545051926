import React from 'react'
import { Redirect } from '@reach/router'
import { AppUser } from '../Auth'
import { Layout } from '../Layout'

const PrivateRoute = ({ component: Component, location, title, ...rest }) => {
  const { isLoggedIn } = AppUser

  return !isLoggedIn() ? (
    <Redirect to={`/signin/?redirect=${location.pathname.replace("/", "")}`} noThrow />
  ) : (
    <Layout isUserNav={isLoggedIn()} title={title}>
      <Component {...rest} />
    </Layout>
  )
}

export default PrivateRoute
