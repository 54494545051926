import { Image } from 'grommet'
import React, { useEffect, useState } from 'react'

export const BikeImage = ({ brand = '', model = '', image = '' }) => {

  const [imageState, setImage] = useState(null);

  useEffect(()=>{

    const imageName = image
    ? `https://blike.imgix.net/${brand
        .toLowerCase()
        .replace(/ /g, '')}/${model
        .toLowerCase()
        .replace(/ /g, '')}/${encodeURIComponent(image)}?w=800&q=64&auto=format`
    : null
    console.log("image", imageName)

    brand && setImage(imageName);

  },[brand, image])

  useEffect(()=>{
    console.log("imageState", imageState);
  },[imageState])
 

  return imageState ? <Image fill fit="contain" src={imageState} /> : null
}
