import React, { useState } from 'react'
import { Link } from 'gatsby'
import { navigate } from '@reach/router'
import Auth from '@aws-amplify/auth'

import {
  AuthForm,
  Email,
  Password,
  ConfirmPassword,
  ConfirmationCode,
} from '../Forms/AuthForms'

import Swoosh from '../../assets/vectors/squiggle.svg'

import {
  Box,
  Button,
  Anchor,
  Paragraph,
  Heading,
  FileInput,
  Grommet,
  MaskedInput,
  RadioButtonGroup,
  RangeInput,
  Select,
  TextArea,
  TextInput,
} from 'grommet'

const initialState = {
  email: ``,
  auth_code: ``,
  password: ``,
  error: ``,
  loading: false,
  stage: 0,
}

const Reset = () => {
  const [state, setState] = useState(initialState)

  const handleUpdate = event => {

    let target = event.target;

    console.log("event", event);
    setState(current => ({
      ...current,
      [target.name]: target.value,
      error: '',
    }))
  }

  const reset = async e => {
    e.preventDefault()
    const { email } = state
    try {
      setState(current => ({ ...current, loading: true }))
      await Auth.forgotPassword(email)
      setState(current => ({ ...current, loading: false, stage: 1 }))
    } catch (err) {
      setState(current => ({ ...current, error: err, loading: false }))
      console.log('error...: ', err)
    }
  }

  const confirmReset = async e => {
    e.preventDefault()
    const { email, auth_code, confirmPassword, password } = state

    if (confirmPassword !== password) {
      setState(current => ({
        ...current,
        error: "Passwords don't match",
        loading: false,
      }))
      return
    }

    setState(current => ({ ...current, loading: true }))
    Auth.forgotPasswordSubmit(email, auth_code, password)
      .then(data => {
        console.log(data)
        setState(current => ({ ...current, loading: false }))
      })
      .then(() => navigate('/signin'))
      .catch(err => {
        console.log(err)
        setState(current => ({ ...current, error: err, loading: false }))
      })
  }

  if (state.stage === 0) {
    return (
      <Box align="center" justify="center" flex="grow">
        <Swoosh
          width="50%"
          style={{ position: 'absolute', zIndex: -1, pointerEvents: 'none' }}
        />
        <AuthForm title="Reset your password" error={state.error}>
          <Email
            handleUpdate={handleUpdate}
            email={state.email}
            autoComplete="on"
          />
          <Button
            primary
            size="large"
            onClick={e => reset(e)}
            type="submit"
            disabled={state.loading}
            label={'Send Code'}
          />
          <p style={{ marginTop: 40 }} className="text-center">
            <Link to="/signin">Back to Sign In</Link>
          </p>
        </AuthForm>
      </Box>
    )
  }

  return (
    <Box align="center" justify="center" flex="grow">
      <Swoosh
        width="50%"
        style={{ position: 'absolute', zIndex: -1, pointerEvents: 'none' }}
      />
      <AuthForm title="Confirm Password Reset" error={state.error}>
        <Email
          handleUpdate={handleUpdate}
          email={state.email}
          autoComplete="on"
        />
        <ConfirmationCode
          handleUpdate={handleUpdate}
          email={state.auth_code}
          autoComplete="off"
        />
        <Password
          handleUpdate={handleUpdate}
          password={state.password}
          autoComplete="on"
        />
        <ConfirmPassword
          handleUpdate={handleUpdate}
          password={state.confirmPassword}
          autoComplete="on"
        />

        <Button
          primary
          size="large"
          onClick={e => confirmReset(e)}
          type="submit"
          className="btn btn-primary btn-block"
          disabled={state.loading}
          label={state.loading ? null : 'Reset Password'}
        ></Button>
      </AuthForm>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Paragraph
          style={{ marginTop: 20, marginBottom: 20 }}
          className="text-center"
        >
          Lost your code?
        </Paragraph>
        <Anchor href="#" margin={{ left: 'small' }} onClick={e => reset(e)}>
          Resend Code
        </Anchor>
      </div>
    </Box>
  )
}

export default Reset
