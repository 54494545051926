import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { AppContent } from '../Layout'
import { navigate } from 'gatsby'
import Auth from '@aws-amplify/auth'
import API from '@aws-amplify/api'
import { orderAPI, employersAPI, usersAPI, subscriptionAPI } from '../../config'
import { dispatch, useSelector } from 'react-redux'
import { AppUser } from '../Auth'
import {
  Box,
  Heading,
  Grid,
  Anchor,
  FormField,
  Text,
  Image,
  TextInput,
  Layer,
  Form,
  Button,
  Paragraph,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHeader,
} from 'grommet'
import Rule from '../Components/Rule'
import Seo from '../../components/Seo'
import Number from '../Components/Number'

import { AccessoriesHeader } from '../Components/Sidebar/Accessories'
import {
  SelectedPlan,
  SelectedPlanHeader,
  SelectedPlanSubscription,
} from '../Components/Sidebar/SelectedPlan'
import {
  SelectedBike,
  SelectedHeader,
} from '../Components/Sidebar/SelectedBike'
import parser from 'html-react-parser'
import { useEditableRegions, useResponsive } from '../../hooks'
import ClipLoader from 'react-spinners/ClipLoader'
import GoCardless from '../Components/PaymentFlow'
import Order from '../../state/order'

let { name, path } = orderAPI
let { name: usersApiName } = usersAPI

const AccessoriesSummary = ({ accessories }) => {
  const total = accessories.reduce((a, { cost }) => a + cost, 0)

  return (
    <Box>
      {accessories.map((accessory, index) => {
        return (
          <Box key={index}>
            <Box justify="center">
              <Heading margin="none" level={5} size="xsmall" truncate>
                {accessory?.name}
              </Heading>
            </Box>
            {/* <Box justify="center" align="end">
              <Text className="bold" size="xsmall">
                {`£${accessory?.cost}`}
              </Text>
            </Box> */}
          </Box>
        )
      })}
      {/* <Box direction="row" justify="between">
        <Heading
          margin={{ vertical: 'none', top: 'medium' }}
          level={5}
          color="black"
          className="uppercase"
        >
          Accessories Total
        </Heading>

        <Heading
          margin={{ vertical: 'none', top: 'medium' }}
          level={4}
          color="black"
          className="uppercase"
        >
          {`£${total}`}
        </Heading>
      </Box> */}
    </Box>
  )
}

const OrderTable = ({ orders }) => {
  const { editableRegions } = useEditableRegions('Sidebar')
  const { isMobile, isDesktop } = useResponsive()
  const { path, name } = subscriptionAPI


  console.log("orders", orders);
  return (
    <>
      {orders ? (
        orders.map(
          ({ bike, fields, id, accessories, orderType, recycle }, index) => {

            console.log("recycle", recycle);
            const confirmSubscription = async isSuccess => {
              console.log('confirming this subscription...')
              API.post(name, `${path}/confirmation`, {
                body: {
                  subscriptionId: id,
                  // cognitoSub,
                  paymentStatus: isSuccess ? 'success' : 'fail',
                },
              }).catch(error => {
                alert('There was an issue with your direct debit payment')
                console.log(error)
              })
            }

            const onSucess = async response => {
              console.log('THIS FIRES')
              await confirmSubscription(true)
              navigate(
                `/orderconfirmation/${fields['Personal Email']}?orderno=00${fields['Quote Number']}&id=${fields['RecordID']}`
              )
            }

            const onError = response => {
              confirmSubscription(false)
            }

            return (
              <Box flex={true} direction={'column'}>
                <Box direction="row" gap="40px">
                  {isDesktop ? <Number number={1} /> : null}
                  <Box pad={{ bottom: 'medium' }} flex={'grow'}>
                    <>
                      <Box direction="row" justify="between" align="center">
                        <Heading
                          margin={{ top: 'none', bottom: 'small' }}
                          level={3}
                        >
                          {'Order Summary'}
                        </Heading>
                      </Box>

                      <Rule margin={{ top: 'none', bottom: 'small' }} />
                    </>
                  </Box>
                </Box>

                <Box fill="horizontal" pad={{ left: isMobile ? null : '90px' }}>
                  <Box direction="row" gap="small" justify="between">
                    <Heading
                      margin="none"
                      level={4}
                      color="brand"
                      className="uppercase"
                    >
                      {`Order`}
                    </Heading>
                    <Heading
                      margin="none"
                      level={5}
                      color="black"
                      className="uppercase"
                    >
                      {`${fields['Display Quote Number']}`}
                    </Heading>
                  </Box>
                  <Rule margin={{ top: 'xsmall', bottom: 'small' }} />

                  <Box justify="between" align="start" direction="row">
                    <Heading
                      color="brand"
                      margin="none"
                      level={4}
                      margin="none"
                    >
                      STATUS
                    </Heading>
                    <Heading
                      margin="none"
                      level={5}
                      color="black"
                      className="uppercase"
                    >
                      {fields['Status Message']}
                    </Heading>
                  </Box>
                  <Rule margin={{ top: 'xsmall', bottom: 'small' }} />
                  {fields['Credit Band'] === '1' && (
                    <Box justify="between" align="start" direction="row">
                      <Heading color="brand" margin="none" level={4}>
                        ELIGIBILITY CHECK
                      </Heading>
                      <Heading
                        margin="none"
                        level={5}
                        color="red"
                        className="uppercase"
                      >
                        Failed
                      </Heading>
                    </Box>
                  )}

                  <SelectedHeader header={'Bike'} readOnly={true} />
                  <SelectedBike
                    step={1}
                    displayName={bike['Display Name']}
                    brand={bike['Brands']}
                    model={bike['Model']}
                    image={null}
                    size={fields['Size']}
                    color={fields['Colour']}
                    recycle={recycle}
                    includedAccessories={bike['Included Accessories']}
                  />
                  <AccessoriesHeader step={1} readOnly={true} />
                  <AccessoriesSummary accessories={accessories} />

                  <SelectedPlanHeader />
                  {orderType === 'order' ? (
                    <SelectedPlan
                      step={null}
                      length={fields['Plan Months']}
                      net={fields['Net'].toFixed(2)}
                      stepName={null}
                      terms={parser(editableRegions['Footer Terms'])}
                    />
                  ) : (
                    <SelectedPlanSubscription
                      price={parseFloat(fields['Monthly Cost']).toFixed(2)}
                      ownership={(bike['RRP'] * 0.12).toFixed(2)}
                      startDate={bike['Lead time']}
                    />
                  )}
                </Box>

                <Box direction="row" gap="40px" margin={{ top: '50px' }}>
                  {isDesktop ? <Number number={2} /> : null}
                  <Box pad={{ bottom: 'medium' }} flex={'grow'}>
                    <Box direction="row" justify="between" align="center">
                      <Heading
                        margin={{ top: 'none', bottom: 'small' }}
                        level={3}
                      >
                        {'Direct Debit'}
                      </Heading>
                    </Box>

                    <Rule margin={{ top: 'none', bottom: 'none' }} />
                  </Box>
                </Box>
                <Box fill="horizontal" pad={{ left: isMobile ? null : '90px' }}>
                  <Paragraph margin={{ top: 'none', bottom: 'medium' }}>
                    {`To complete your order please pay the £99 joining fee and set up your Direct Debit.
                  We will deduct your first instalment of £${fields['Monthly Cost']} when your bike is delivered.`}
                  </Paragraph>
                  <Box justify="between" align="start" direction="row">
                    <GoCardless
                      onSuccess={onSucess}
                      onError={onError}
                      agree={true}
                      value={parseFloat(fields['Monthly Cost'])}
                    />
                  </Box>
                </Box>
              </Box>
            )
          }
        )
      ) : (
        <Box align="center" flex fill justify="center" height={{ min: '80vh' }}>
          <Text>Fetching your orders...</Text>
          <Box pad="large">
            <ClipLoader
              color={'#03E272'}
              loading={true}
              size={40}
              speedMultiplier={0.5}
            />
          </Box>
        </Box>
      )}
    </>
  )
}

const Orders = ({ orderId }) => {
  const [orders, setOrders] = useState(false)

  const fetchOrders = async () => {
    API.get(name, `/retrieve/${orderId}`)
      .then(({ subscriptions }) => {
        let mappedSubs = subscriptions.map(item => {
          return {
            ...item,
            orderType: 'subscription',
          }
        })

        setOrders([...mappedSubs])
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    fetchOrders()
  }, [])

  return (
    <Box>
      {!orders ? (
        <Box align="center">
          <Paragraph>Fetching order...</Paragraph>
        </Box>
      ) : orders.length > 0 ? (
        <OrderTable orders={orders} fetchOrders={fetchOrders} />
      ) : (
        <Box align="center">
          <Paragraph>You have no current orders</Paragraph>
        </Box>
      )}
    </Box>
  )
}

const RetrievelPage = ({ orderId }) => {
  const { isDesktop, isMobile } = useResponsive()
  const { check, id } = useSelector(state => state.order)
  const [payment, setStage] = useState(false)

  useEffect(() => {
    // gaEvent('select-plan');
    // fbEvent('InitiateCheckout');
    // dispatch(clearOrders())
  }, [])

  return (
    <Box
      direction="column"
      justify="start"
      flex={false}
      fill
      margin={{ bottom: 'large' }}
      pad={{ horizontal: 'large' }}
    >
      <Grid columns={isMobile ? ['auto'] : ['350px', 'flex']}>
        {isDesktop && <Box id="boundary"></Box>}
        {isMobile && <Box flex>{/* <MobileTopBar /> */}</Box>}
        <Box flex>
          <Orders orderId={orderId} />
        </Box>
      </Grid>
    </Box>
  )
}

export default RetrievelPage
