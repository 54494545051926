import React, { useEffect, useState, useContext, useRef } from 'react'
import {
  Box,
  Heading,
  Grid,
  Anchor,
  FormField,
  Text,
  Image,
  TextInput,
  Form,
  Button,
  Paragraph,
  Table,
  TableCell,
  TableHeader,
  Spinner,
} from 'grommet'
import { useResponsive } from '../../../hooks'
import styled from 'styled-components'

import Sidebar from '../../Components/Sidebar'
import ProgressBar from '../../Components/ProgressBar'
import Rule from '../../Components/Rule'
import { selectedSalary, selectedPlan } from '../../../state/selection'
import { useDispatch, useSelector } from 'react-redux'

import { fetchQuote, resetQuote } from '../../../state/quote'
import { navigate } from 'gatsby'
import Sticky from 'react-sticky-el'
import { QuoteTable } from './QuoteTable'
import MobileTopBar from '../../Components/Sidebar/MobileTopBar'
import { numberWithCommas, fbEvent, gaEvent } from '../../../helpers'
import { showError } from '../../../state/ui'

const Bike = () => {
  const { isDesktop, isMobile } = useResponsive()
  const dispatch = useDispatch()
  const quote = useSelector(state => state.quote)
  const salary = useSelector(state => state.selection.salary)
  const [loading, setLoading] = useState(false)
  const selected = useSelector(state => state.selection)
  const [value, setValue] = useState({ salary: '' })

  useEffect(()=>{
    dispatch(fetchQuote())
  },[salary]);

  const onSubmit = () => {
    setLoading(true)
    if ((value.salary - selected.selectedBike.price - selected.accessoriesTotal) < 16216) {
      dispatch(
        showError({
          title: 'National Living Wage Requirement',
          message: "Your annual salary minus the cost of your bike needs to be at least £16,216 to qualify for a plan.",
          type: null,
        })
      )
      setLoading(false)
    } else {
      dispatch(selectedSalary(value.salary))
      dispatch(fetchQuote())
    }
  }

  useEffect(() => {
    !quote.loading && setLoading(false)
  }, [quote])

  const selectPlan = timeLength => {
    dispatch(
      selectedPlan({
        plan: quote[timeLength],
        length: timeLength,
      })
    )
    navigate('/register')
  }

  const clearPlan = () => {
    dispatch(resetQuote())
    dispatch(selectedSalary(null))
  }

  return (
    <>
      <Box
        direction="column"
        justify="start"
        margin={{ bottom: 'large' }}
        pad={{ horizontal: 'large' }}
      >
        <Grid
          columns={isMobile ? ['flex'] : ['350px', 'flex']}
          gap={{ column: 'large' }}
        >
          {isDesktop && (
            <Box id="boundary">
              <Sticky
                mode="top"
                positionRecheckInterval={1}
                boundaryElement="#boundary"
              >
                <Box fill>
                  <Sidebar step="plan" />
                </Box>
              </Sticky>
            </Box>
          )}

          {isMobile && (
            <Box id="boundary">
              <MobileTopBar />
            </Box>
          )}

          <Box animate={'fadeIn'} direction="column" flex>
            <Box>
              <Box direction="row" justify="between">
                <Heading margin={{ top: 'none', bottom: 'small' }} level={3}>
                  {salary ? 'Select Your Plan' : 'Calculate Your Plan'}
                </Heading>
                {isDesktop && salary && (
                  <Box align="end" direction="row" gap="small" align="center">
                    <Paragraph margin="none">{`Salary: £${numberWithCommas(
                      parseInt(salary).toFixed(0)
                    )}`}</Paragraph>

                    <a href="#" onClick={clearPlan} style={{ lineHeight: 1 }}>
                      change
                    </a>
                  </Box>
                )}
              </Box>
              <Rule margin={{ top: 'none', bottom: 'small' }} />
              {salary ? (
                !quote[12] ? (
                  <Box align="center" pad="large">
                    <Spinner />
                  </Box>
                ) : (
                  <Box>
                    <QuoteTable quote={quote} selectPlan={selectPlan} />
                    {isMobile && (
                      <Paragraph>{`Based on your salary of £${numberWithCommas(
                        parseInt(salary).toFixed(0)
                      )}`}</Paragraph>
                    )}
                  </Box>
                )
              ) : (
                <>
                  <Paragraph>
                    Enter your annual salary and we'll calculate your plan for you.
                  </Paragraph>
                  <Form
                    value={value}
                    onChange={nextValue => {
                      return setValue(nextValue)
                    }}
                    onReset={() => {
                      return setValue()
                    }}
                    onSubmit={onSubmit}
                  >
                    <Box flex="shrink" width={{ max: '350px' }} gap="small">
                      <FormField
                        fill
                        required
                        htmlFor={'salary'}
                        name={'salary'}
                        margin="none"
                      >
                        <TextInput
                          type="number"
                          placeholder={'£'}
                          plain={false}
                          id={'salary'}
                          name={'salary'}
                          margin="none"
                        />
                      </FormField>
                      <Button
                        fill
                        type="submit"
                        primary
                        // loading
                        size={'large'}
                        disabled={loading}
                        icon={
                          loading ? (
                            <Spinner
                              style={{ margin: '0 auto' }}
                              alignSelf="center"
                              size="xsmall"
                            />
                          ) : null
                        }
                        label={loading ? null : 'Calculate'}
                      />
                    </Box>
                  </Form>
                  <Paragraph>
                    <Anchor
                      margin={{ vertical: 'medium' }}
                      className="smallprint"
                      href="/privacy-policy/"
                      label="Blike Privacy Policy"
                    ></Anchor>
                  </Paragraph>
                </>
              )}
            </Box>
          </Box>
        </Grid>
      </Box>
    </>
  )
}

export default Bike
