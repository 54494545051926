import React, { useState } from 'react'
import { Box, Heading, Layer, Button } from 'grommet'
import parser from 'html-react-parser'
import { CircleInformation, Close } from 'grommet-icons'
import styled from 'styled-components'
import { useResponsive } from '../../hooks'

const StyledBox = styled(Box)`
  padding-top: '120px';
  p {
    font-size: 14px;
  }
`

export const InfoPoint = ({ info }) => {
  const [show, setShow] = useState()
  const { isMobile } = useResponsive()

  return (
    <Box margin={{ left: '10px' }} justify="center" align="center">
      <Button
        plain
        style={{ lineHeight: 1 }}
        icon={<CircleInformation size="20px" color="brand" />}
        onClick={() => setShow(true)}
      />
      {show && (
        <Layer
          modal
          responsive={false}
          margin="medium"
          onEsc={() => setShow(false)}
          onClickOutside={() => setShow(false)}
          full={isMobile ? 'horizontal' : false}
        >
          <Box
            flex
            margin="medium"
            style={{ overflowY: 'scroll' }}
            pad={{ top: isMobile ? 'large' : 'small' }}
          >
            <Button
              plain
              icon={<Close />}
              onClick={() => setShow(false)}
              style={{ position: 'absolute', right: 20, top: 10 }}
            />
            <StyledBox
              className="editable"
              pad={'medium'}
              isMobile={isMobile}
              flex
            >
              {info.title && (
                <Box
                  pad={{ vertical: 'small' }}
                  align={'center'}
                  flex="grow"
                  alignSelf={'center'}
                >
                  <Heading
                    responsive={false}
                    level={3}
                    size={isMobile ? 'small' : 'medium'}
                    textAlign="center"
                    alignSelf={'center'}
                    fill={false}
                    margin={{
                      bottom: 'medium',
                      top: 'none',
                      horizontal: 'auto',
                    }}
                  >
                    {parser(info.title)}
                  </Heading>
                  <Box
                    flex
                    alignSelf={'center'}
                    width={'25%'}
                    border={{ color: 'brand', bottom: { size: '1px' } }}
                  />
                </Box>
              )}
              <Box flex style={{ overflow: 'hidden' }}>
                <Box fill style={{ overflowY: 'scroll' }}>
                  {parser(info.content)}
                </Box>
              </Box>
            </StyledBox>
          </Box>
        </Layer>
      )}
    </Box>
  )
}
