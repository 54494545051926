import {
    Box, Paragraph
} from 'grommet'
import React from 'react'

const Number = ({ number, complete = true }) => {
  return (
    <Box
      border={{ color: complete ? 'brand-third' : 'brand', size: '2px' }}
      round="full"
      width={{ min: '50px', max: '50px' }}
      height="50px"
      align="center"
      shrink={false}
      justify="center"
      background={complete ? 'brand-third' : null}
    >
      <Paragraph
        style={{ fontSize: 25, textAlign: 'center' }}
        color={complete ? 'brand-secondary' : 'brand'}
      >
        {number}
      </Paragraph>
    </Box>
  )
}

export default Number
