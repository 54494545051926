import API from '@aws-amplify/api'
import {
  Box,
  Button,
  CheckBox,
  Image,
  Form,
  Heading,
  Paragraph,
  Spinner,
  Text,
  Grid,
  FormField,
  RadioButtonGroup,
} from 'grommet'
import parser, { domToReact } from 'html-react-parser'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import TitleSwoosh from '../../assets/vectors/gradient-swoosh.svg'
import { contactsAPI } from '../../config'
import { useEditableRegions, useResponsive } from '../../hooks'
import { showError } from '../../state/ui'
import Rule from '../Components/Rule'
import { FormTextInput } from '../Forms'
import { useLocation, navigate } from '@reach/router'
import styled from 'styled-components'

function useUrlUpdate() {
  const urlUpdate = url => {
    if (typeof window !== `undefined` && typeof url !== `undefined`) {
      console.log('url', url)
      window.history.pushState({}, '', url)
    }
  }

  return { urlUpdate }
}

const bikeGrid = {
  vanmoof: {
    name: 'VanMoof S3 & X3',
    image: 'https://blike.imgix.net/grid/vanmoof/s3/default.jpg?w=600',
    offer: '£88 per month',
    groupImages: [
      {
        name: 'Vanmoof S3',
        image: 'https://blike.imgix.net/grid/vanmoof/s3/default.jpg?w=600',
      },
      {
        name: 'Vanmoof X3',
        image: 'https://blike.imgix.net/grid/vanmoof/x3/default.jpg?w=600',
      },
    ],
  },
  ampler1: {
    name: 'Ampler Stout & Stellar',
    image: 'https://blike.imgix.net/grid/ampler/stellar/default.jpg?w=600',
    offer: '£96 per month',
    groupImages: [
      {
        name: 'Ampler Stellar',
        image: 'https://blike.imgix.net/grid/ampler/stellar/default.jpg?w=600',
      },
      {
        name: 'Ampler Stout',
        image: 'https://blike.imgix.net/grid/ampler/stout/default.jpg?w=600',
      },
    ],
  },
  ampler2: {
    name: 'Ampler Curt SS & Curt 11',
    image: 'https://blike.imgix.net/grid/ampler/curtss/default.jpg?w=600',
    offer: '£112 per month',
    groupImages: [
      {
        name: 'Ampler Curt',
        image: 'https://blike.imgix.net/grid/ampler/curt11/default.jpg?w=600',
      },
      {
        name: 'Ampler SS',
        image: 'https://blike.imgix.net/grid/ampler/curtss/default.jpg?w=600',
      },
    ],
  },
  cowboy: {
    name: 'Cowboy 4 & 4ST',
    image: 'https://blike.imgix.net/grid/cowboy/4/default.jpg?w=600',
    offer: '£108 per month',
    groupImages: [
      {
        name: 'Cowboy 4',
        image: 'https://blike.imgix.net/grid/cowboy/4/default.jpg?w=600',
      },
      {
        name: 'Cowboy 4ST',
        image: 'https://blike.imgix.net/grid/cowboy/4st/default.jpg?w=600',
      },
    ],
  },
  mate: {
    name: 'Mate X',
    image: 'https://blike.imgix.net/grid/mate/x/default.jpg?w=600',
    offer: '£108 per month',
    groupImages: [
      {
        name: 'Mate X',
        image: 'https://blike.imgix.net/grid/mate/x/default.jpg?w=600',
      },
    ],
  },
  radpower: {
    name: 'RadPower RadWagon',
    image: 'https://blike.imgix.net/grid/radpower/radwagon/default.jpg?w=600',
    offer: '£80 per month',
    groupImages: [
      {
        name: 'RadPower RadWagon',
        image:
          'https://blike.imgix.net/grid/radpower/radwagon/default.jpg?w=600',
      },
    ],
  },
  velobike: {
    name: 'VeloBike Bike+',
    image: 'https://blike.imgix.net/grid/vello/bikeplus/default.jpg?w=600',
    offer: '£115 per month',
    groupImages: [
      {
        name: 'VeloBike Bike+',
        image: 'https://blike.imgix.net/grid/vello/bikeplus/default.jpg?w=600',
      },
    ],
  },
  voltbikes: {
    name: 'Volt Burlington & Pulse',
    image: 'https://blike.imgix.net/grid/volt/pulse/default.jpg?w=600',
    offer: '£86 per month',
    groupImages: [
      {
        name: 'Volt Burlington',
        image: 'https://blike.imgix.net/grid/volt/burlington/default.jpg?w=600',
      },
      {
        name: 'Volt Pulse',
        image: 'https://blike.imgix.net/grid/volt/pulse/default.jpg?w=600',
      },
    ],
  },
}

const GridItem = ({
  top = false,
  right = false,
  noBorder = false,
  bike: { name, image },
  centered = false,
}) => {
  const { isDesktop, isMobile } = useResponsive()

  return (
    <Box
      pad={{
        top: top ? 'none' : 'medium',
        horizontal: 'medium',
        bottom: 'medium',
      }}
      style={{ position: 'relative' }}
    >
      {!right && (
        <Box
          style={{
            width: 1,
            position: 'absolute',
            right: 0,
            height: '80%',
            top: '10%',
            borderLeft:
              noBorder || isMobile ? null : '1px solid rgba(124,124,124,0.15)',
          }}
        />
      )}
      <Box
        style={{
          height: 1,
          position: 'absolute',
          bottom: 0,
          width: '80%',
          left: '10%',
          borderBottom: noBorder ? null : '1px solid rgba(124,124,124,0.15)',
        }}
      />
      <Box height="200px">
        {image && <Image fill fit={'contain'} src={image} />}
      </Box>

      <Box fill style={{ overflow: 'hidden' }}>
        <Paragraph
          fill="horizontal"
          size="medium"
          className="bold"
          style={{
            whiteSpace: 'nowrap',
            textAlign: centered ? 'center' : null,
          }}
          margin={{ top: 'medium', bottom: 'xsmall' }}
        >
          {`${name}`}
        </Paragraph>
      </Box>
    </Box>
  )
}

const defaultValue = {
  email: '',
  firstname: '',
  lastname: '',
  marketing: false,
}

const BikeGrid = ({ setSelectedBike, selectedBike }) => {
  const { isDesktop, isMobile } = useResponsive()

  return (
    <Grid
      margin={{ top: 'large' }}
      columns={{
        count: isDesktop ? 3 : 1,
        size: '33%',
      }}
    >
      {Object.keys(bikeGrid).map((bike, key) => {
        return (
          <a
            href="#"
            key={key}
            className="bike-item"
            onClick={e => setSelectedBike(bike)}
          >
            <Box margin={{ vertical: isMobile ? 'medium' : 'none' }}>
              <GridItem
                bike={bikeGrid[bike]}
                top={key + 1 <= 3 ? true : false}
                right={(key + 1) % 3 === 0 ? true : false}
              />
            </Box>
          </a>
        )
      })}
    </Grid>
  )
}

const LightHeading = styled(Heading)`
  font-family: 'Circular Std Book';
`

const IntroText = ({ selectedBike }) => {
  const { editableRegions } = useEditableRegions('Cycleshow Offers')

  return (
    <>
      <Box
        direction="column"
        fill="horizontal"
        justify="center"
        align="center"
        gap="small"
      >
        <Heading
          margin={{ top: 'none', bottom: 'none', right: 'small' }}
          level={2}
          textAlign="center"
          alignSelf="center"
          color="brand"
        >
          {`Special Cycle Show Offer`}
        </Heading>
        <Heading
          margin={{ top: 'none', bottom: 'none', right: 'small' }}
          level={1}
          size="large"
          textAlign="center"
          alignSelf="center"
        >
          {bikeGrid[selectedBike].offer}
        </Heading>
        {editableRegions[`offer`] &&
          parser(editableRegions[`offer`], {
            replace: ({ name, children }) => {
              if (name === 'p') {
                return (
                  <>
                    <LightHeading
                      margin={{ top: 'none', bottom: 'none' }}
                      level={3}
                      size="small"
                      textAlign="center"
                      alignSelf="center"
                    >
                      {domToReact(children)}{' '}
                    </LightHeading>
                  </>
                )
              }
            },
          })}

        {editableRegions[`bullets`] &&
          parser(editableRegions[`bullets`], {
            replace: ({ name, children }) => {
              if (name === 'p') {
                return (
                  <>
                    <LightHeading
                      margin={{ top: 'none', bottom: 'none' }}
                      level={5}
                      fill="horizontal"
                      size="small"
                      textAlign="center"
                      alignSelf="center"
                    >
                      {domToReact(children)}{' '}
                    </LightHeading>
                  </>
                )
              }
            },
          })}
      </Box>

      {editableRegions[`cycletowork`] &&
        parser(editableRegions[`cycletowork`], {
          replace: ({ name, children }) => {
            if (name === 'p') {
              return (
                <Box
                  direction="column"
                  fill="horizontal"
                  justify="center"
                  align="center"
                >
                  <Heading
                    margin={{ top: 'large', bottom: 'small', right: 'small' }}
                    level={2}
                    textAlign="center"
                    alignSelf="center"
                    color="brand"
                  >
                    {`Or get 32-47% off with Cycle To Work`}
                  </Heading>
                  <LightHeading
                    margin={{ top: 'none', bottom: 'none' }}
                    level={5}
                    fill="horizontal"
                    size="small"
                    textAlign="center"
                    alignSelf="center"
                  >
                    {domToReact(children)}{' '}
                  </LightHeading>
                </Box>
              )
            }
          },
        })}
      <Heading
        style={{ fontWeight: 'normal' }}
        margin={{ top: 'large', bottom: 'none' }}
        level={4}
        textAlign="center"
        alignSelf="center"
      >
        {'Enter your details below to receive the offer'}
      </Heading>
      <Box
        border={{ side: 'bottom', size: '2px', color: 'brand' }}
        width="200px"
        alignSelf="center"
        margin={{ vertical: 'medium' }}
      ></Box>
    </>
  )
}

const EnquiryForm = ({ selectedBike }) => {
  const { isDesktop, isMobile } = useResponsive()
  const [value, setValue] = useState(defaultValue)
  const { name, path } = contactsAPI
  const dispatch = useDispatch()
  const [submitting, setSubmitting] = useState(false)
  const [success, setSuccess] = useState(false)

  const onSubmit = async () => {
    setSubmitting(true)

    const { email, firstname, lastname, interested } = value || {}

    API.post(name, `${path}`, {
      body: {
        contact: {
          firstname,
          lastname,
          email,
          lead_source: 'Cycle Show',
          hs_lead_status: '1. Engaged - Company unsigned',
          bike: selectedBike,
          interested,
        },
      },
    })
      .then(response => {
        if (response.success) {
          setSuccess(true)
        }
      })
      .catch(err => {
        console.log(err)
        dispatch(
          showError({
            title: 'Error',
            message: 'There was a problem submitting the form.',
            type: null,
          })
        )
        setSubmitting(false)
        return
      })
  }

  return success ? (
    <Box pad="large">
      <Heading
        level={2}
        margin="xsmall"
        textAlign="center"
        alignSelf="center"
        color="black"
      >
        {`Thank you. We'll be in touch shortly.`}
      </Heading>
    </Box>
  ) : (
    <>
      <Box
        direction="column"
        justify="start"
        flex={false}
        pad={{ horizontal: isDesktop ? 'large' : null }}
        margin={{ bottom: 'large' }}
      >
        <Box fill gap="xlarge">
          <Box>
            <Form
              value={value}
              onChange={nextValue => setValue(nextValue)}
              onReset={() => setValue({})}
              onSubmit={onSubmit}
            >
              <Heading margin={{ top: 'large', bottom: 'small' }} level={3}>
                Your Contact Details
              </Heading>
              {/* <ContactAdd contacts={contacts} setContacts={setContacts} /> */}

              <Box
                fill="horizontal"
                flex={isDesktop ? true : false}
                direction={isMobile ? 'column' : 'row'}
                gap="medium"
              >
                <FormTextInput name={`firstname`} placeholder={`First Name`} />
                <FormTextInput name={`lastname`} placeholder={`Last Name`} />
              </Box>

              <Box
                fill="horizontal"
                flex={isDesktop ? true : false}
                direction={isMobile ? 'column' : 'row'}
                gap="medium"
              >
                <FormTextInput name={`email`} placeholder={`Email Address`} />
              </Box>

              <Heading margin={{ top: 'large', bottom: 'small' }} level={3}>
                I'm interested in...
              </Heading>
              <Rule margin={{ top: 'none', bottom: 'small' }} />
              {/* <ContactAdd contacts={contacts} setContacts={setContacts} /> */}

              <Box
                fill="horizontal"
                flex={isDesktop ? true : false}
                direction={isMobile ? 'column' : 'row'}
                gap="medium"
              >
                <FormField name="interested" required>
                  <RadioButtonGroup
                    style={{ borderColor: 'black' }}
                    name="interested"
                    options={[
                      {
                        label: `Personal subscription (including self employed)`,
                        value: 'subscription',
                      },
                      {
                        label: `Cycle-to-Work scheme (employer or employee)`,
                        value: 'ctw',
                      },
                      { label: `Company plan`, value: 'plan' },
                    ]}
                  />
                </FormField>
              </Box>

              <Box
                fill="horizontal"
                flex={isDesktop ? true : false}
                direction={isMobile ? 'column' : 'row'}
                gap="medium"
                margin={{ top: '50px' }}
              >
                <Box flex="shrink" align="center">
                  <Button
                    style={{ width: 250, textAlign: 'center' }}
                    disabled={submitting}
                    justify="center"
                    type="submit"
                    icon={
                      submitting ? (
                        <Spinner
                          style={{ margin: '0 auto' }}
                          alignSelf="center"
                          size="xsmall"
                        />
                      ) : null
                    }
                    alignSelf="start"
                    primary
                    size="large"
                    label={submitting ? null : 'Submit Details'}
                  />
                </Box>
                <Box flex basis="1/2"></Box>
              </Box>
            </Form>
          </Box>
        </Box>
      </Box>
    </>
  )
}

const BikeImage = ({ selectedBike }) => {
  const { isDesktop, isMobile } = useResponsive()

  return (
    <Grid
      margin={{ top: 'large' }}
      columns={{
        count: isDesktop ? bikeGrid[selectedBike]?.groupImages.length : 1,
        size: '33%',
      }}
    >
      {bikeGrid[selectedBike]?.groupImages?.map((item, key) => {
        return (
          <a
            href="#"
            key={key}
            className="bike-item"
            // onClick={e => setSelectedBike(bike)}
          >
            <Box margin={{ vertical: isMobile ? 'medium' : 'none' }}>
              <GridItem bike={item} noBorder centered />
            </Box>
          </a>
        )
      })}
    </Grid>
  )
}

const AboutForm = ({ brand }) => {
  const [selectedBike, setSelectedBike] = useState(null)
  const { isDesktop, isMobile } = useResponsive()

  useEffect(() => {
    setSelectedBike(brand)
  }, [brand])

  useEffect(() => {
    !brand && navigate(`/cycleshow/${selectedBike || ''}`)
  }, [selectedBike, brand])

  return (
    <Box style={{ position: 'relative' }} flex="grow">
      <>
        <Box
          justify="center"
          fill="horizontal"
          pad={{ horizontal: isDesktop ? 'xlarge' : 'large' }}
          margin={{ bottom: 'large' }}
        >
          <Box style={{ position: 'relative' }} justify="center" align="center">
            <TitleSwoosh
              style={{
                position: 'absolute',
                top: '15%',
                bottom: 0,
                margin: 'auto 0',
                zIndex: -1,
              }}
              height="80%"
            />

            <Heading
              textAlign="center"
              style={{ maxWidth: '680px' }}
              alignSelf="center"
              level={2}
              size="xlarge"
              margin={{ vertical: 'xlarge' }}
            >
              {`Blike @ Cycle Show`}
            </Heading>
          </Box>

          {!selectedBike ? (
            <>
              <Heading
                margin={{ top: 'large', bottom: 'meidum' }}
                level={2}
                textAlign="center"
                alignSelf="center"
              >
                {'Which bike are you interested in?'}
              </Heading>
              <BikeGrid
                setSelectedBike={setSelectedBike}
                selectedBike={selectedBike}
              />
            </>
          ) : (
            <>
              <BikeImage selectedBike={selectedBike} />
              <IntroText selectedBike={selectedBike} />
              <EnquiryForm selectedBike={selectedBike} />
            </>
          )}
        </Box>
      </>
    </Box>
  )
}

export default AboutForm
