import React, { useEffect } from 'react'
import { Box } from 'grommet'
import { useResponsive } from '../../hooks'

const About = () => {
  const { isDesktop } = useResponsive()

  useEffect(() => {}, [])

  return (
    <Box margin={{horizontal: "large"}} height="60vh">
     <iframe
        title="Investor Report"
        width="100%"
        height="100%"
        src="https://app.powerbi.com/reportEmbed?reportId=56deef1c-5fc4-4117-9ca4-56ddc6f034ec&appId=5bdb4dd3-72cf-4fd7-ae3d-2c7c35f59a98&autoAuth=true&ctid=8b43594c-e2f8-40e2-a357-802c6082c428&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVrLXNvdXRoLWMtcHJpbWFyeS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D"
        frameborder="0"
        allowFullScreen="true"
      ></iframe>
    </Box>
  )
}

export default About
