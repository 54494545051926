import React, { useState } from 'react'
import { FormLock, View } from 'grommet-icons'

import {
  Box,
  Button,
  CheckBox,
  Form,
  Heading,
  Paragraph,
  Grommet,
  MaskedInput,
  RadioButtonGroup,
  RangeInput,
  Select,
  TextArea,
  TextInput,
} from 'grommet'

export function AuthForm({ children, title, error }) {
  return (
    <Box
      flex="shrink"
      margin="medium"
      pad="large"
      round="small"
      align="center"
      alignSelf="center"
      justify="center"
      background="white"
      elevation={'medium'}
    >
      <Form>
        <Heading
          level={2}
          size="medium"
          margin={{ top: 'none', bottom: 'medium' }}
        >
          {title}
        </Heading>
        {error && (
          <Paragraph color="status-critical">{error.message ? error.message : error}</Paragraph>
        )}
        <Box gap="medium">{children}</Box>
      </Form>
    </Box>
  )
}

export function Email({ handleUpdate, email, autoComplete }) {
  return (
    <>
      <TextInput
        onChange={handleUpdate}
        name="email"
        type="email"
        value={email}
        className="form-control"
        autoComplete={autoComplete}
        id="enterEmailAddress"
        aria-describedby="emailHelp"
        placeholder="Enter email"
      />
    </>
  )
}

export function Password({ handleUpdate, password, autoComplete, confirm = false }) {
  const [confirmReveal, setConfirmReveal] = useState(false)
  return (
    <Box direction="row" style={{position: "relative"}}>
      <TextInput
        onChange={handleUpdate}
        autoComplete={autoComplete}
        name={confirm ? "confirmPassword" : "password"}
        value={password}
        type={confirmReveal ? "text" : "password"}
        className="form-control"
        placeholder={confirm ? "Confirm Password" : "Password"}
        id={confirm ? "confirmPassword" : "enterPassword"}
      />
      <Button
        style={{ position: 'absolute', right: 0, top: 10 }}
        icon={confirmReveal ? <FormLock size="21px" /> : <View size="17px" />}
        onClick={() => setConfirmReveal(!confirmReveal)}
      />
    </Box>
  )
}

export function ConfirmPassword({
  handleUpdate,
  confirmPassword,
  autoComplete,
}) {
  const [reveal, setReveal] = useState(false)

  return (
    <Box direction="row" style={{position: "relative"}}>
      <TextInput
        onChange={handleUpdate}
        autoComplete={autoComplete}
        name="confirmPassword"
        value={confirmPassword}
        type={reveal ? "text" : "password"}
        className="form-control"
        placeholder="Confirm Password"
        id="confirmPassword"
      />
      <Button
        style={{ position: 'absolute', right: 0, top: 3 }}
        icon={reveal ? <FormLock size="21px" /> : <View size="17px" />}
        onClick={() => setReveal(!reveal)}
      />
    </Box>
  )
}

export function ConfirmationCode({ handleUpdate, auth_code, autoComplete }) {
  return (
    <>
      <TextInput
        onChange={handleUpdate}
        autoComplete={autoComplete}
        name="auth_code"
        value={auth_code}
        type="text"
        className="form-control"
        placeholder="Verification Code #####"
        id="enterCode"
      />
    </>
  )
}
