import { Box, Button, Accordion, AccordionPanel } from 'grommet'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Close } from 'grommet-icons'
import {
  useResponsive,
  useCheckoutPhase,
  useEditableRegions,
} from '../../../hooks'
import { Accessories, AccessoriesHeader } from './Accessories'
import {
  SelectedPlan,
  SelectedPlanHeader,
  SelectedPlanSubscription,
} from './SelectedPlan'
import { SelectedBike, SelectedHeader } from './SelectedBike'
import { useSelector } from 'react-redux'
import parser from 'html-react-parser'
import { discountSelector } from '../../../state/discount';

const Sidebar = ({ disabled = false, mobileMenuToggle }) => {
  const { isMobile } = useResponsive()
  const [active, setActive] = useState(-1)

  //SELECTED BIKE
  const image = useSelector(state => state.selection.selectedBike.image)
  const selectedId = useSelector(state => state.selection.selectedBike.model)
  const bikes = useSelector(state => state.bikes.models.byId)
  const { purchaseType } = useSelector(state => state.ui)
  const discount = useSelector(discountSelector);

  //ACCESSORIES
  const { step, stepName } = useCheckoutPhase()
  const bikesById = useSelector(state => state.bikes.models.byId)
  const accessoryTotal = useSelector(state => state.selection.accessoriesTotal)
  const selectedAccessories = useSelector(
    state => state.selection.selectedAccessories
  )

  const { model, size, color, includedAccessories } = useSelector(
    state => state.selection.selectedBike
  )
  const { accessoriesMonthlyTotal, accessoriesTotal } = useSelector(
    state => state.selection
  )
  const selectedPlan = useSelector(state => state.selection.selectedPlan)
  const { editableRegions } = useEditableRegions('Sidebar')

  const onActive = ([index]) => {
    setActive(index)
  }

  return (
    <Box
      flex
      pad={{
        right: isMobile ? 'none' : 'large',
        top: 'small',
        bottom: 'large',
      }}
      border={
        isMobile
          ? null
          : {
            color: 'border',
            size: '1px',
            style: 'solid',
            side: 'right',
          }
      }
    >
      <Accordion onActive={onActive}>
        <AccordionPanel
          header={
            <SelectedHeader
              mobile
              active={active === 0}
              purchaseType={purchaseType}
              subPrice={bikes[selectedId].subPrice}
              rrp={bikes[selectedId].RRP}
            />
          }
        >
          <Box pad={{ vertical: 'medium' }} flex="grow">
            <SelectedBike
              step={step}
              mobile
              displayName={bikes[selectedId]['Display Name']}
              brand={bikes[selectedId].brand}
              model={bikes[selectedId].Model}
              recycle={bikes[selectedId].recycle}
              image={image}
              size={size}
              color={color}
              active={active === 0}
              includedAccessories={includedAccessories}
            />
          </Box>
        </AccordionPanel>
        <AccordionPanel
          header={
            <AccessoriesHeader
              mobile
              total={accessoriesTotal}
              monthlyTotal={accessoriesMonthlyTotal}
              purchaseType={purchaseType}
              active={active === 1}
              step={step}
            />
          }
        >
          <Box pad={{ vertical: 'medium' }}>
            <Accessories
              step={step}
              bike={bikesById[selectedId]}
              accessories={selectedAccessories}
              total={accessoryTotal}
              mobile
            />
          </Box>
        </AccordionPanel>

        <AccordionPanel
          header={
            <SelectedPlanHeader
              mobile
              active={active === 2}
              price={(
                bikes[selectedId].subPrice + parseFloat(accessoriesMonthlyTotal) - discount.amount).toFixed(2)}
            />
          }
        >
          <Box pad={{ vertical: 'medium' }}>
            {purchaseType === 'subscription' ? (
              <SelectedPlanSubscription
                // step={step}
                discount={discount}
                ownership={(bikes[selectedId].RRP * 0.12).toFixed(2)}
                price={(
                  bikes[selectedId].subPrice + parseFloat(accessoriesMonthlyTotal) - discount.amount).toFixed(2)}
                startDate={bikes[selectedId]['Lead time']}
              // stepName={stepName}
              // terms={parser(editableRegions['Subscription Terms'])}
              />
            ) : (
              <SelectedPlan
                mobile
                step={step}
                length={selectedPlan?.length}
                net={selectedPlan?.plan?.net}
                stepName={stepName}
                terms={parser(editableRegions['Footer Terms'])}
              />
            )}
          </Box>
        </AccordionPanel>
      </Accordion>
    </Box >
  )
}

export default Sidebar
