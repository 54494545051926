import { Box, Grid, Layer } from 'grommet'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Sticky from 'react-sticky-el'
import styled from 'styled-components'
import { useResponsive } from '../../../hooks'
import Sidebar from '../../Components/Sidebar'
import { AccessoryChooser } from './AccessoryChooser'
import MobileTopBar from '../../Components/Sidebar/MobileTopBar'

const StyledLayer = styled(Layer)`
  border-radius: 0;
`

const ProgressArea = () => {
  return <></>
}

const Bike = ({ status, message, onValidated }) => {
  const { isDesktop, isMobile } = useResponsive()

  return (
    <>
      <Box
        direction="column"
        justify="start"
        flex={false}
        fill
        pad={{ horizontal: 'large' }}
      >
        <Grid
          columns={isMobile ? ['flex'] : ['350px', 'flex']}
          // rows={isMobile ? ['auto', 'auto'] : ['auto']}
        >
          {isDesktop && (
            <Box id="boundary">
              <Sticky
                mode="top"
                positionRecheckInterval={1}
                boundaryElement="#boundary"
              >
                <Box fill>
                  <Sidebar step="accessories" />
                </Box>
              </Sticky>
            </Box>
          )}

          {isMobile && (
            <Box flex>
              <MobileTopBar />
            </Box>
          )}

          <Box animate={'fadeIn'} id="mainpage" flex>
            <AccessoryChooser />
          </Box>
        </Grid>
      </Box>
    </>
  )
}

export default Bike
