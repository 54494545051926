import React, { useState } from 'react'
import { Link } from 'gatsby'
import { navigate } from '@reach/router'
import Auth from '@aws-amplify/auth'

import {
  AuthForm,
  Email,
  Password,
  ConfirmPassword,
  ConfirmationCode,
} from '../Forms/AuthForms'

import Swoosh from '../../assets/vectors/squiggle.svg'

import {
  Box,
  Button,
  Anchor,
  Paragraph,
  Spinner,
  Heading,
  FileInput,
  Grommet,
  MaskedInput,
  RadioButtonGroup,
  RangeInput,
  Select,
  TextArea,
  TextInput,
} from 'grommet'

const initialState = {
  email: ``,
  auth_code: ``,
  password: ``,
  error: ``,
  loading: false,
  stage: 0,
}

const Reset = ({ id, location }) => {
  const [state, setState] = useState(initialState)
  const [verified, setVerified] = useState(false)

  const handleUpdate = event => {
    const { name, value } = event.target // <-- moved outside asynchronous context

    setState(current => ({
      ...current,
      [name]: value,
      error: '',
    }))
  }

  const reset = async e => {
    e.preventDefault()
    try {
      setState(current => ({ ...current, loading: true }))
      await Auth.resendSignUp(id)
      setState(current => ({ ...current, loading: false }))
    } catch (err) {
      setState(current => ({ ...current, error: err, loading: false }))
      console.log('error...: ', err)
    }
  }

  const confirmReset = async e => {
    e.preventDefault()

    ///
    const { auth_code } = state

    setState(current => ({ ...current, loading: true }))
    try {
      await Auth.confirmSignUp(id, auth_code)
      setState(current => ({ ...current, loading: false }))
      setVerified(true)
    } catch (err) {
      setState(current => ({ ...current, error: err, loading: false }))
      setVerified(false)
    }
  }


  return (
    <Box align="center" justify="center" flex="grow">
      <AuthForm
        title={verified ? 'Verification Successful' : 'Verify Your Account'}
        error={state.error}
      >
        {verified ? (
          <>
            <Paragraph>
              You are now able to log into your account area
            </Paragraph>
            <Button
              primary
              size="large"
              onClick={e => navigate(`/signin${location.search}`)}
              type="submit"
              className="btn btn-primary btn-block"
              disabled={state.loading}
              label={
                state.loading ? (
                  <Spinner
                    style={{ margin: '0 auto' }}
                    alignSelf="center"
                    size="xsmall"
                  />
                ) : (
                  'Login'
                )
              }
            ></Button>
          </>
        ) : (
          <>
            <ConfirmationCode
              handleUpdate={handleUpdate}
              email={state.auth_code}
              autoComplete="off"
            />

            <Button
              margin={{ top: 'medium' }}
              primary
              size="large"
              onClick={e => confirmReset(e)}
              type="submit"
              className="btn btn-primary btn-block"
              disabled={state.loading}
              label={
                state.loading ? (
                  <Spinner
                    style={{ margin: '0 auto' }}
                    alignSelf="center"
                    size="xsmall"
                  />
                ) : (
                  'Verify'
                )
              }
            ></Button>
          </>
        )}
      </AuthForm>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Paragraph
          style={{ marginTop: 20, marginBottom: 20 }}
          className="text-center"
        >
          Lost your code?
        </Paragraph>
        <Anchor href="#" margin={{ left: 'small' }} onClick={e => reset(e)}>
          Resend Code
        </Anchor>
      </div>
    </Box>
  )
}

export default Reset
