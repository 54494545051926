import { Box, Grid, Heading, Image, Text } from 'grommet'
import React from 'react'
import { useDispatch } from 'react-redux'
import { selectedAccessories } from '../../../state/selection'
import { Close } from 'grommet-icons'
import {useSelector} from "react-redux";

export const ProductOptions = ({ item, index, bike, disabled }) => {
  const dispatch = useDispatch()
  const { purchaseType } = useSelector(state => state.ui)

  const selectItem = (e, accessory) => {
    e.preventDefault()
    dispatch(selectedAccessories(accessory))
  }

  const getImageURL = () => {
    return item && item['File location & Name']
      ? `https://blike.imgix.net/${encodeURIComponent(
          item['File location & Name']
        )}`
      : null
  }

  return (
    <Box
      flex={false}
      background={index % 2 !== 0 ? '#f7f7f7' : 'white'}
      animation={['slideDown', 'fadeIn']}
      border={{ size: '1px', side: 'bottom', color: 'light' }}
      pad={{ vertical: 'xsmall', right: 'small' }}
    >
      <Grid
        gap="small"
        fill="horizontal"
        rows={['50px']}
        columns={
          !disabled
            ? ['50px', 'auto', 'auto', '20px']
            : ['50px', 'auto', 'auto']
        }
      >
        <Box justify="center" fill>
          <Image height="100%" width="100%" fit="contain" src={getImageURL()} />
        </Box>
        <Box justify="center">
          <Heading margin="none" level={5} size="xsmall" truncate>
            {item?.['Display Name']}
          </Heading>
        </Box>
        <Box justify="center" align="end">
          <Text className="bold" size="xsmall">
            {`£${
              purchaseType === 'subscription' ? item?.['Sub Monthly']?.toFixed(2) : item?.RRP
            }`}
          </Text>
        </Box>
        {!disabled && (
          <Box
            height="20px"
            width="20px"
            background="black"
            round
            justify="center"
            align="center"
            onClick={e => (disabled ? null : selectItem(e, item))}
            alignSelf="center"
          >
            <Close size="10px" color="white" />
          </Box>
        )}
      </Grid>
    </Box>
  )
}
